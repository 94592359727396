import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "mt-3 white-space-pre-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        class: "text-indigo-400",
        href: `/feeds/${_ctx.feed.id}`,
        target: "_blank"
      }, _toDisplayString(_ctx.feed.compiled_name), 9, _hoisted_1),
      _createElementVNode("a", {
        class: "ml-2",
        href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ feed_id: [_ctx.feed.id] })}`,
        target: "_blank"
      }, [
        _withDirectives(_createVNode(_component_IconPark, {
          class: "text-blue-600 cursor-pointer",
          type: "chart-histogram-two",
          theme: "outline",
          size: _ctx.iconSize
        }, null, 8, ["size"]), [
          [
            _directive_tooltip,
            _ctx.$t('statistics'),
            void 0,
            { right: true }
          ]
        ])
      ], 8, _hoisted_2)
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('revshare')) + ": " + _toDisplayString(_ctx.$n(_ctx.feed.revshare / 100, 'percent')), 1),
    (_ctx.feed.fixed_bid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('fixed_bid')) + ": " + _toDisplayString(_ctx.$n(_ctx.feed.fixed_bid, 'bid')), 1))
      : _createCommentVNode("", true),
    (_ctx.feed.max_qps)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "QPS: " + _toDisplayString(_ctx.feed.max_qps), 1))
      : _createCommentVNode("", true),
    (_ctx.feed.comment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.feed.comment), 1))
      : _createCommentVNode("", true)
  ]))
}