import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('available_macros')) + ":", 1),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.macros, (macro, index) => {
        return (_openBlock(), _createElementBlock("code", {
          class: "mr-2 text-sm text-indigo-300",
          key: index
        }, "{" + _toDisplayString(macro) + "}", 1))
      }), 128))
    ])
  ]))
}