import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  key: 0,
  class: "block"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!
  const _component_Cropper = _resolveComponent("Cropper")!
  const _component_PrimeVueDialog = _resolveComponent("PrimeVueDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_PrimeVueButton, {
        label: "Select image",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true))
      }),
      (_ctx.modelValue || _ctx.imageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "block mt-3",
            src: _ctx.modelValue || _ctx.imageUrl,
            style: _normalizeStyle({ 'max-width': `${_ctx.maxWidth}px` })
          }, null, 12, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.name)
        ? (_openBlock(), _createBlock(_component_FieldErrors, {
            key: 2,
            class: "mt-3",
            "errors-map": _ctx.errorsMap,
            name: _ctx.name
          }, null, 8, ["errors-map", "name"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PrimeVueDialog, {
      visible: _ctx.dialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
      header: "Image",
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("input", {
          class: "block mb-3",
          type: "file",
          accept: "image/*",
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
        }, null, 32),
        (_ctx.imageBase64)
          ? (_openBlock(), _createBlock(_component_Cropper, {
              key: 0,
              class: "cropper mb-3",
              src: _ctx.imageBase64,
              "stencil-props": { aspectRatio: _ctx.aspectRatio },
              style: { height: '50vh' },
              onChange: _ctx.onCropperChange
            }, null, 8, ["src", "stencil-props", "onChange"]))
          : _createCommentVNode("", true),
        _createVNode(_component_PrimeVueButton, {
          label: _ctx.$t('save'),
          icon: "pi pi-save",
          onClick: _ctx.save
        }, null, 8, ["label", "onClick"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}