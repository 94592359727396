
import axios from 'axios';

import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import type { Subid } from '@/types';

import {
  SUBID_STATUS_NEW,
  SUBID_STATUS_AUTO_APPROVED,
  SUBID_STATUS_AUTO_DECLINED,
  SUBID_STATUS_DECLINED,
  SUBID_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

export default defineComponent({
  props: {
    subid: {
      type: Object as PropType<Subid>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const toast = useToast();

    const { activeUser: user } = useUser();

    const declineConfirmation = ref<boolean>(true); // 28.06 отключено подтверждение удаления

    const loading = ref<boolean>(false);

    const changeSubidStatus = async (status: number): Promise<void> => {
      if (status === SUBID_STATUS_DECLINED && !declineConfirmation.value) {
        declineConfirmation.value = true;

        return;
      }

      loading.value = true;

      try {
        const response = await axios.post(
          '/api/subid/changeStatusById',
          {
            id: props.subid.id,
            status,
          },
        );

        emit('update:subid', response.data);

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_subid_status_change'),
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      loading.value = false;
    };

    return {
      SUBID_STATUS_NEW,
      SUBID_STATUS_AUTO_APPROVED,
      SUBID_STATUS_AUTO_DECLINED,
      SUBID_STATUS_DECLINED,
      SUBID_STATUSES_MAP,
      user,
      declineConfirmation,
      loading,
      changeSubidStatus,
    };
  },
});
