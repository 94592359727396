import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "login-body" }
const _hoisted_2 = { class: "login-wrapper" }
const _hoisted_3 = { class: "login-panel" }
const _hoisted_4 = {
  key: 0,
  class: "mb-8 text-center"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "login-footer flex-column align-items-center sm:flex-row" }
const _hoisted_8 = { class: "mr-0 mb-2 sm:mb-0 sm:mr-5" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueInputText = _resolveComponent("PrimeVueInputText")!
  const _component_PrimeVuePassword = _resolveComponent("PrimeVuePassword")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.project.logo_url_black)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                href: `https://${_ctx.project.site_host}`
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(["w-full", _ctx.project.logo_class]),
                  src: _ctx.project.logo_url_black
                }, null, 10, _hoisted_6)
              ], 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_PrimeVueInputText, {
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('email')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_PrimeVuePassword, {
          modelValue: _ctx.form.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('password'),
          feedback: false
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_PrimeVueButton, {
          label: _ctx.$t('login'),
          disabled: !_ctx.formIsValid,
          onClick: _ctx.login
        }, null, 8, ["label", "disabled", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.project.name), 1),
        (!_ctx.project.white_label)
          ? (_openBlock(), _createElementBlock("h6", _hoisted_9, "⚙️ " + _toDisplayString(_ctx.$t('powered_by')) + " AdBison Platform", 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}