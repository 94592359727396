
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { Endpoint } from '@/types';

import {
  ENDPOINT_STATUS_DISABLED,
  ENDPOINT_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  props: {
    endpoint: {
      type: Object as PropType<Endpoint>,
      required: true,
    },
    showUuid: {
      type: Boolean,
      default: true,
    },
    showUid: {
      type: Boolean,
      default: true,
    },
    showCheckerButton: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 14,
    },
    statisticsFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { activeUser: user } = useUser();

    const { encodeStatisticsFilters } = useStatisticsLink();

    return {
      ENDPOINT_STATUS_DISABLED,
      ENDPOINT_STATUSES_MAP,
      user,
      encodeStatisticsFilters,
    };
  },
});
