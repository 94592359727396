
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import PrimeVueInputText from 'primevue/inputtext';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  components: {
    PrimeVueInputText,
  },
  props: {
    modelValue: {
      type: [Number, String],
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    inputGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const wrapperClass = computed((): string => (props.inputGroup ? 'p-inputgroup' : 'field mb-0'));

    const textLength = computed((): number => (props.modelValue ? String(props.modelValue).length : 0));

    return {
      wrapperClass,
      textLength,
    };
  },
});
