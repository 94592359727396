
import {
  defineComponent,
  defineAsyncComponent,
  computed,
} from 'vue';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    Manager: defineAsyncComponent(() => import('@/components/Manager.vue')),
  },
  setup() {
    const { activeUser: user } = useUser();

    const encodedId = btoa(user.id);

    const contactUrl = computed((): string => user.project.contact_url.replace('{encodedId}', encodedId));

    return {
      user,
      contactUrl,
    };
  },
});
