import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "ml-2 text-bluegray-300" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_5 = { class: "p-fluid" }
const _hoisted_6 = { class: "grid" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "mb-3 text-xs text-center" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "mb-3 text-xs text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FiltersSidebar = _resolveComponent("FiltersSidebar")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_User = _resolveComponent("User")!
  const _component_Chartist = _resolveComponent("Chartist")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": "",
      "refresh-button-handler": _ctx.fetchChartDataWrapper,
      "refresh-button-loading": _ctx.chartDataLoading,
      "show-filters-button": "",
      "show-filters": _ctx.showChartDataFilters,
      "onUpdate:show-filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showChartDataFilters) = $event)),
      loading: _ctx.chartDataLoading
    }, {
      title: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, [
          _createTextVNode("💸 " + _toDisplayString(_ctx.$t('advertisers_activity')), 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('for_last')) + " " + _toDisplayString(_ctx.chartDataFilters.hour_offset) + " " + _toDisplayString(_ctx.$t('hours_abbr')), 1)
        ])
      ]),
      _: 1
    }, 8, ["refresh-button-handler", "refresh-button-loading", "show-filters", "loading"]),
    _createVNode(_component_FiltersSidebar, {
      visible: _ctx.showChartDataFilters,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showChartDataFilters) = $event)),
      "apply-button-handler": _ctx.fetchChartDataWrapper,
      "apply-button-loading": _ctx.chartDataLoading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FieldDropdownNext, {
            modelValue: _ctx.chartDataFilters.hour_offset,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.chartDataFilters.hour_offset) = $event)),
            modelModifiers: { number: true },
            label: _ctx.$t('hours'),
            "show-clear": "",
            options: _ctx.hourOffsetOptions
          }, null, 8, ["modelValue", "label", "options"])
        ]),
        (_ctx.activeUser.isSuperAdmin())
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_FieldDropdownNext, {
                modelValue: _ctx.chartDataFilters.project_id,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.chartDataFilters.project_id) = $event)),
                  _ctx.onProjectChange
                ],
                modelModifiers: { number: true },
                label: _ctx.$t('project'),
                loading: _ctx.projectsLoading,
                filter: "",
                options: _ctx.projects.data,
                "option-value": "id",
                "option-label": "name",
                multiple: ""
              }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_FieldDropdownNext, {
            modelValue: _ctx.chartDataFilters.advertiser_id,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.chartDataFilters.advertiser_id) = $event)),
            modelModifiers: { number: true },
            label: _ctx.$t('advertiser'),
            loading: _ctx.advertisersLoading,
            filter: "",
            options: _ctx.advertisers.data,
            "option-value": "id",
            "option-label": "compiled_name",
            multiple: "",
            "multiple-sort-by-value": ""
          }, null, 8, ["modelValue", "label", "loading", "options"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "apply-button-handler", "apply-button-loading"]),
    (_ctx.chartDataLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (index) => {
          return _createVNode(_component_PrimeVueSkeleton, {
            class: "mb-3",
            key: index,
            height: "25px"
          })
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.chartData, (entry) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "card mb-3 pb-0",
            key: entry.advertiser.id
          }, [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _createVNode(_component_User, {
                  class: "mb-3",
                  user: entry.advertiser,
                  "show-checker-button": false,
                  "statistics-filters-key": "advertiser_id"
                }, null, 8, ["user"])
              ]),
              fallback: _withCtx(() => [
                _createVNode(_component_PrimeVueSkeleton, {
                  class: "mb-3",
                  height: "50px"
                })
              ]),
              _: 2
            }, 1024)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('spent')), 1),
                      _createVNode(_component_Chartist, {
                        class: "activity-chart activity-chart-clicks",
                        "axis-y-label-interpolation-function": _ctx.axisYLabelInterpolationFunction,
                        entries: entry.statistics,
                        "entry-key": "spent"
                      }, null, 8, ["axis-y-label-interpolation-function", "entries"])
                    ])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 2
                }, 1024))
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('profit')), 1),
                      _createVNode(_component_Chartist, {
                        class: "activity-chart activity-chart-clicks",
                        "axis-y-label-interpolation-function": _ctx.axisYLabelInterpolationFunction,
                        entries: entry.statistics,
                        "entry-key": "profit"
                      }, null, 8, ["axis-y-label-interpolation-function", "entries"])
                    ])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 2
                }, 1024))
              ])
            ])
          ]))
        }), 128))
  ], 64))
}