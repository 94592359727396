import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HighlightedNumber = _resolveComponent("HighlightedNumber")!

  return (_openBlock(), _createBlock(_component_HighlightedNumber, {
    number: _ctx.data.advertisers_roi,
    "number-format": "percent",
    "threshold-warning": 0.1,
    "threshold-danger": -0.3
  }, null, 8, ["number", "threshold-warning", "threshold-danger"]))
}