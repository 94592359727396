import { reactive } from 'vue';

import type {
  GenericObject,
  SubStatisticsComponent,
} from '@/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default () => {
  const defaultSubStatisticComponent = {
    name: null,
    key: null,
    filters: {},
  };

  const subStatisticsComponent = reactive<SubStatisticsComponent>({ ...defaultSubStatisticComponent });

  const setSubStatisticsComponent = (name: string, key: string, filters: GenericObject = {}): void => {
    subStatisticsComponent.name = name;

    subStatisticsComponent.key = `${name}_${key}`;

    subStatisticsComponent.filters = filters;
  };

  const resetSubStatisticComponent = (): void => {
    Object.assign(subStatisticsComponent, defaultSubStatisticComponent);
  };

  return {
    subStatisticsComponent,
    setSubStatisticsComponent,
    resetSubStatisticComponent,
  };
};
