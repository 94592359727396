
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import PrimeVueInputNumber from 'primevue/inputnumber';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  components: {
    PrimeVueInputNumber,
  },
  props: {
    modelValue: {
      type: Number,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    mode: {
      type: String,
    },
    useGrouping: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: String,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    minFractionDigits: {
      type: Number,
    },
    maxFractionDigits: {
      type: Number,
    },
    step: {
      type: Number,
    },
    showButtons: {
      type: Boolean,
    },
    buttonLayout: {
      type: String,
    },
    incrementButtonIcon: {
      type: String,
    },
    decrementButtonIcon: {
      type: String,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
    inputGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const wrapperClass = computed((): string => (props.inputGroup ? 'p-inputgroup' : 'field mb-0'));

    return {
      wrapperClass,
    };
  },
});
