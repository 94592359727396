import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 lg:col-4" }
const _hoisted_4 = {
  key: 0,
  class: "card"
}
const _hoisted_5 = { class: "p-fluid" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = {
  key: 1,
  class: "card"
}
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = {
  key: 2,
  class: "card"
}
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "p-fluid" }
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "p-fluid" }
const _hoisted_15 = { class: "col-12 lg:col-4" }
const _hoisted_16 = {
  key: 0,
  class: "card"
}
const _hoisted_17 = { class: "p-fluid" }
const _hoisted_18 = {
  key: 1,
  class: "card"
}
const _hoisted_19 = {
  key: 2,
  class: "card"
}
const _hoisted_20 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _component_Entry = _resolveComponent("Entry")!

  return (_openBlock(), _createBlock(_component_Entry, {
    "entry-errors-map": _ctx.siteErrorsMap,
    "onUpdate:entry-errors-map": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.siteErrorsMap) = $event)),
    title: _ctx.$t('site'),
    entry: _ctx.site,
    "entry-fetch-handler": _ctx.fetchSite,
    "entry-store-handler": (!!_ctx.site.id && _ctx.user.isAffiliate()) ? null : _ctx.storeSite
  }, {
    "buttons-col-2-prepend": _withCtx(() => [
      (!!_ctx.site.id && _ctx.user.isStaff())
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "ml-4",
            href: `/users/${_ctx.site.user_id}`,
            target: "_blank"
          }, [
            _createVNode(_component_PrimeVueButton, {
              class: "p-button-info mb-0",
              label: _ctx.$t('user'),
              icon: "pi pi-user"
            }, null, 8, ["label"])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!!_ctx.site.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.site.id,
                    label: "ID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.site.uuid,
                    label: "UUID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_FieldDropdownNext, {
                    modelValue: _ctx.site.project_id,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.site.project_id) = $event)),
                      _ctx.onProjectChange
                    ],
                    modelModifiers: { number: true },
                    name: "project_id",
                    "errors-map": _ctx.siteErrorsMap,
                    label: _ctx.$t('project'),
                    loading: _ctx.projectsLoading,
                    disabled: !!_ctx.site.id,
                    options: _ctx.projects.data,
                    "option-value": "id",
                    "option-label": "name"
                  }, null, 8, ["modelValue", "errors-map", "label", "loading", "disabled", "options", "onUpdate:modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.isStaff())
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_FieldDropdownNext, {
                    modelValue: _ctx.site.user_id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.site.user_id) = $event)),
                    modelModifiers: { number: true },
                    name: "user_id",
                    "errors-map": _ctx.siteErrorsMap,
                    label: _ctx.$t('user'),
                    loading: _ctx.usersLoading,
                    disabled: !!_ctx.site.id,
                    filter: true,
                    options: _ctx.users.data,
                    "option-value": "id",
                    "option-label": "compiled_name"
                  }, null, 8, ["modelValue", "errors-map", "label", "loading", "disabled", "options"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_FieldDropdownNext, {
                modelValue: _ctx.site.traffic_category,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.site.traffic_category) = $event)),
                modelModifiers: { number: true },
                name: "traffic_category",
                "errors-map": _ctx.siteErrorsMap,
                label: _ctx.$t('category'),
                disabled: !!_ctx.site.id,
                options: _ctx.TRAFFIC_CATEGORIES
              }, null, 8, ["modelValue", "errors-map", "label", "disabled", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.site.host,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.site.host) = $event)),
                modelModifiers: { trim: true },
                name: "host",
                "errors-map": _ctx.siteErrorsMap,
                label: _ctx.$t('host'),
                help: _ctx.$t('host_help'),
                disabled: !!_ctx.site.id
              }, null, 8, ["modelValue", "errors-map", "label", "help", "disabled"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (!!_ctx.site.id || _ctx.user.isStaff())
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_FieldDropdownNext, {
                    modelValue: _ctx.site.approval_status,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.site.approval_status) = $event)),
                    modelModifiers: { number: true },
                    name: "approval_status",
                    "errors-map": _ctx.siteErrorsMap,
                    label: _ctx.$t('approval_status'),
                    disabled: _ctx.user.isAffiliate(),
                    options: _ctx.SITE_APPROVAL_STATUSES,
                    "option-label-translate": ""
                  }, null, 8, ["modelValue", "errors-map", "label", "disabled", "options"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.isStaff())
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_FieldTextarea, {
                  modelValue: _ctx.site.comment,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.site.comment) = $event)),
                  modelModifiers: { trim: true },
                  name: "comment",
                  "errors-map": _ctx.siteErrorsMap,
                  label: _ctx.$t('comment')
                }, null, 8, ["modelValue", "errors-map", "label"])
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.site.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.site.created_at,
                    label: _ctx.$t('created_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"]),
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.site.updated_at,
                    label: _ctx.$t('updated_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["entry-errors-map", "title", "entry", "entry-fetch-handler", "entry-store-handler"]))
}