import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML", "for"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueCheckbox = _resolveComponent("PrimeVueCheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'mb-3': !!_ctx.help })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["field-checkbox", { 'mb-1': !!_ctx.help }])
    }, [
      _createVNode(_component_PrimeVueCheckbox, {
        "model-value": _ctx.modelValue,
        binary: true,
        "true-value": true,
        "false-value": false,
        id: _ctx.uuid,
        disabled: _ctx.disabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["model-value", "id", "disabled"]),
      _createElementVNode("label", {
        class: "cursor-pointer",
        innerHTML: _ctx.label,
        for: _ctx.uuid
      }, null, 8, _hoisted_1)
    ], 2),
    (_ctx.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 0,
          class: "block",
          innerHTML: _ctx.help
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}