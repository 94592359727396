import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 1,
  class: "font-semibold"
}
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserColumn = _resolveComponent("UserColumn", true)!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showEmailAsLink)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            class: "text-indigo-400",
            href: `/users/${_ctx.user.id}`,
            target: "_blank"
          }, _toDisplayString(_ctx.user.email), 9, _hoisted_2)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.user.email), 1)),
    (_ctx.user.alias)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.user.alias), 1))
      : _createCommentVNode("", true),
    (_ctx.user.comment)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(_ctx.sectionClasses)
        }, _toDisplayString(_ctx.user.comment), 3))
      : _createCommentVNode("", true),
    (_ctx.user.referrer_id)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(_ctx.sectionClasses)
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('referrer')) + ":", 1),
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(_component_UserColumn, {
                user: _ctx.user.referrer,
                "show-email-as-link": ""
              }, null, 8, ["user"])
            ]),
            fallback: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, { height: "10px" })
            ]),
            _: 1
          }))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.user.link_id)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: _normalizeClass(_ctx.sectionClasses)
        }, _toDisplayString(_ctx.$t('link')) + ": /" + _toDisplayString(_ctx.user.link.slug), 3))
      : _createCommentVNode("", true)
  ], 64))
}