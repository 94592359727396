import mitt from 'mitt';

type Events = {
  fetchEntries: void;
  entriesLoading: boolean;
};

const emitter = mitt<Events>();

export default emitter;
