import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "text-indigo-400"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 2,
  class: "flex align-items-center"
}
const _hoisted_8 = { class: "flex align-items-center" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_SubidStatus = _resolveComponent("SubidStatus")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showUuid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.subidUuid), 1),
          _createVNode(_component_IconPark, {
            class: "ml-2 cursor-pointer",
            type: "copy",
            theme: "outline",
            size: "10",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.subidUuid)))
          }),
          (_ctx.user.isStaff())
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "ml-2",
                href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ subid_uuid: _ctx.subidUuid })}`,
                target: "_blank"
              }, [
                _withDirectives(_createVNode(_component_IconPark, {
                  class: "text-blue-600 cursor-pointer",
                  type: "chart-histogram-two",
                  theme: "outline",
                  size: _ctx.iconSize
                }, null, 8, ["size"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('statistics'),
                    void 0,
                    { right: true }
                  ]
                ])
              ], 8, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.showCheckerButton && _ctx.user.isStaff())
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "ml-2",
                href: `/checker?filters=${_ctx.encodeStatisticsFilters({ subid_uuid: _ctx.subidUuid })}`,
                target: "_blank"
              }, [
                _withDirectives(_createVNode(_component_IconPark, {
                  class: "text-blue-600 cursor-pointer",
                  type: "shield",
                  theme: "outline",
                  size: _ctx.iconSize
                }, null, 8, ["size"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('checker'),
                    void 0,
                    { right: true }
                  ]
                ])
              ], 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.subid)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.showValue)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.subid.value), 1),
                _createVNode(_component_IconPark, {
                  class: "ml-2 cursor-pointer",
                  type: "copy",
                  theme: "outline",
                  size: "10",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.subid.value)))
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.subid.endpoint)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("a", {
                  class: "text-indigo-400",
                  href: `/endpoints/${_ctx.subid.endpoint.id}`,
                  target: "_blank"
                }, _toDisplayString(_ctx.subid.endpoint.compiled_name), 9, _hoisted_6)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showStatus)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SubidStatus, {
                      subid: _ctx.subid,
                      "onUpdate:subid": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subid) = $event)),
                        _ctx.onSubidStatusChange
                      ]
                    }, null, 8, ["subid", "onUpdate:subid"])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, { height: "12px" })
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true),
          (_ctx.showQualityPresets && _ctx.subid.quality_presets_ids.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (!_ctx.qualityPresetsFetched)
                  ? (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
                      key: 0,
                      class: "my-1",
                      height: "5px"
                    }))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.subid.quality_presets_ids, (qualityPresetId) => {
                      return (_openBlock(), _createElementBlock("div", { key: qualityPresetId }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_IconPark, {
                            class: "mr-2",
                            type: "tag",
                            theme: "outline",
                            size: "10"
                          }),
                          (_ctx.qualityPresetsMap[qualityPresetId])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.qualityPresetsMap[qualityPresetId].name), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('unknown')), 1))
                        ])
                      ]))
                    }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('unknown')), 1))
  ]))
}