import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chartist = _resolveComponent("Chartist")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('spending_by_days')), 1)
      ])
    ]),
    (_ctx.statisticsFetched)
      ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_Chartist, {
              class: "activity-chart activity-chart-revenue",
              labels: _ctx.labels,
              "show-point": "",
              "axis-y-label-interpolation-function": _ctx.axisYLabelInterpolationFunction,
              "tooltip-options": { currency: '$' },
              entries: _ctx.statisticsData,
              "entry-key": "spent",
              "entry-description-key": "date"
            }, null, 8, ["labels", "axis-y-label-interpolation-function", "entries"])
          ]),
          fallback: _withCtx(() => [
            _createVNode(_component_PrimeVueSkeleton, { height: "120px" })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
          key: 1,
          height: "120px"
        }))
  ]))
}