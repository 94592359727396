import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-4" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = {
  key: 1,
  class: "card"
}
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = {
  key: 2,
  class: "card"
}
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = {
  key: 3,
  class: "card"
}
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = { class: "col-12 lg:col-4" }
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "p-fluid" }
const _hoisted_14 = { class: "p-fluid" }
const _hoisted_15 = { class: "card" }
const _hoisted_16 = { class: "p-fluid" }
const _hoisted_17 = { class: "p-fluid" }
const _hoisted_18 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_19 = { class: "card" }
const _hoisted_20 = { class: "p-fluid" }
const _hoisted_21 = { class: "col-12 lg:col-4" }
const _hoisted_22 = {
  key: 0,
  class: "card"
}
const _hoisted_23 = { class: "p-fluid" }
const _hoisted_24 = {
  key: 1,
  class: "card"
}
const _hoisted_25 = { class: "p-fluid" }
const _hoisted_26 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldDate = _resolveComponent("FieldDate")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _component_PublisherPayoutInformation = _resolveComponent("PublisherPayoutInformation")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_Entry = _resolveComponent("Entry")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Entry, {
    "entry-errors-map": _ctx.payoutErrorsMap,
    "onUpdate:entry-errors-map": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payoutErrorsMap) = $event)),
    title: _ctx.$t('payout'),
    entry: _ctx.payout,
    "entry-fetch-handler": _ctx.fetchPayout,
    "entry-store-handler": _ctx.activeUser.isAffiliate() ? null : _ctx.storePayout
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!!_ctx.payout.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.payout.id,
                    label: "ID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeUser.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_FieldDropdown, {
                    modelValue: _ctx.payout.project_id,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payout.project_id) = $event)),
                      _ctx.onProjectChange
                    ],
                    modelModifiers: { number: true },
                    name: "project_id",
                    "errors-map": _ctx.payoutErrorsMap,
                    label: _ctx.$t('project'),
                    loading: _ctx.projectsLoading,
                    options: _ctx.projects.data,
                    "option-value": "id",
                    "option-label": "name",
                    "option-label-translate": false
                  }, null, 8, ["modelValue", "errors-map", "label", "loading", "options", "onUpdate:modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.activeUser.isAffiliate())
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_FieldDropdown, {
                    modelValue: _ctx.payout.user_id,
                    "onUpdate:modelValue": [
                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payout.user_id) = $event)),
                      _ctx.onUserChange
                    ],
                    modelModifiers: { number: true },
                    name: "user_id",
                    "errors-map": _ctx.payoutErrorsMap,
                    label: _ctx.$t('user'),
                    options: _ctx.users.data,
                    "option-value": "id",
                    "option-label": "compiled_name",
                    "option-label-translate": false,
                    filter: true
                  }, null, 8, ["modelValue", "errors-map", "label", "options", "onUpdate:modelValue"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.activeUser.isAffiliate())
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_FieldDropdown, {
                    modelValue: _ctx.payout.type,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payout.type) = $event)),
                    modelModifiers: { number: true },
                    name: "type",
                    "errors-map": _ctx.payoutErrorsMap,
                    label: _ctx.$t('type'),
                    options: _ctx.PAYOUT_TYPES
                  }, null, 8, ["modelValue", "errors-map", "label", "options"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_FieldDate, {
                modelValue: _ctx.payout.date_from,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payout.date_from) = $event)),
                name: "date_from",
                "errors-map": _ctx.payoutErrorsMap,
                label: _ctx.$t('date_from'),
                disabled: _ctx.activeUser.isAffiliate()
              }, null, 8, ["modelValue", "errors-map", "label", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_FieldDate, {
                modelValue: _ctx.payout.date_to,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payout.date_to) = $event)),
                name: "date_to",
                "errors-map": _ctx.payoutErrorsMap,
                label: _ctx.$t('date_to'),
                disabled: _ctx.activeUser.isAffiliate()
              }, null, 8, ["modelValue", "errors-map", "label", "disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.payout.amount,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payout.amount) = $event)),
                modelModifiers: { number: true },
                name: "amount",
                "errors-map": _ctx.payoutErrorsMap,
                label: _ctx.$t('amount'),
                disabled: _ctx.activeUser.isAffiliate(),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 2,
                "input-group": true
              }, {
                append: _withCtx(() => [
                  _withDirectives(_createVNode(_component_PrimeVueButton, {
                    class: "p-button-secondary",
                    icon: "pi pi-copy",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.copyToClipboard(_ctx.payout.amount)))
                  }, null, 512), [
                    [
                      _directive_tooltip,
                      _ctx.$t('copy'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "errors-map", "label", "disabled"])
            ]),
            (_ctx.activeUser.isAffiliate() && !_ctx.payout.extra_amount ? false : true)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_FieldNumber, {
                      modelValue: _ctx.payout.extra_amount,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payout.extra_amount) = $event)),
                      modelModifiers: { number: true },
                      name: "extra_amount",
                      "errors-map": _ctx.payoutErrorsMap,
                      label: _ctx.$t('extra_amount'),
                      disabled: _ctx.activeUser.isAffiliate(),
                      "min-fraction-digits": 1,
                      "max-fraction-digits": 2,
                      "input-group": true
                    }, {
                      append: _withCtx(() => [
                        _withDirectives(_createVNode(_component_PrimeVueButton, {
                          class: "p-button-secondary",
                          icon: "pi pi-copy",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.copyToClipboard(_ctx.payout.extra_amount)))
                        }, null, 512), [
                          [
                            _directive_tooltip,
                            _ctx.$t('copy'),
                            void 0,
                            { top: true }
                          ]
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "errors-map", "label", "disabled"])
                  ]),
                  (_ctx.activeUser.isAffiliate() && !_ctx.payout.extra_amount_comment ? false : true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createVNode(_component_FieldTextarea, {
                          modelValue: _ctx.payout.extra_amount_comment,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payout.extra_amount_comment) = $event)),
                          modelModifiers: { trim: true },
                          name: "extra_amount_comment",
                          "errors-map": _ctx.payoutErrorsMap,
                          label: _ctx.$t('extra_amount_comment'),
                          help: _ctx.activeUser.isAffiliate() ? null : _ctx.$t('visible_to_all_male'),
                          disabled: _ctx.activeUser.isAffiliate()
                        }, null, 8, ["modelValue", "errors-map", "label", "help", "disabled"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(_component_PublisherPayoutInformation, {
                modelValue: _ctx.payout,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payout) = $event)),
                "errors-map": _ctx.payoutErrorsMap,
                disabled: _ctx.activeUser.isAffiliate()
              }, null, 8, ["modelValue", "errors-map", "disabled"])
            ]),
            fallback: _withCtx(() => [
              _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
            ]),
            _: 1
          })),
          (!_ctx.activeUser.isAffiliate() && _ctx.payoutUser)
            ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_PublisherPayoutInformation, {
                    "model-value": _ctx.payoutUser,
                    "title-translate-key": "payout_information_from_user_profile",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_FieldDate, {
                modelValue: _ctx.payout.paid_at,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payout.paid_at) = $event)),
                name: "paid_at",
                "errors-map": _ctx.payoutErrorsMap,
                label: _ctx.$t('paid_at'),
                disabled: _ctx.activeUser.isAffiliate()
              }, null, 8, ["modelValue", "errors-map", "label", "disabled"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          (!_ctx.activeUser.isAffiliate())
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_FieldTextarea, {
                    modelValue: _ctx.payout.comment,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payout.comment) = $event)),
                    modelModifiers: { trim: true },
                    name: "comment",
                    "errors-map": _ctx.payoutErrorsMap,
                    label: _ctx.$t('comment')
                  }, null, 8, ["modelValue", "errors-map", "label"])
                ])
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.payout.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.payout.created_at,
                    label: _ctx.$t('created_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.payout.updated_at,
                    label: _ctx.$t('updated_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["entry-errors-map", "title", "entry", "entry-fetch-handler", "entry-store-handler"]))
}