
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import { useI18n } from 'vue-i18n';

import PrimeVueDropdown from 'primevue/dropdown';

import PrimeVueMultiSelect from 'primevue/multiselect';

import type {
  ErrorsMap,
  Option,
} from '@/types';

export default defineComponent({
  components: {
    PrimeVueDropdown,
    PrimeVueMultiSelect,
  },
  props: {
    modelValue: {
      type: [Number, String, Array],
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    filterPlaceholder: {
      type: String,
    },
    options: {
      type: Array as PropType<Option[]>,
      default: () => ([]),
    },
    optionValue: {
      type: String as PropType<keyof Option>,
      default: 'value',
    },
    optionLabel: {
      type: String as PropType<keyof Option>,
      default: 'label',
    },
    optionLabelTranslate: {
      type: Boolean,
      default: false,
    },
    optionDisabled: {
      type: String as PropType<keyof Option>,
      default: 'disabled',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multipleSortByValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const componentName = computed((): string => (props.multiple ? 'PrimeVueMultiSelect' : 'PrimeVueDropdown'));

    const sortedOptions = computed((): Option[] => {
      const options = [...props.options];

      if (props.optionLabelTranslate) {
        options.forEach((option: Option) => {
          // eslint-disable-next-line no-param-reassign
          option.label = option.translate_key ? i18n.t(option.translate_key) : i18n.t('unknown');
        });
      }

      if (!props.multiple || !Array.isArray(props.modelValue)) {
        return options;
      }

      return options.sort((a, b) => {
        // Сортировка опций по "активности" + по значению или в алфавитном порядке

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const aSelected = Number((props.modelValue as any[]).includes(a[props.optionValue]));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const bSelected = Number((props.modelValue as any[]).includes(b[props.optionValue]));

        let secondarySortCondition = 0;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const valuesIsNumbers = (props.modelValue as any[]).every((value) => typeof value === 'number');

        if (props.multipleSortByValue && valuesIsNumbers) {
          secondarySortCondition = (b[props.optionValue] as number) - (a[props.optionValue] as number);
        } else {
          secondarySortCondition = (a[props.optionLabel] as string).localeCompare(b[props.optionLabel] as string);
        }

        return bSelected - aSelected || secondarySortCondition;
      });
    });

    return {
      componentName,
      sortedOptions,
    };
  },
});
