
import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  GenericObject,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Country: defineAsyncComponent(() => import('@/components/Country.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/Columns/ViewsColumn.vue')),
  },
  props: {
    statisticsFilters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/advertiser/getStatisticsByCountries');

    const fetchStatisticsWrapper = async (): Promise<void> => fetchStatistics({
      ...props.statisticsFilters,
      ...props.filters,
    });

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
    };
  },
});
