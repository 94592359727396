import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer text-green-400",
        type: "every-user",
        theme: "outline",
        size: "10",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handler('StatisticsByPublishers', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('publishers'),
          void 0,
          { left: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer",
        type: "link-in",
        theme: "outline",
        size: "10",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handler('StatisticsByEndpoints', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('endpoints'),
          void 0,
          { left: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer",
        type: "tag-one",
        theme: "outline",
        size: "10",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handler('StatisticsBySubids', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('subids'),
          void 0,
          { left: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer",
        type: "google",
        theme: "outline",
        size: "10",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handler('StatisticsByReferrers', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('referrers'),
          void 0,
          { left: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer",
        type: "globe",
        theme: "outline",
        size: "10",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handler('StatisticsByCountries', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('countries'),
          void 0,
          { left: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer",
        type: "windows",
        theme: "outline",
        size: "10",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handler('StatisticsByPlatforms', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('platforms'),
          void 0,
          { left: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_IconPark, {
        class: "mr-2 cursor-pointer",
        type: "browser",
        theme: "outline",
        size: "10",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handler('StatisticsByBrowsers', _ctx.subStatisticsComponentKey, _ctx.filters)))
      }, null, 512), [
        [
          _directive_tooltip,
          _ctx.$t('browsers'),
          void 0,
          { left: true }
        ]
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.statisticsFilters.bidder !== _ctx.BIDDER_FEED)
        ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
            key: 0,
            class: "mr-2 cursor-pointer text-blue-400",
            type: "every-user",
            theme: "outline",
            size: "10",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handler('StatisticsByAdvertisers', _ctx.subStatisticsComponentKey, _ctx.filters)))
          }, null, 512)), [
            [
              _directive_tooltip,
              _ctx.$t('advertisers'),
              void 0,
              { left: true }
            ]
          ])
        : _createCommentVNode("", true),
      (_ctx.statisticsFilters.bidder !== _ctx.BIDDER_FEED)
        ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
            key: 1,
            class: "mr-2 cursor-pointer",
            type: "newspaper-folding",
            theme: "outline",
            size: "10",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handler('StatisticsByCampaigns', _ctx.subStatisticsComponentKey, _ctx.filters)))
          }, null, 512)), [
            [
              _directive_tooltip,
              _ctx.$t('campaigns'),
              void 0,
              { left: true }
            ]
          ])
        : _createCommentVNode("", true),
      (_ctx.statisticsFilters.bidder !== _ctx.BIDDER_FEED)
        ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
            key: 2,
            class: "mr-2 cursor-pointer",
            type: "picture",
            theme: "outline",
            size: "10",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handler('StatisticsByCreatives', _ctx.subStatisticsComponentKey, _ctx.filters)))
          }, null, 512)), [
            [
              _directive_tooltip,
              _ctx.$t('creatives'),
              void 0,
              { left: true }
            ]
          ])
        : _createCommentVNode("", true),
      (_ctx.statisticsFilters.bidder !== _ctx.BIDDER_ADVERTISER)
        ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
            key: 3,
            class: "mr-2 cursor-pointer",
            type: "file-quality-one",
            theme: "outline",
            size: "10",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handler('StatisticsByFeeds', _ctx.subStatisticsComponentKey, _ctx.filters)))
          }, null, 512)), [
            [
              _directive_tooltip,
              _ctx.$t('feeds'),
              void 0,
              { left: true }
            ]
          ])
        : _createCommentVNode("", true)
    ])
  ], 64))
}