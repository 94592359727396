import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsTitle = _resolveComponent("StatisticsTitle")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_Country = _resolveComponent("Country")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createVNode(_component_StatisticsTitle, {
          title: _ctx.$t('countries'),
          filters: _ctx.filters
        }, null, 8, ["title", "filters"])
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EntriesDataTableNext, {
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        "default-sort-by": "profit"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('country'),
                sortable: "",
                field: "country_id"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              (_ctx.user.show_bounces)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 0,
                    header: _ctx.$t('bounces'),
                    sortable: "",
                    field: "bounces"
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              _createVNode(_component_PrimeVueColumn, {
                header: "CPC",
                sortable: "",
                field: "cpc"
              }),
              _createVNode(_component_PrimeVueColumn, {
                header: "CPM",
                sortable: "",
                field: "cpm"
              }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('profit'),
                sortable: "",
                field: "profit"
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Country, {
                    country: data.country
                  }, null, 8, ["country"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.impressions)), 1)
            ]),
            _: 1
          }),
          (_ctx.user.show_bounces)
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 0 }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(_ctx.$n(data.bounces)), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.cpm, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.profit, 'payout')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler"])
    ])
  ], 64))
}