import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "grid mt-2" }
const _hoisted_5 = { class: "col-12 lg:col-3" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "col-12 lg:col-5" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "col-12 lg:col-4" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = {
  key: 1,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('advertiser_balance')) + ": " + _toDisplayString(_ctx.$n(_ctx.userBalance, 'payout')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FieldNumber, {
        modelValue: _ctx.form.amount,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.amount) = $event)),
        modelModifiers: { number: true },
        label: _ctx.$t('payment'),
        step: 5,
        "show-buttons": "",
        "button-layout": "horizontal",
        "increment-button-icon": "pi pi-plus",
        "decrement-button-icon": "pi pi-minus",
        prefix: "$"
      }, null, 8, ["modelValue", "label"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FieldTextarea, {
        modelValue: _ctx.form.comment,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.comment) = $event)),
        modelModifiers: { trim: true },
        label: _ctx.$t('comment')
      }, null, 8, ["modelValue", "label"])
    ]),
    _createVNode(_component_PrimeVueButton, {
      class: "p-button-primary w-full",
      label: _ctx.$t('add'),
      loading: _ctx.addPaymentLoading,
      onClick: _ctx.addPayment
    }, null, 8, ["label", "loading", "onClick"]),
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('payments')), 1),
    (_ctx.paymentsLoading)
      ? (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
          key: 0,
          height: "10px"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.payments.data.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.payments.data, (payment) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "insights mt-2",
                  key: payment.id
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('payment')), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$n(payment.amount, 'payout')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('comment')), 1),
                      (payment.comment)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(payment.comment), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, "—"))
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('date')), 1),
                      _createElementVNode("div", null, _toDisplayString(payment.created_at), 1)
                    ])
                  ])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('nothing')), 1))
        ], 64))
  ]))
}