
import type { PropType } from 'vue';

import {
  defineComponent,
  reactive,
  onMounted,
} from 'vue';

import type {
  Entries,
  EntriesPaginator,
  EntriesPaginatorEvent,
} from '@/types';

import {
  ENTRIES_PER_PAGE,
  ENTRIES_SORT_BY_DIRECTION_ASC,
  ENTRIES_SORT_BY_DIRECTION_DESC,
} from '@/libs/consts';

export default defineComponent({
  props: {
    entries: {
      type: Object as PropType<Entries>,
      required: true,
    },
    entriesLoading: {
      type: Boolean,
      required: true,
    },
    entriesFetched: {
      type: Boolean,
      required: true,
    },
    entriesHandler: {
      type: Function as PropType<(entiresPaginator?: EntriesPaginator) => Promise<void>>,
      required: true,
    },
    dataKey: {
      type: String,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    paginator: {
      type: Boolean,
      default: true,
    },
    defaultSortBy: {
      type: String,
      default: null,
    },
    defaultSortByDirection: {
      type: String,
      default: ENTRIES_SORT_BY_DIRECTION_DESC,
    },
    rowClass: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Function as PropType<(row: any) => string>,
    },
    showGridlines: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const entriesPaginator = reactive<EntriesPaginator>({
      entries_per_page: ENTRIES_PER_PAGE,
      page: 1,
      sort_by: props.defaultSortBy,
      sort_by_direction: props.defaultSortByDirection,
    } as EntriesPaginator);

    const onEntriesPaginatorEvent = (event: EntriesPaginatorEvent): void => {
      entriesPaginator.page = event.first / ENTRIES_PER_PAGE + 1;

      if (event.sortField) {
        entriesPaginator.sort_by = event.sortField;

        entriesPaginator.sort_by_direction = event.sortOrder === -1 ? ENTRIES_SORT_BY_DIRECTION_DESC : ENTRIES_SORT_BY_DIRECTION_ASC;
      }

      props.entriesHandler(entriesPaginator);
    };

    const paginatorEvents = {
      page: props.lazy ? onEntriesPaginatorEvent : undefined,
      sort: props.lazy ? onEntriesPaginatorEvent : undefined,
    };

    onMounted((): Promise<void> => (props.paginator ? props.entriesHandler(entriesPaginator) : props.entriesHandler()));

    return {
      ENTRIES_PER_PAGE,
      ENTRIES_SORT_BY_DIRECTION_DESC,
      paginatorEvents,
    };
  },
});
