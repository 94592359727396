import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), {
      "model-value": _ctx.modelValue,
      placeholder: _ctx.placeholder,
      loading: _ctx.loading,
      disabled: _ctx.disabled,
      "show-clear": _ctx.showClear,
      filter: _ctx.filter,
      "filter-placeholder": _ctx.filterPlaceholder,
      options: _ctx.sortedOptions,
      "option-value": _ctx.optionValue,
      "option-label": _ctx.optionLabel,
      "option-disabled": _ctx.optionDisabled,
      "data-key": _ctx.optionValue,
      display: "chip",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, null, 8, ["model-value", "placeholder", "loading", "disabled", "show-clear", "filter", "filter-placeholder", "options", "option-value", "option-label", "option-disabled", "data-key"])),
    (_ctx.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: "block",
          innerHTML: _ctx.help
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 2,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}