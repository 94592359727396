
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from 'vue';

import PrimeVueSidebar from 'primevue/sidebar';

import emitter from '@/libs/emitter';

import EntriesDataTable from '@/components/EntriesDataTable.vue';

export default defineComponent({
  components: {
    EntriesDataTable,
    PrimeVueSidebar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showFiltersButton: {
      type: Boolean,
      default: true,
    },
    hrefPrefix: {
      type: String,
    },
  },
  setup() {
    const showFilters = ref<boolean>(false);

    const entriesLoading = ref<boolean>(false);

    const fetchEntries = (): void => emitter.emit('fetchEntries');

    onMounted(() => {
      emitter.on('entriesLoading', (event: boolean): void => {
        entriesLoading.value = event;
      });
    });

    onUnmounted(() => {
      emitter.off('entriesLoading');
    });

    return {
      showFilters,
      entriesLoading,
      fetchEntries,
    };
  },
});
