
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    showClear: {
      type: Boolean,
    },
    maxWidth: {
      type: Number,
      required: true,
    },
    maxFileSizeMegabytes: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const toast = useToast();

    const onFileChange = (event: Event): void => {
      const { files } = event.target as HTMLInputElement;

      if (!files || !files.length) {
        return;
      }

      const file = files[0];

      if (file.size > props.maxFileSizeMegabytes * 1024 * 1024) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('file_size_too_big'),
          life: 5000,
        });

        return;
      }

      const reader = new FileReader();

      reader.onloadend = (): void => {
        emit('update:modelValue', reader.result);
      };

      reader.readAsDataURL(file);
    };

    const clear = (): void => {
      emit('update:modelValue', null);
    };

    return {
      onFileChange,
      clear,
    };
  },
});
