import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, Suspense as _Suspense, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsTitle = _resolveComponent("StatisticsTitle")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_Subid = _resolveComponent("Subid")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_EfficiencyColumn = _resolveComponent("EfficiencyColumn")!
  const _component_RequestsColumn = _resolveComponent("RequestsColumn")!
  const _component_ViewsColumn = _resolveComponent("ViewsColumn")!
  const _component_PayableViewsColumn = _resolveComponent("PayableViewsColumn")!
  const _component_CTRColumn = _resolveComponent("CTRColumn")!
  const _component_ClicksColumn = _resolveComponent("ClicksColumn")!
  const _component_ImpressionsColumn = _resolveComponent("ImpressionsColumn")!
  const _component_FeedsRequestsColumn = _resolveComponent("FeedsRequestsColumn")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createVNode(_component_StatisticsTitle, {
          title: _ctx.$t('subids'),
          filters: _ctx.filters
        }, null, 8, ["title", "filters"])
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EntriesDataTableNext, {
        class: "p-datatable-sm statistics-table text-xs",
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        lazy: true,
        "data-key": "subid_uuid",
        "default-sort-by": "publishers_payout",
        "row-class": _ctx.getStatisticBySubidRowClass,
        "show-gridlines": true
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('main'),
                colspan: 8
              }, null, 8, ["header"]),
              (_ctx.showFeedsSection)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 0,
                    header: _ctx.$t('feeds'),
                    colspan: 6
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              (_ctx.showCampaignsSection)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 1,
                    header: _ctx.$t('campaigns'),
                    colspan: 8
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                rowspan: 2,
                sortable: "",
                field: "publishers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                rowspan: 2,
                sortable: "",
                field: "publishers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('feeds_plus_advertisers_plus_conversions_payout'),
                rowspan: 2,
                sortable: "",
                field: "feeds_plus_advertisers_plus_conversions_payout_estimated"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('profit'),
                rowspan: 2,
                sortable: "",
                field: "profit_estimated"
              }, null, 8, ["header"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('subid'),
                sortable: "",
                field: "subid_uuid"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('efficiency'),
                sortable: "",
                field: "efficiency"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('requests'),
                sortable: "",
                field: "requests"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions'),
                sortable: "",
                field: "views"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('payable_views'),
                sortable: "",
                field: "payable_views"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: "CTR",
                sortable: "",
                field: "ctr_not_pop"
              }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('rtb_clicks'),
                sortable: "",
                field: "clicks"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('direct_clicks'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              (_ctx.showFeedsSection)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('requests'),
                      sortable: "",
                      field: "feeds_requests"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_payout'),
                      sortable: "",
                      field: "publishers_payout_by_feeds"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_cpc'),
                      sortable: "",
                      field: "publishers_cpc_by_feeds"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('feeds_payout'),
                      sortable: "",
                      field: "feeds_payout_estimated"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('feeds_cpc'),
                      sortable: "",
                      field: "feeds_cpc"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('profit'),
                      sortable: "",
                      field: "profit_by_feeds_estimated"
                    }, null, 8, ["header"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.showCampaignsSection)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_payout'),
                      sortable: "",
                      field: "publishers_payout_by_campaigns"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_cpc'),
                      sortable: "",
                      field: "publishers_cpc_by_campaigns"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('advertisers_payout'),
                      sortable: "",
                      field: "advertisers_payout"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('advertisers_cpc'),
                      sortable: "",
                      field: "advertisers_cpc"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('conversions_short'),
                      sortable: "",
                      field: "conversions"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('conversions_payout'),
                      sortable: "",
                      field: "conversions_payout"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: "CTC",
                      sortable: "",
                      field: "advertisers_ctc"
                    }),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('profit'),
                      sortable: "",
                      field: "profit_by_campaigns"
                    }, null, 8, ["header"])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Subid, {
                    "subid-uuid": data.subid_uuid,
                    subid: data.subid,
                    "onUpdate:subid": ($event: any) => ((data.subid) = $event),
                    "quality-presets": _ctx.qualityPresets,
                    "quality-presets-fetched": _ctx.qualityPresetsFetched,
                    "icon-size": _ctx.iconSize,
                    "show-status": ""
                  }, null, 8, ["subid-uuid", "subid", "onUpdate:subid", "quality-presets", "quality-presets-fetched", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_EfficiencyColumn, {
                    data: data,
                    "hide-requests-column": _ctx.hideRequestsColumn
                  }, null, 8, ["data", "hide-requests-column"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_RequestsColumn, {
                    data: data,
                    "hide-requests-column": _ctx.hideRequestsColumn,
                    "show-extra-data": _ctx.showExtraData
                  }, null, 8, ["data", "hide-requests-column", "show-extra-data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_PayableViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CTRColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ClicksColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: _ctx.filters.date || null, hour: _ctx.filters.hour || null, subid_uuid: data.subid_uuid },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ImpressionsColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: _ctx.filters.date || null, hour: _ctx.filters.hour || null, subid_uuid: data.subid_uuid },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          (_ctx.showFeedsSection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    (_openBlock(), _createBlock(_Suspense, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_FeedsRequestsColumn, {
                          data: data,
                          "hide-feeds-requests-column": _ctx.hideFeedsRequestsColumn
                        }, null, 8, ["data", "hide-feeds-requests-column"])
                      ]),
                      fallback: _withCtx(() => [
                        _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                      ]),
                      _: 2
                    }, 1024))
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout_by_feeds, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc_by_feeds, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$n(data.feeds_payout_estimated, 'payout')) + " ℮", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.feeds_cpc, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$n(data.profit_by_feeds_estimated, 'payout')) + " ℮", 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.showCampaignsSection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout_by_campaigns, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc_by_campaigns, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$n(data.advertisers_payout, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_cpc, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.conversions)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.conversions_payout, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_ctc, 'percent_with_fraction')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$n(data.profit_by_campaigns, 'payout')), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.feeds_plus_advertisers_plus_conversions_payout_estimated, 'payout' )) + " ℮", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$n(data.profit_estimated, 'payout')) + " ℮", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler", "row-class"])
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.subStatisticsComponent.name)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subStatisticsComponent.name), {
              key: _ctx.subStatisticsComponent.key,
              "statistics-filters": _ctx.statisticsFilters,
              filters: _ctx.subStatisticsComponent.filters,
              "hide-requests-column": _ctx.hideRequestsColumn,
              "hide-feeds-payout-column": _ctx.hideFeedsPayoutColumn,
              "show-extra-data": _ctx.showExtraData,
              "show-feeds-section": _ctx.showFeedsSection,
              "show-campaigns-section": _ctx.showCampaignsSection,
              "icon-size": _ctx.iconSize
            }, null, 8, ["statistics-filters", "filters", "hide-requests-column", "hide-feeds-payout-column", "show-extra-data", "show-feeds-section", "show-campaigns-section", "icon-size"]))
          : _createCommentVNode("", true)
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
      ]),
      _: 1
    }))
  ], 64))
}