import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = { class: "p-fluid" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('contact_information')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FieldText, {
        modelValue: _ctx.user.firstname,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.firstname) = $event)),
        modelModifiers: { trim: true },
        name: "firstname",
        "errors-map": _ctx.errorsMap,
        label: _ctx.$t('firstname')
      }, null, 8, ["modelValue", "errors-map", "label"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_FieldText, {
        modelValue: _ctx.user.lastname,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.lastname) = $event)),
        modelModifiers: { trim: true },
        name: "lastname",
        "errors-map": _ctx.errorsMap,
        label: _ctx.$t('lastname')
      }, null, 8, ["modelValue", "errors-map", "label"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_FieldText, {
        modelValue: _ctx.user.telegram,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.telegram) = $event)),
        modelModifiers: { trim: true },
        name: "telegram",
        "errors-map": _ctx.errorsMap,
        label: "Telegram"
      }, null, 8, ["modelValue", "errors-map"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_FieldText, {
        modelValue: _ctx.user.skype,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.skype) = $event)),
        modelModifiers: { trim: true },
        name: "skype",
        "errors-map": _ctx.errorsMap,
        label: "Skype"
      }, null, 8, ["modelValue", "errors-map"])
    ])
  ]))
}