import { toClipboard } from '@soerenmartius/vue3-clipboard';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default () => {
  const i18n = useI18n();

  const toast = useToast();

  const copyToClipboard = async (value: string): Promise<void> => {
    await toClipboard(value);

    toast.add({
      severity: 'success',
      summary: i18n.t('success'),
      detail: i18n.t('copied_to_clipboard'),
      life: 5000,
    });
  };

  return {
    copyToClipboard,
  };
};
