
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import {
  GenericObject,
  User,
} from '@/types';

import useStatisticsLink from '@/composable/useStatisticsLink';

import useUser from '@/composable/useUser';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
    showStatisticsButton: {
      type: Boolean,
      default: true,
    },
    showCheckerButton: {
      type: Boolean,
      default: true,
    },
    showSitesButton: {
      type: Boolean,
      default: false,
    },
    statisticsFiltersKey: {
      type: String,
      default: 'publisher_id',
    },
    statisticsFilters: {
      type: Object as PropType<keyof GenericObject>,
      default: () => ({}),
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
  setup() {
    const { activeUser } = useUser();

    const { encodeStatisticsFilters } = useStatisticsLink();

    return {
      activeUser,
      encodeStatisticsFilters,
    };
  },
});
