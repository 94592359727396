
import {
  defineComponent,
  onMounted,
} from 'vue';

import { GenericObject } from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  setup() {
    const {
      entriesLoading: jobsCountersLoading,
      entries: jobsCounters,
      fetchEntries: fetchJobsCounters,
    } = useEntriesNext<GenericObject>('/api/widget/getJobsCounters');

    onMounted(async (): Promise<void> => {
      await fetchJobsCounters();
    });

    return {
      jobsCountersLoading,
      jobsCounters,
    };
  },
});
