
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { User } from '@/types';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
});
