import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueSidebar = _resolveComponent("PrimeVueSidebar")!

  return (_openBlock(), _createBlock(_component_PrimeVueSidebar, {
    visible: _ctx.visible,
    position: "right",
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:visible', $event)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_PrimeVueButton, {
        class: "w-full mt-3",
        label: _ctx.$t('apply'),
        loading: _ctx.applyButtonLoading,
        onClick: _ctx.applyButtonHandler
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    _: 3
  }, 8, ["visible"]))
}