
import _keyBy from 'lodash/keyBy';

import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
  computed,
} from 'vue';

import type {
  Subid,
  QualityPreset,
  QualityPresets,
} from '@/types';

import useUser from '@/composable/useUser';

import useClipboard from '@/composable/useClipboard';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    SubidStatus: defineAsyncComponent(() => import('@/components/SubidStatus.vue')),
  },
  props: {
    subidUuid: {
      type: String,
      required: true,
    },
    subid: {
      type: Object as PropType<Subid>,
    },
    showUuid: {
      type: Boolean,
      default: true,
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showQualityPresets: {
      type: Boolean,
      default: true,
    },
    showCheckerButton: {
      type: Boolean,
      default: true,
    },
    qualityPresets: {
      type: Object as PropType<QualityPresets>,
    },
    qualityPresetsFetched: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
  setup(props, { emit }) {
    const { activeUser: user } = useUser();

    const { copyToClipboard } = useClipboard();

    const { encodeStatisticsFilters } = useStatisticsLink();

    const qualityPresetsMap = computed((): { [key: number]: QualityPreset } => (props.qualityPresets ? _keyBy(props.qualityPresets.data, 'id') : {}));

    const onSubidStatusChange = (subid: Subid): void => {
      emit('update:subid', subid);
    };

    return {
      user,
      copyToClipboard,
      encodeStatisticsFilters,
      qualityPresetsMap,
      onSubidStatusChange,
    };
  },
});
