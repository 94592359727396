import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Suspense as _Suspense, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-4" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = {
  key: 1,
  class: "card"
}
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = {
  key: 0,
  class: "col-12 lg:col-4"
}
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_Entry = _resolveComponent("Entry")!
  const _component_Feeds = _resolveComponent("Feeds")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Entry, {
      class: "mb-4",
      "entry-errors-map": _ctx.pseudositeErrorsMap,
      "onUpdate:entry-errors-map": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pseudositeErrorsMap) = $event)),
      title: _ctx.$t('pseudosite'),
      entry: _ctx.pseudosite,
      "entry-fetch-handler": _ctx.fetchPseudosite,
      "entry-store-handler": _ctx.storePseudosite,
      "entry-delete-handler": _ctx.deletePseudosite
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (!!_ctx.pseudosite.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_FieldText, {
                      "model-value": _ctx.pseudosite.id,
                      label: "ID",
                      disabled: ""
                    }, null, 8, ["model-value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeUser.isSuperAdmin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_FieldDropdown, {
                      modelValue: _ctx.pseudosite.project_id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pseudosite.project_id) = $event)),
                      modelModifiers: { number: true },
                      name: "project_id",
                      "errors-map": _ctx.pseudositeErrorsMap,
                      label: _ctx.$t('project'),
                      loading: _ctx.projectsLoading,
                      disabled: !!_ctx.pseudosite.id,
                      options: _ctx.projects.data,
                      "option-value": "id",
                      "option-label": "name",
                      "option-label-translate": false
                    }, null, 8, ["modelValue", "errors-map", "label", "loading", "disabled", "options"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_FieldDropdown, {
                  modelValue: _ctx.pseudosite.traffic_category,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pseudosite.traffic_category) = $event)),
                  modelModifiers: { number: true },
                  name: "traffic_category",
                  "errors-map": _ctx.pseudositeErrorsMap,
                  label: _ctx.$t('category'),
                  disabled: !!_ctx.pseudosite.id,
                  options: _ctx.TRAFFIC_CATEGORIES
                }, null, 8, ["modelValue", "errors-map", "label", "disabled", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_FieldText, {
                  modelValue: _ctx.pseudosite.host,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pseudosite.host) = $event)),
                  modelModifiers: { trim: true },
                  name: "host",
                  "errors-map": _ctx.pseudositeErrorsMap,
                  label: _ctx.$t('host')
                }, null, 8, ["modelValue", "errors-map", "label"])
              ])
            ])
          ]),
          (!!_ctx.pseudosite.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_FieldText, {
                      "model-value": _ctx.pseudosite.created_at,
                      label: _ctx.$t('created_at'),
                      disabled: ""
                    }, null, 8, ["model-value", "label"]),
                    _createVNode(_component_FieldText, {
                      "model-value": _ctx.pseudosite.updated_at,
                      label: _ctx.$t('updated_at'),
                      disabled: ""
                    }, null, 8, ["model-value", "label"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["entry-errors-map", "title", "entry", "entry-fetch-handler", "entry-store-handler", "entry-delete-handler"]),
    (!!_ctx.pseudosite.id)
      ? (_openBlock(), _createBlock(_Suspense, {
          key: 0,
          class: "mb-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Feeds, {
              filters: { id: _ctx.pseudosite.feeds_ids },
              "show-project-column": false
            }, null, 8, ["filters"])
          ]),
          fallback: _withCtx(() => [
            _createVNode(_component_PrimeVueColumn, { height: "10px" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}