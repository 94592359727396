import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inline mr-2"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = {
  key: 4,
  class: "mt-3 white-space-pre-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      (_ctx.endpoint.status === _ctx.ENDPOINT_STATUS_DISABLED)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "🚫"))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: "text-indigo-400",
        href: `/endpoints/${_ctx.endpoint.id}`,
        target: "_blank"
      }, _toDisplayString(_ctx.endpoint.compiled_name), 9, _hoisted_2),
      (_ctx.user.isStaff())
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "ml-2",
            href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ ..._ctx.statisticsFilters, endpoint_id: [_ctx.endpoint.id] })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "chart-histogram-two",
              theme: "outline",
              size: _ctx.iconSize
            }, null, 8, ["size"]), [
              [
                _directive_tooltip,
                _ctx.$t('statistics'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.showCheckerButton && _ctx.user.isStaff())
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            class: "ml-2",
            href: `/checker?filters=${_ctx.encodeStatisticsFilters({ date_from: _ctx.statisticsFilters.date_from || null, date_to: _ctx.statisticsFilters.date_to || null, endpoint_id: [_ctx.endpoint.id] })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "shield",
              theme: "outline",
              size: _ctx.iconSize
            }, null, 8, ["size"]), [
              [
                _directive_tooltip,
                _ctx.$t('checker'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.user.isStaff() && !!_ctx.endpoint.site_id)
        ? (_openBlock(), _createElementBlock("a", {
            key: 3,
            class: "ml-2",
            href: `/sites/${_ctx.endpoint.site_id}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "page",
              theme: "outline",
              size: _ctx.iconSize
            }, null, 8, ["size"]), [
              [
                _directive_tooltip,
                _ctx.$t('site'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showUuid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.endpoint.uuid), 1))
      : _createCommentVNode("", true),
    (_ctx.showUid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.endpoint.uid), 1))
      : _createCommentVNode("", true),
    (_ctx.user.isStaff())
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('revshare')) + ": " + _toDisplayString(_ctx.$n(_ctx.endpoint.revshare / 100, 'percent')), 1),
          (_ctx.endpoint.max_qps)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, "QPS: " + _toDisplayString(_ctx.endpoint.max_qps), 1))
            : _createCommentVNode("", true),
          (_ctx.endpoint.allow_feeds)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('feeds_allowed')), 1))
            : _createCommentVNode("", true),
          (_ctx.endpoint.allow_campaigns)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('campaigns_allowed')), 1))
            : _createCommentVNode("", true),
          (_ctx.endpoint.sensitive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('sensitive')), 1))
            : _createCommentVNode("", true),
          (_ctx.endpoint.comment)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.endpoint.comment), 1))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}