import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "mt-3 white-space-pre-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "name"),
      _createElementVNode("a", {
        class: "text-indigo-400",
        href: `/users/${_ctx.user.id}`,
        target: "_blank"
      }, _toDisplayString(_ctx.user.compiled_name), 9, _hoisted_1),
      (_ctx.showStatisticsButton)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "ml-2",
            href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ [_ctx.statisticsFiltersKey]: [_ctx.user.id], ..._ctx.statisticsFilters })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "chart-histogram-two",
              theme: "outline",
              size: _ctx.iconSize
            }, null, 8, ["size"]), [
              [
                _directive_tooltip,
                _ctx.$t('statistics'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.showCheckerButton && !_ctx.activeUser.isAffiliate())
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "ml-2",
            href: `/checker?filters=${_ctx.encodeStatisticsFilters({ user_id: [_ctx.user.id], ..._ctx.statisticsFilters })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "shield",
              theme: "outline",
              size: _ctx.iconSize
            }, null, 8, ["size"]), [
              [
                _directive_tooltip,
                _ctx.$t('checker'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.showSitesButton)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            class: "ml-2",
            href: `/sites?filters=${_ctx.encodeStatisticsFilters({ user_id: _ctx.user.id })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "page",
              theme: "outline",
              size: _ctx.iconSize
            }, null, 8, ["size"]), [
              [
                _directive_tooltip,
                _ctx.$t('sites'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showBalance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('balance')) + ": " + _toDisplayString(_ctx.$n(_ctx.user.balance, 'payout')), 1))
      : _createCommentVNode("", true),
    (_ctx.user.comment)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.user.comment), 1))
      : _createCommentVNode("", true)
  ]))
}