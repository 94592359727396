
import _keyBy from 'lodash/keyBy';

import axios from 'axios';

import moment from 'moment';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue';

import PrimeVuePanel from 'primevue/panel';

import {
  GenericObject,
  EntriesMap,
  User,
  UsersReport,
  Links,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    PrimeVuePanel,
    User: defineAsyncComponent(() => import('@/components/User.vue')),
  },
  setup() {
    const {
      entries: links,
      entriesLoading: linksLoading,
      fetchEntries: fetchLinks,
    } = useEntriesNext<Links>('/api/link/getLinks');

    const usersReportFilters = reactive<GenericObject>({
      date_from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      link_id: null,
    });

    const usersReportLoading = ref<boolean>(false);

    const usersReportFetched = ref<boolean>(false);

    const usersReport = reactive<UsersReport>({
      users: {
        data: [],
        // TODO: Сделать meta необязательным свойством
        meta: {
          total: 0,
        },
      },
      users_ids_has_sites: [],
      users_ids_has_sites_and_has_not_traffic: [],
      users_has_traffic: {
        data: [],
        // TODO: Сделать meta необязательным свойством (?)
        meta: {
          total: 0,
        },
      },
      users_has_traffic_now: {
        data: [],
        // TODO: Сделать meta необязательным свойством (?)
        meta: {
          total: 0,
        },
      },
    });

    const fetchUsersReport = async (): Promise<void> => {
      usersReportLoading.value = true;

      const response = await axios.post('/api/tool/getUsersReport', usersReportFilters);

      Object.assign(usersReport, response.data);

      usersReportLoading.value = false;

      usersReportFetched.value = true;
    };

    const usersMap = computed((): EntriesMap => _keyBy(usersReport.users.data, 'id'));

    const usersHasSites = computed((): User[] => usersReport.users.data.filter((user: User) => usersReport.users_ids_has_sites.includes(user.id)));

    const usersHasSitesAndHasNoTraffic = computed((): User[] => usersReport.users.data.filter((user: User) => usersReport.users_ids_has_sites_and_has_not_traffic.includes(user.id)));

    const usersHasNoSites = computed((): User[] => usersReport.users.data.filter((user: User) => !usersReport.users_ids_has_sites.includes(user.id)));

    const statisticsFilters = computed((): GenericObject => ({
      date_from: usersReportFilters.date_from,
      date_to: usersReportFilters.date_to,
    }));

    const entriesHandlerStub = (): Promise<void> => Promise.resolve();

    const getPlashka = (row: GenericObject): string => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (row.impressions_filtered_percent! as number <= 0.5 && row.impressions_has_social_auth_percent! as number >= 0.2) {
        return '🥇';
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (row.impressions_filtered_percent! as number <= 0.7 && row.impressions_has_social_auth_percent! as number >= 0.1) {
        return '🟢';
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (row.impressions_filtered_percent! as number <= 0.8 && row.impressions_has_social_auth_percent! as number >= 0.02) {
        return '🟡';
      }

      return '🔴';
    };

    const downloadCSV = (users: User[], name: string): void => {
      const csv = users.map((user) => [user.id, user.email].join(',')).join('\n');

      const blob = new Blob([csv], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.setAttribute('href', url);

      a.setAttribute('download', `${name}.csv`);

      a.click();
    };

    onMounted(() => Promise.all([
      fetchLinks(),
      fetchUsersReport(),
    ]));

    return {
      links,
      linksLoading,
      usersReportFilters,
      usersReportLoading,
      usersReportFetched,
      usersReport,
      fetchUsersReport,
      usersMap,
      usersHasSites,
      usersHasSitesAndHasNoTraffic,
      usersHasNoSites,
      statisticsFilters,
      entriesHandlerStub,
      getPlashka,
      downloadCSV,
    };
  },
});
