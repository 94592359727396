
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    showRefreshButton: {
      type: Boolean,
      default: false,
    },
    refreshButtonLoading: {
      type: Boolean,
      default: false,
    },
    refreshButtonHandler: {
      type: Function,
    },
    showFiltersButton: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
  },
});
