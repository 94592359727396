
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { Platform } from '@/types';

export default defineComponent({
  props: {
    platform: {
      type: Object as PropType<Platform>,
    },
  },
});
