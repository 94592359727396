
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
} from 'vue';

import type {
  EntriesPaginator,
  Links,
} from '@/types';

import { ENTRIES } from '@/libs/consts';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const links = ref<Links>(ENTRIES);

    const fetchLinks = async (linksPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/link/getLinksPaginator',
        {
          ...linksPaginator,
        },
      );

      links.value = response.data;
    };

    return {
      activeUser,
      links,
      fetchLinks,
    };
  },
});
