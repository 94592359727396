import axios from 'axios';

import * as Sentry from '@sentry/vue';

import { BrowserTracing } from '@sentry/tracing';

import { createApp } from 'vue';

import { createI18n } from 'vue-i18n';

import PrimeVue from 'primevue/config';

import PrimeVueButton from 'primevue/button';

import PrimeVueTag from 'primevue/tag';

import PrimeVueBadge from 'primevue/badge';

import PrimeVueDataTable from 'primevue/datatable';

import PrimeVueColumn from 'primevue/column';

import PrimeVueColumnGroup from 'primevue/columngroup';

import PrimeVueRow from 'primevue/row';

import PrimeVueSkeleton from 'primevue/skeleton';

import PrimeVueDialog from 'primevue/dialog';

import PrimeVueVirtualScroller from 'primevue/virtualscroller';

import PrimeVueTooltip from 'primevue/tooltip';

import PrimeVueToastService from 'primevue/toastservice';

import PrimeVueConfirmationService from 'primevue/confirmationservice';

import { IconPark } from '@icon-park/vue-next/es/all';

import 'nprogress/nprogress.css';

import 'primevue/resources/primevue.min.css';

import 'primeflex/primeflex.min.css';

import 'primeicons/primeicons.css';

import '@icon-park/vue-next/styles/index.css';

import '@/assets/style.css';

import store from '@/store';

import router from '@/router';

import en from '@/locales/en';

import App from '@/App.vue';

import Entries from '@/components/Entries.vue';

import EntriesDataTable from '@/components/EntriesDataTable.vue';

import EntriesDataTableNext from '@/components/EntriesDataTableNext.vue';

import Entry from '@/components/Entry.vue';

import FieldText from '@/components/FieldText.vue';

import FieldTextarea from '@/components/FieldTextarea.vue';

import FieldNumber from '@/components/FieldNumber.vue';

import FieldBidCPC from '@/components/FieldBidCPC.vue';

import FieldBidCPM from '@/components/FieldBidCPM.vue';

import FieldCheckbox from '@/components/FieldCheckbox.vue';

import FieldDropdown from '@/components/FieldDropdown.vue';

import FieldDropdownNext from '@/components/FieldDropdownNext.vue';

import FieldErrors from '@/components/FieldErrors.vue';

import FieldDate from '@/components/FieldDate.vue';

import FieldListBox from '@/components/FieldListBox.vue';

import HighlightedNumber from '@/components/Statistics/HighlightedNumber.vue';

import NumberWithPercent from '@/components/Statistics/NumberWithPercent.vue';

import Header from '@/components/Header.vue';

import StatisticsTitle from '@/components/Statistics/StatisticsTitle.vue';

import FiltersSidebar from '@/components/FiltersSidebar.vue';

import Analytics from '@/components/Analytics.vue';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const numberFormats = {
  payout: {
    style: 'currency',
    currency: 'USD',
  },
  payout_3signs: {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 3,
  },
  payout_rounded: {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  },
  bid: {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 1,
    maximumFractionDigits: 5,
  },
  percent: {
    style: 'percent',
  },
  percent_with_fraction: {
    style: 'percent',
    maximumFractionDigits: 2,
  },
};

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
  numberFormats: {
    en: numberFormats,
  },
});

const app = createApp(App)
  .use(i18n)
  .use(PrimeVue, {
    locale: {
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      weekHeader: 'Wk',
      firstDayOfWeek: 1,
      dateFormat: 'dd/mm/yy',
    },
  })
  .use(PrimeVueToastService)
  .use(PrimeVueConfirmationService)
  .use(store)
  .use(router)
  .component('PrimeVueButton', PrimeVueButton)
  .component('PrimeVueTag', PrimeVueTag)
  .component('PrimeVueBadge', PrimeVueBadge)
  .component('PrimeVueDataTable', PrimeVueDataTable)
  .component('PrimeVueColumn', PrimeVueColumn)
  .component('PrimeVueColumnGroup', PrimeVueColumnGroup)
  .component('PrimeVueRow', PrimeVueRow)
  .component('PrimeVueSkeleton', PrimeVueSkeleton)
  .component('PrimeVueDialog', PrimeVueDialog)
  .component('PrimeVueVirtualScroller', PrimeVueVirtualScroller)
  .component('IconPark', IconPark)
  .component('Entries', Entries)
  .component('EntriesDataTable', EntriesDataTable)
  .component('EntriesDataTableNext', EntriesDataTableNext)
  .component('Entry', Entry)
  .component('FieldText', FieldText)
  .component('FieldTextarea', FieldTextarea)
  .component('FieldNumber', FieldNumber)
  .component('FieldBidCPC', FieldBidCPC)
  .component('FieldBidCPM', FieldBidCPM)
  .component('FieldCheckbox', FieldCheckbox)
  .component('FieldDropdown', FieldDropdown)
  .component('FieldDropdownNext', FieldDropdownNext)
  .component('FieldErrors', FieldErrors)
  .component('FieldDate', FieldDate)
  .component('FieldListBox', FieldListBox)
  .component('HighlightedNumber', HighlightedNumber)
  .component('NumberWithPercent', NumberWithPercent)
  .component('Header', Header)
  .component('StatisticsTitle', StatisticsTitle)
  .component('FiltersSidebar', FiltersSidebar)
  .component('Analytics', Analytics)
  .directive('tooltip', PrimeVueTooltip);

app.mount('#app');

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [/^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});
