import axios from 'axios';

import { ref } from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import { ErrorsMap } from '@/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default (handler: CallableFunction) => {
  const i18n = useI18n();

  const toast = useToast();

  const entryLoading = ref<boolean>(false);

  const entryErrorsMap = ref<ErrorsMap>({});

  const storeEntry = async (): Promise<void> => {
    entryLoading.value = true;

    entryErrorsMap.value = {};

    try {
      await handler();

      toast.add({
        severity: 'success',
        summary: i18n.t('success'),
        detail: i18n.t('success_store'),
        life: 5000,
      });
    } catch (error) {
      // В случае ошибки валидации API возвращает ответ с кодом 422
      if (axios.isAxiosError(error) && error.response?.status === 422) {
        entryErrorsMap.value = error.response.data;

        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('validation_error'),
          life: 5000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }
    }

    entryLoading.value = false;
  };

  return {
    entryLoading,
    entryErrorsMap,
    storeEntry,
  };
};
