
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import {
  defineComponent,
  ref,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  setup() {
    const i18n = useI18n();

    const toast = useToast();

    const emails = ref<string>('');

    const ids = ref<string>('');

    const loading = ref<boolean>(false);

    const errorsMap = ref<ErrorsMap>({});

    const convert = async (): Promise<void> => {
      loading.value = true;

      try {
        const response = await axios.post('/api/tool/getUsersIdsByEmails', {
          emails: emails.value.split('\n'),
        });

        ids.value = response.data.join('\n');
      } catch (error: unknown | AxiosError) {
        // В случае ошибки валидации API возвращает ответ с кодом 422
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          errorsMap.value = error.response.data as ErrorsMap;

          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('validation_error'),
            life: 5000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('unknown_error'),
            life: 5000,
          });
        }
      }

      loading.value = false;
    };

    return {
      emails,
      ids,
      loading,
      errorsMap,
      convert,
    };
  },
});
