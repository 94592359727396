import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!

  return (_ctx.asIcon)
    ? (_openBlock(), _createBlock(_component_IconPark, {
        class: _normalizeClass(["cursor-pointer", _ctx.iconClass]),
        key: _ctx.iconType,
        type: _ctx.iconType,
        theme: "outline",
        size: _ctx.iconSize,
        onClick: _ctx.changeStatus
      }, null, 8, ["class", "type", "size", "onClick"]))
    : (_openBlock(), _createBlock(_component_PrimeVueButton, {
        key: 1,
        class: _normalizeClass(["p-button-rounded p-button-text", _ctx.buttonClass]),
        icon: _ctx.buttonIcon,
        loading: _ctx.changeStatusLoading,
        onClick: _ctx.changeStatus
      }, null, 8, ["class", "icon", "loading", "onClick"]))
}