
import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
} from 'vue';

import PrimeVueDialog from 'primevue/dialog';

import 'vue-advanced-cropper/dist/style.css';

import { Cropper } from 'vue-advanced-cropper';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  components: {
    PrimeVueDialog,
    Cropper,
  },
  props: {
    modelValue: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    maxWidth: {
      type: Number,
      required: true,
    },
    aspectRatio: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);

    const imageBase64 = ref<ArrayBuffer|string|null>(null);

    const imageBase64Cropped = ref<string|null>(null);

    const onFileChange = (event: Event): void => {
      const { files } = event.target as HTMLInputElement;

      if (!files || !files.length) {
        return;
      }

      const file = files[0];

      const reader = new FileReader();

      reader.onloadend = (): void => {
        imageBase64.value = reader.result;
      };

      reader.readAsDataURL(file);
    };

    const onCropperChange = (attributes: { canvas: HTMLCanvasElement }): void => {
      imageBase64Cropped.value = attributes.canvas.toDataURL();
    };

    const save = (): void => {
      emit('update:modelValue', imageBase64Cropped.value);

      dialog.value = false;
    };

    return {
      dialog,
      imageBase64,
      onFileChange,
      onCropperChange,
      save,
    };
  },
});
