import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.data.views_icon)), 1)
    ])), [
      [
        _directive_tooltip,
        _ctx.$t('icon'),
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.data.views_image)), 1)
    ])), [
      [
        _directive_tooltip,
        _ctx.$t('image'),
        void 0,
        { left: true }
      ]
    ])
  ]))
}