import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        class: "text-indigo-400",
        href: `/creatives/${_ctx.creative.id}`,
        target: "_blank"
      }, _toDisplayString(_ctx.creative.compiled_name), 9, _hoisted_1),
      (!_ctx.user.isAffiliate())
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "ml-2",
            href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ creative_id: [_ctx.creative.id] })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_IconPark, {
              class: "text-blue-600 cursor-pointer",
              type: "chart-histogram-two",
              theme: "outline",
              size: "14"
            }, null, 512), [
              [
                _directive_tooltip,
                _ctx.$t('statistics'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_2))
        : _createCommentVNode("", true)
    ])
  ]))
}