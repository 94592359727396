import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_IconPark, {
      class: "mr-2 cursor-pointer",
      type: "link-in",
      theme: "outline",
      size: "12",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handler('StatisticsByEndpoints', _ctx.subStatisticsComponentKey, _ctx.filters)))
    }, null, 512), [
      [
        _directive_tooltip,
        _ctx.$t('endpoints'),
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives(_createVNode(_component_IconPark, {
      class: "mr-2 cursor-pointer",
      type: "tag-one",
      theme: "outline",
      size: "12",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handler('StatisticsBySubids', _ctx.subStatisticsComponentKey, _ctx.filters)))
    }, null, 512), [
      [
        _directive_tooltip,
        _ctx.$t('subids'),
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives(_createVNode(_component_IconPark, {
      class: "mr-2 cursor-pointer",
      type: "globe",
      theme: "outline",
      size: "12",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handler('StatisticsByCountries', _ctx.subStatisticsComponentKey, _ctx.filters)))
    }, null, 512), [
      [
        _directive_tooltip,
        _ctx.$t('countries'),
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives(_createVNode(_component_IconPark, {
      class: "mr-2 cursor-pointer",
      type: "windows",
      theme: "outline",
      size: "12",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handler('StatisticsByPlatforms', _ctx.subStatisticsComponentKey, _ctx.filters)))
    }, null, 512), [
      [
        _directive_tooltip,
        _ctx.$t('platforms'),
        void 0,
        { left: true }
      ]
    ]),
    _withDirectives(_createVNode(_component_IconPark, {
      class: "mr-2 cursor-pointer",
      type: "browser",
      theme: "outline",
      size: "12",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handler('StatisticsByBrowsers', _ctx.subStatisticsComponentKey, _ctx.filters)))
    }, null, 512), [
      [
        _directive_tooltip,
        _ctx.$t('browsers'),
        void 0,
        { left: true }
      ]
    ])
  ], 64))
}