
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import PrimeVueMessage from 'primevue/message';

import useStatisticsLink from '@/composable/useStatisticsLink';

import useEntries from '@/composable/useEntries@next';

import useEntry from '@/composable/useEntry';

import type {
  Endpoint,
  Endpoints,
} from '@/types';

import {
  ENTRIES,
  TRAFFIC_CATEGORIES,
  TRAFFIC_CATEGORIES_MAP,
  ENDPOINT_STATUS_DISABLED,
} from '@/libs/consts';

export default defineComponent({
  components: {
    PrimeVueMessage,
  },
  setup() {
    const MAX_DIRECT_LINKS = 3;

    const { encodeStatisticsFilters } = useStatisticsLink();

    const {
      entries: endpoints,
      entriesLoading: endpointsLoading,
      entriesFetched: endpointsFetched,
      fetchEntries: fetchEndpoints,
    } = useEntries<Endpoints>('/api/direct-link/getEndpoints ', ENTRIES);

    const storeDirectLinkDialog = ref<boolean>(false);

    const storeDirectLinkForm = reactive({
      traffic_category: null,
      passback_url: '',
    });

    const storeDirectLinkFormAgreement = ref<boolean>(false);

    const {
      entryLoading: storeDirectLinkLoading,
      entryErrorsMap: storeDirectLinkFormErrorsMap,
      storeEntry: storeDirectLink,
    } = useEntry(async () => {
      const response = await axios.post('/api/direct-link/storeDirectLink', storeDirectLinkForm);

      endpoints.data = [response.data, ...endpoints.data];

      storeDirectLinkDialog.value = false;

      storeDirectLinkFormAgreement.value = false;
    });

    const storePassbackUrlDialog = ref<boolean>(false);

    const storePassbackUrlForm = reactive({
      endpoint_id: 0,
      passback_url: '',
    });

    const {
      entryLoading: storePassbackUrlLoading,
      storeEntry: storePassbackUrl,
    } = useEntry(async () => {
      const response = await axios.post('/api/direct-link/storePassbackUrl', storePassbackUrlForm);

      const index = endpoints.data.findIndex((ep) => ep.id === response.data.id);

      endpoints.data[index] = response.data;
    });

    const showStorePassbackUrlDialog = (endpoint: Endpoint): void => {
      storePassbackUrlDialog.value = true;

      storePassbackUrlForm.endpoint_id = endpoint.id;

      storePassbackUrlForm.passback_url = endpoint.passback_url as string;
    };

    const getDirectLinkUrl = (endpoint: Endpoint): string => `https://abpsl23.com/endpoint?endpoint_uuid=${endpoint.uuid}&subid=default`;

    onMounted(() => fetchEndpoints());

    return {
      TRAFFIC_CATEGORIES,
      TRAFFIC_CATEGORIES_MAP,
      ENDPOINT_STATUS_DISABLED,
      MAX_DIRECT_LINKS,
      encodeStatisticsFilters,
      endpoints,
      endpointsLoading,
      endpointsFetched,
      storeDirectLinkDialog,
      storeDirectLinkForm,
      storeDirectLinkFormAgreement,
      storeDirectLinkLoading,
      storeDirectLinkFormErrorsMap,
      storeDirectLink,
      storePassbackUrlDialog,
      storePassbackUrlForm,
      storePassbackUrlLoading,
      storePassbackUrl,
      showStorePassbackUrlDialog,
      getDirectLinkUrl,
    };
  },
});
