import type { GenericObject } from '@/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default () => {
  const encodeStatisticsFilters = (filters: GenericObject): string => {
    const cleanedFilters = { ...filters };

    Object
      .keys(filters)
      .forEach((key) => filters[key] == null && delete cleanedFilters[key]);

    const cleanedFiltersJSON = JSON.stringify(cleanedFilters);

    return btoa(cleanedFiltersJSON);
  };

  const decodeStatisticsFilters = (base64: string): GenericObject => {
    try {
      const filtersJSON = atob(base64);

      return JSON.parse(filtersJSON);
    } catch (error) {
      return {};
    }
  };

  return {
    encodeStatisticsFilters,
    decodeStatisticsFilters,
  };
};
