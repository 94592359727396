
import moment from 'moment';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import PrimeVueSidebar from 'primevue/sidebar';

import PrimeVueCalendar from 'primevue/calendar';

import {
  ENTRIES,
  PRESELECTED_PROJECTS_IDS,
} from '@/libs/consts';

import useEntriesNext from '@/composable/useEntries@next';

import type {
  Option,
  Projects,
  Endpoints,
  Feeds,
  Countries,
  ChainsDiffs,
} from '@/types';

export default defineComponent({
  components: {
    PrimeVueSidebar,
    PrimeVueCalendar,
  },
  setup() {
    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries', ENTRIES);

    const {
      entries: endpoints,
      entriesLoading: endpointsLoading,
      fetchEntries: fetchEndpoints,
    } = useEntriesNext<Endpoints>('/api/endpoint/getEndpoints', ENTRIES);

    const {
      entries: feeds,
      entriesLoading: feedsLoading,
      fetchEntries: fetchFeeds,
    } = useEntriesNext<Feeds>('/api/feed/getFeeds', ENTRIES);

    const {
      entries: chainsDiff,
      entriesLoading: chainsDiffLoading,
      entriesFetched: chainsDiffFetched,
      fetchEntries: fetchchainsDiff,
    } = useEntriesNext<ChainsDiffs>('/api/chain-diff ', ENTRIES);

    const showFilters = ref(false);

    const filtersDateFrom = moment().subtract(7, 'days');

    const filtersDateTo = moment().subtract(1, 'days');

    const filters = reactive({
      date_from: filtersDateFrom.format('YYYY-MM-DD'),
      date_to: filtersDateTo.format('YYYY-MM-DD'),
      project_id: PRESELECTED_PROJECTS_IDS,
      endpoint_id: null,
      country_id: null,
      feed_id: null,
      column_name: [
        'endpoint_id',
        'feed_id',
      ],
    });

    const filtersDatePeriod = ref([
      filtersDateFrom.toDate(),
      filtersDateTo.toDate(),
    ]);

    const fetchchainsDiffWrapper = async (): Promise<void> => fetchchainsDiff(filters);

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints({
      project_id: filters.project_id,
    });

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds({
      project_id: filters.project_id,
    });

    const onFiltersDatePeriodChange = (value: Date[]): void => {
      filters.date_from = moment(value[0]).format('YYYY-MM-DD');

      filters.date_to = moment(value[1]).format('YYYY-MM-DD');
    };

    const onProjectChange = (): void => {
      filters.endpoint_id = null;

      fetchEndpointsWrapper();

      filters.feed_id = null;

      fetchFeedsWrapper();
    };

    const columnNameOptions : Option[] = [
      {
        value: 'endpoint_id',
        label: 'Endpoint ID',
      },
      {
        value: 'feed_id',
        label: 'Feed ID',
      },
      {
        value: 'country_id',
        label: 'Country 🌍',
      },
    ];

    onMounted(() => Promise.all([
      fetchProjects(),
      fetchCountries(),
      fetchEndpointsWrapper(),
      fetchFeedsWrapper(),
      fetchchainsDiffWrapper(),
    ]));

    return {
      projects,
      projectsLoading,
      countries,
      countriesLoading,
      endpoints,
      endpointsLoading,
      feeds,
      feedsLoading,
      chainsDiff,
      chainsDiffLoading,
      chainsDiffFetched,
      fetchchainsDiffWrapper,
      showFilters,
      filters,
      filtersDatePeriod,
      onFiltersDatePeriodChange,
      onProjectChange,
      columnNameOptions,
    };
  },
});
