import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "mt-2 pt-2 text-sm white-space-pre-wrap border-1 border-100 border-left-none border-right-none border-bottom-none"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "p-fluid" }
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_10 = {
  key: 1,
  class: "p-fluid"
}
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "p-fluid" }
const _hoisted_13 = { class: "p-fluid" }
const _hoisted_14 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EndpointMeta = _resolveComponent("EndpointMeta")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_UserColumn = _resolveComponent("UserColumn")!
  const _component_SiteColumn = _resolveComponent("SiteColumn")!
  const _component_PrimeVueTag = _resolveComponent("PrimeVueTag")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_Entries = _resolveComponent("Entries")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Entries, {
    title: _ctx.$t('endpoints'),
    "show-add-button": !_ctx.activeUser.isAffiliate(),
    "href-prefix": "endpoints"
  }, {
    tables: _withCtx(() => [
      _createVNode(_component_EntriesDataTable, {
        entries: _ctx.endpoints,
        "entries-handler": _ctx.fetchEndpoints,
        "expanded-rows": _ctx.endpointsExpandedRows,
        "onUpdate:expanded-rows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.endpointsExpandedRows) = $event)),
        "href-prefix": "endpoints"
      }, _createSlots({
        "columns-expansion": _withCtx(({ data }) => [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(_component_EndpointMeta, {
                endpoint: data,
                "show-compiled-name": true,
                "show-uuid": true,
                "show-comment": !_ctx.activeUser.isAffiliate()
              }, null, 8, ["endpoint", "show-comment"])
            ]),
            fallback: _withCtx(() => [
              _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
            ]),
            _: 2
          }, 1024))
        ]),
        columns: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, {
            field: "id",
            header: "ID",
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", null, _toDisplayString(data.id), 1),
              (!_ctx.activeUser.isAffiliate())
                ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(data.uid), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.showProjectColumn && _ctx.activeUser.isSuperAdmin())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 0,
                field: "project_id",
                header: _ctx.$t('project'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.project.name), 1)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          (_ctx.showUserColumn && !_ctx.activeUser.isAffiliate())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 1,
                field: "user_id",
                header: _ctx.$t('user'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_UserColumn, {
                        user: data.user,
                        "show-email-as-link": true
                      }, null, 8, ["user"])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueColumn, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024)),
                  (data.comment)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(data.comment), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, {
            field: "site_id",
            header: _ctx.$t('site'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.site_id)
                ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SiteColumn, {
                        site: data.site
                      }, null, 8, ["site"])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueColumn, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, "—"))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "traffic_type",
            header: _ctx.$t('type'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.TRAFFIC_TYPES_MAP[data.traffic_type].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "traffic_category",
            header: _ctx.$t('category'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.TRAFFIC_CATEGORIES_MAP[data.traffic_category].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "protocol",
            header: _ctx.$t('protocol'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.PROTOCOLS_MAP[data.protocol].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "status",
            header: _ctx.$t('status'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_PrimeVueTag, {
                value: _ctx.$t(_ctx.ENDPOINT_STATUSES_MAP[data.status].translate_key),
                severity: _ctx.ENDPOINT_STATUSES_MAP[data.status].severity
              }, null, 8, ["value", "severity"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "max_qps",
            header: _ctx.$t('max_qps'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.max_qps)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$n(data.max_qps)), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, "—"))
            ]),
            _: 1
          }, 8, ["header"]),
          (!_ctx.activeUser.isAffiliate())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 2,
                field: "revshare",
                header: _ctx.$t('revshare'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.revshare) + "%", 1)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, {
            field: "updated_at",
            header: _ctx.$t('updated_at'),
            sortable: ""
          }, null, 8, ["header"])
        ]),
        _: 2
      }, [
        (!_ctx.activeUser.isAffiliate())
          ? {
              name: "actions",
              fn: _withCtx(({ data }) => [
                _createElementVNode("a", {
                  href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ endpoint_id: [data.id] })}`,
                  target: "_blank"
                }, [
                  _withDirectives(_createVNode(_component_PrimeVueButton, {
                    class: "p-button-rounded p-button-text",
                    icon: "pi pi-chart-bar"
                  }, null, 512), [
                    [
                      _directive_tooltip,
                      _ctx.$t('statistics'),
                      void 0,
                      { right: true }
                    ]
                  ])
                ], 8, _hoisted_1)
              ])
            }
          : undefined
      ]), 1032, ["entries", "entries-handler", "expanded-rows"])
    ]),
    filters: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_FieldText, {
          modelValue: _ctx.endpointsFilters.uid,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endpointsFilters.uid) = $event)),
          modelModifiers: { trim: true },
          label: "UID"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_FieldText, {
          modelValue: _ctx.endpointsFilters.uuid,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endpointsFilters.uuid) = $event)),
          modelModifiers: { trim: true },
          label: "UUID"
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.showProjectColumn && _ctx.activeUser.isSuperAdmin())
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.endpointsFilters.project_id,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.endpointsFilters.project_id) = $event)),
                _ctx.onProjectChange
              ],
              modelModifiers: { number: true },
              label: _ctx.$t('project'),
              loading: _ctx.projectsLoading,
              "show-clear": true,
              options: _ctx.projects.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false
            }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showUserColumn && !_ctx.activeUser.isAffiliate())
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.endpointsFilters.user_id,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.endpointsFilters.user_id) = $event)),
              modelModifiers: { number: true },
              label: _ctx.$t('user'),
              loading: _ctx.usersLoading,
              filter: true,
              "show-clear": true,
              options: _ctx.users.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false
            }, null, 8, ["modelValue", "label", "loading", "options"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.endpointsFilters.traffic_type,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.endpointsFilters.traffic_type) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('type'),
          "show-clear": true,
          options: _ctx.TRAFFIC_TYPES
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.endpointsFilters.traffic_category,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.endpointsFilters.traffic_category) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('category'),
          "show-clear": true,
          options: _ctx.TRAFFIC_CATEGORIES
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.endpointsFilters.region,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.endpointsFilters.region) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('region'),
          "show-clear": true,
          options: _ctx.REGIONS
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.endpointsFilters.status,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.endpointsFilters.status) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('status'),
          "show-clear": true,
          options: _ctx.PROJECT_STATUSES
        }, null, 8, ["modelValue", "label", "options"])
      ])
    ]),
    _: 1
  }, 8, ["title", "show-add-button"]))
}