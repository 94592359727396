
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import type { User } from '@/types';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    showEmailAsLink: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const sectionClasses: string[] = [
      'mt-2',
      'pt-2',
      'text-sm',
      'white-space-pre-wrap',
      'border-1',
      'border-100',
      'border-left-none',
      'border-right-none',
      'border-bottom-none',
    ];

    return {
      sectionClasses,
    };
  },
});
