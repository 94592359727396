import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.archivedAt)
    ? _withDirectives((_openBlock(), _createBlock(_component_PrimeVueButton, {
        key: 0,
        class: "p-button-rounded p-button-text p-button-secondary",
        icon: "pi pi-arrow-circle-up",
        loading: _ctx.loading,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeArchivedAt(false)))
      }, null, 8, ["loading"])), [
        [_directive_tooltip, _ctx.$t('unarchive')]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_PrimeVueButton, {
        key: 1,
        class: "p-button-rounded p-button-text p-button-secondary",
        icon: "pi pi-trash",
        loading: _ctx.loading,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeArchivedAt(true)))
      }, null, 8, ["loading"])), [
        [_directive_tooltip, _ctx.$t('archive')]
      ])
}