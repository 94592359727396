import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_Entries = _resolveComponent("Entries")!

  return (_openBlock(), _createBlock(_component_Entries, {
    title: _ctx.$t('pseudosites'),
    "href-prefix": "pseudosites"
  }, {
    tables: _withCtx(() => [
      _createVNode(_component_EntriesDataTable, {
        entries: _ctx.pseudosites,
        "entries-handler": _ctx.fetchPseudosites,
        "href-prefix": "pseudosites"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, {
            field: "id",
            header: "ID",
            sortable: ""
          }),
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 0,
                field: "project_id",
                header: _ctx.$t('project'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.project.name), 1)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, {
            field: "traffic_category",
            header: _ctx.$t('category'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.TRAFFIC_CATEGORIES_MAP[data.traffic_category].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "host",
            header: _ctx.$t('host'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(data.host), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "feeds_count",
            header: "Feeds"
          }),
          _createVNode(_component_PrimeVueColumn, {
            field: "updated_at",
            header: _ctx.$t('updated_at'),
            sortable: ""
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["entries", "entries-handler"])
    ]),
    filters: _withCtx(() => [
      (_ctx.user.isSuperAdmin())
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.pseudositesFilters.project_id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pseudositesFilters.project_id) = $event)),
              modelModifiers: { number: true },
              label: _ctx.$t('project'),
              loading: _ctx.projectsLoading,
              "show-clear": true,
              options: _ctx.projects.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false
            }, null, 8, ["modelValue", "label", "loading", "options"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.pseudositesFilters.traffic_category,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pseudositesFilters.traffic_category) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('category'),
          "show-clear": true,
          options: _ctx.TRAFFIC_CATEGORIES
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FieldText, {
          modelValue: _ctx.pseudositesFilters.host,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pseudositesFilters.host) = $event)),
          modelModifiers: { trim: true },
          label: _ctx.$t('host')
        }, null, 8, ["modelValue", "label"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}