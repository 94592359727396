
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  ErrorsMap,
  Pseudosite,
} from '@/types';

import { TRAFFIC_CATEGORIES } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Feeds: defineAsyncComponent(() => import('@/views/feeds/Feeds.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const { getEntryByRoute } = useEntries();

    const router = useRouter();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const pseudosite = reactive<Pseudosite>({} as unknown as Pseudosite);

    const pseudositeErrorsMap = ref<ErrorsMap>({});

    const fetchPseudosite = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/pseudosite/getPseudositeById');

      if (entry) {
        Object.assign(pseudosite, entry);
      }
    };

    const storePseudosite = async (): Promise<void> => {
      const response = await axios.post('/api/pseudosite/storePseudosite', pseudosite);

      Object.assign(pseudosite, response.data);
    };

    const deletePseudosite = async (): Promise<void> => {
      await axios.post('/api/pseudosite/deletePseudositeById', { id: pseudosite.id });

      await router.push('/pseudosites');
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
      ]);
    });

    return {
      TRAFFIC_CATEGORIES,
      activeUser,
      projectsLoading,
      projects,
      pseudosite,
      pseudositeErrorsMap,
      fetchPseudosite,
      storePseudosite,
      deletePseudosite,
    };
  },
});
