
import type { PropType } from 'vue';

import {
  defineComponent,
  reactive,
  watch,
} from 'vue';

import type { CustomBid } from '@/types';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<CustomBid[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    keyValidator: {
      type: Function,
      default: (key: string | number | null) => !!key,
    },
  },
  setup(props, { emit }) {
    const customBids = reactive<CustomBid[]>([...props.modelValue]);

    const addCustomBid = (): void => {
      customBids.push({
        key: null,
        bid: props.min,
      });
    };

    const deleteCustomBid = (index: number): void => {
      customBids.splice(index, 1);
    };

    watch(
      customBids,
      () => emit('update:modelValue', customBids.filter((customBid: CustomBid) => props.keyValidator(customBid.key))),
    );

    return {
      customBids,
      addCustomBid,
      deleteCustomBid,
    };
  },
});
