
import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
  watch,
} from 'vue';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
      default: 'CPC',
    },
    help: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0.01,
    },
  },
  setup(props, { emit }) {
    const cpc = ref<number>(props.modelValue || 0);

    watch(cpc, (currentValue: number) => emit('update:modelValue', currentValue));

    return {
      cpc,
    };
  },
});
