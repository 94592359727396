
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import {
  AdSecureAnalyzes,
  EntriesPaginator,
  Projects,
  Users,
  Campaigns,
  Feeds,
} from '@/types';

import {
  ENTRIES,
  ADSECURE_ANALYSIS_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    showRelationsColumns: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const {
      entries: campaigns,
      entriesLoading: campaignsLoading,
      fetchEntries: fetchCampaigns,
    } = useEntriesNext<Campaigns>('/api/campaign/getCampaigns');

    const {
      entries: feeds,
      entriesLoading: feedsLoading,
      fetchEntries: fetchFeeds,
    } = useEntriesNext<Feeds>('/api/feed/getFeeds');

    const adSecureAnalyzes = ref<AdSecureAnalyzes>(ENTRIES);

    const adSecureAnalyzesFilters = reactive({
      project_id: null,
      user_id: null,
      campaign_id: null,
      feed_id: null,
      url: null,
    });

    const fetchAdSecureAnalyzes = async (adSecureAnalyzesPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        'api/adsecure/getAnalyzesPaginator',
        {
          ...adSecureAnalyzesPaginator,
          ...adSecureAnalyzesFilters,
          ...props.filters,
        },
      );

      adSecureAnalyzes.value = response.data;
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? adSecureAnalyzesFilters.project_id : null,
    });

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds({
      project_id: user.isSuperAdmin() ? adSecureAnalyzesFilters.project_id : undefined,
    });

    const fetchCampaignsWrapper = async (): Promise<void> => fetchCampaigns({
      project_id: user.isSuperAdmin() ? adSecureAnalyzesFilters.project_id : undefined,
      user_id: adSecureAnalyzesFilters.user_id,
    });

    const onProjectChange = (): void => {
      adSecureAnalyzesFilters.user_id = null;

      fetchUsersWrapper();

      adSecureAnalyzesFilters.feed_id = null;

      fetchFeedsWrapper();

      adSecureAnalyzesFilters.campaign_id = null;

      fetchCampaignsWrapper();
    };

    const onUserChange = (): void => {
      adSecureAnalyzesFilters.campaign_id = null;

      fetchCampaignsWrapper();
    };

    if (props.showRelationsColumns) {
      onMounted((): void => {
        Promise.all([
          user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
          fetchUsersWrapper(),
          fetchCampaigns(),
          fetchFeeds(),
        ]);
      });
    }

    return {
      ADSECURE_ANALYSIS_STATUSES_MAP,
      user,
      adSecureAnalyzes,
      adSecureAnalyzesFilters,
      projects,
      projectsLoading,
      users,
      usersLoading,
      campaigns,
      campaignsLoading,
      feeds,
      feedsLoading,
      fetchAdSecureAnalyzes,
      onProjectChange,
      onUserChange,
    };
  },
});
