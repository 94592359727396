
import moment from 'moment';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  GenericObject,
  Endpoints,
  Countries,
  Platforms,
  Browsers,
  Statistics,
} from '@/types';

import {
  TRAFFIC_CATEGORIES,
  TRAFFIC_TYPES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

export default defineComponent({
  components: {
    Actions: defineAsyncComponent(() => import('@/components/Statistics/Direct/Actions.vue')),
    StatisticsByHours: defineAsyncComponent(() => import('@/components/Statistics/Direct/StatisticsByHours.vue')),
    StatisticsByEndpoints: defineAsyncComponent(() => import('@/components/Statistics/Direct/StatisticsByEndpoints.vue')),
    StatisticsBySubids: defineAsyncComponent(() => import('@/components/Statistics/Direct/StatisticsBySubids.vue')),
    StatisticsByCountries: defineAsyncComponent(() => import('@/components/Statistics/Direct/StatisticsByCountries.vue')),
    StatisticsByPlatforms: defineAsyncComponent(() => import('@/components/Statistics/Direct/StatisticsByPlatforms.vue')),
    StatisticsByBrowsers: defineAsyncComponent(() => import('@/components/Statistics/Direct/StatisticsByBrowsers.vue')),
  },
  setup() {
    const { activeUser: user } = useUser();

    const {
      entries: endpoints,
      entriesLoading: endpointsLoading,
      fetchEntries: fetchEndpoints,
    } = useEntriesNext<Endpoints>('/api/endpoint/getEndpoints');

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries');

    const {
      entries: platforms,
      entriesLoading: platformsLoading,
      fetchEntries: fetchPlatforms,
    } = useEntriesNext<Platforms>('/api/platform/getPlatforms');

    const {
      entries: browsers,
      entriesLoading: browsersLoading,
      fetchEntries: fetchBrowsers,
    } = useEntriesNext<Browsers>('/api/browser/getBrowsers');

    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/publisher/direct/getStatisticsByDays');

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const getStatisticByDayRowClass = (row: GenericObject): string => (row.date === subStatisticsComponent.filters.date ? 'statistics-table-row-active' : '');

    const defaultStatisticsFilters = {
      date_from: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      traffic_type: null,
      traffic_category: null,
      endpoint_id: null,
      country_id: null,
      platform_id: null,
      browser_id: null,
    };

    const statisticsFilters = reactive<GenericObject>({ ...defaultStatisticsFilters });

    const statisticsFiltersKeyCounter = ref<number>(0);

    const resetStatisticsFilters = (): void => {
      Object.assign(statisticsFilters, defaultStatisticsFilters);

      statisticsFiltersKeyCounter.value += 1;
    };

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints({
      site_id_is_null: false,
    });

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics(statisticsFilters);

      resetSubStatisticComponent();
    };

    onMounted(() => Promise.all([
      fetchEndpointsWrapper(),
      fetchCountries(),
      fetchPlatforms(),
      fetchBrowsers(),
    ]));

    return {
      TRAFFIC_CATEGORIES,
      TRAFFIC_TYPES,
      user,
      endpoints,
      endpointsLoading,
      countries,
      countriesLoading,
      platforms,
      platformsLoading,
      browsers,
      browsersLoading,
      statistics,
      statisticsLoading,
      statisticsFetched,
      statisticsFilters,
      statisticsFiltersKeyCounter,
      resetStatisticsFilters,
      fetchStatisticsWrapper,
      subStatisticsComponent,
      setSubStatisticsComponent,
      getStatisticByDayRowClass,
    };
  },
});
