
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  computed,
} from 'vue';

import PrimeVueMessage from 'primevue/message';

import type {
  Option,
  ErrorsMap,
  Endpoint,
} from '@/types';

import {
  ENTRIES_SORT_BY_DIRECTION_ASC,
  TRAFFIC_TYPE_POP,
  TRAFFIC_TYPE_PUSH,
  TRAFFIC_TYPES,
  TRAFFIC_TYPE_MIX,
  TRAFFIC_CATEGORY_MAINSTREAM,
  TRAFFIC_CATEGORY_ADULT,
  TRAFFIC_CATEGORIES,
  REGIONS,
  TARGETING_TYPE_DISABLED,
  TARGETING_TYPES,
  PROTOCOL_LEGACY,
  PROTOCOL_DIRECT_LINK,
  PROTOCOLS,
  BID_SUBJECT_CLICK,
  BID_SUBJECTS,
  BID_TYPE_PER_THOUSAND,
  BID_TYPES,
  ENDPOINT_MAX_QPS_0,
  ENDPOINT_MAX_QPS,
  USER_TYPE_AFFILIATE,
  ENDPOINT_STATUS_ENABLED,
  ENDPOINT_STATUSES,
  ENDPOINT_DEFAULT_SHAVE,
  ENDPOINT_DEFAULT_POP_FREQUENCY_SECONDS,
  CUSTOM_RESPONSE_PROVIDERS,
  CUSTOM_RESPONSE_PROVIDER_EXOCLICK,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  components: {
    PrimeVueMessage,
    EndpointMeta: defineAsyncComponent(() => import('@/components/EndpointMeta.vue')),
    ShaveRules: defineAsyncComponent(() => import('@/components/ShaveRules.vue')),
    HardcodedBids: defineAsyncComponent(() => import('@/components/HardcodedBids.vue')),
    TargetingByIDs: defineAsyncComponent(() => import('@/components/TargetingByIDs.vue')),
  },
  setup() {
    const { activeUser: user } = useUser();

    const { getEntryByRoute } = useEntries();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: usersLoading,
      entries: users,
      fetchEntries: fetchUsers,
    } = useEntries();

    // Добавлено для опции targeting_by_advertisers_ids
    // т.к. нужен весь список пользователей, от всех проектов, т.к. кампании рекламодателей перелинкованы между собой
    // опция доступна только супер администраторам
    const {
      entriesLoading: advertisersLoading,
      entries: advertisers,
      fetchEntries: fetchAdvertisers,
    } = useEntries();

    const {
      entriesLoading: sitesLoading,
      entries: sites,
      fetchEntries: fetchSites,
    } = useEntries();

    const {
      entriesLoading: feedsLoading,
      entries: feeds,
      fetchEntries: fetchFeeds,
    } = useEntries();

    const {
      entriesLoading: campaignsLoading,
      entries: campaigns,
      fetchEntries: fetchCampaigns,
    } = useEntries();

    const {
      entriesLoading: countriesLoading,
      entries: countries,
      fetchEntries: fetchCountries,
    } = useEntries();

    const endpoint = reactive<Endpoint>({
      site_id: null,
      custom_response_provider: null,
      bid_subject: BID_SUBJECT_CLICK,
      revshare: 60,
      shave: ENDPOINT_DEFAULT_SHAVE,
      shave_rules: [],
      min_bid_override: null,
      pay_filtered_click_chance: 50,
      hardcoded_bid: null,
      hardcoded_bids: [],
      allow_referrer_mismatch: false,
      get_ip_and_user_agent_from_headers: false,
      subids_checking_delay_to: null,
      disable_decline_non_effective_subids: false,
      allow_feeds: false,
      allow_campaigns: false,
      only_mainstream_coverage: false,
      replace_feeds_responses: false,
      show_ad_id: false,
      sensitive: false,
      hide_pseudosite_host_in_click_url: false,
      pop_frequency_seconds: ENDPOINT_DEFAULT_POP_FREQUENCY_SECONDS,
      pop_targeting_by_countries: TARGETING_TYPE_DISABLED,
      pop_countries_ids: [],
      push_delay_to_first_sending: 30, // TODO: Вынести в константы
      push_delay_to_sending: 600, // TODO: Вынести в константы
      push_max_sendings: 300, // TODO: Вынести в константы
      push_max_age: 7, // TODO: Вынести в константы
      status: ENDPOINT_STATUS_ENABLED,
      comment: null,
      targeting_by_advertisers_ids: TARGETING_TYPE_DISABLED,
      advertisers_ids: [],
      targeting_by_campaigns: TARGETING_TYPE_DISABLED,
      campaigns_ids: [],
      passback_url: null,
      passback_forbidden_traffic: false,
      max_qps: ENDPOINT_MAX_QPS_0,
      targeting_by_feeds_ids: TARGETING_TYPE_DISABLED,
      feeds_ids: [],
      click_host: null,
    } as unknown as Endpoint);

    const endpointErrorsMap = ref<ErrorsMap>({});

    // eslint-disable-next-line arrow-body-style
    const protocols = computed((): Option[] => [...PROTOCOLS].map((protocol: Option) => {
      // eslint-disable-next-line no-param-reassign
      protocol.disabled = protocol.value === PROTOCOL_DIRECT_LINK && (!endpoint.site_id || endpoint.traffic_type !== TRAFFIC_TYPE_POP);

      return protocol;
    }));

    const declinedSubids = ref<string[]>([]);

    const declinedSubidsLoading = ref<boolean>(false);

    const declinedSubidsAsStringSeparators = [
      {
        value: '\n',
        translate_key: 'new_line',
      },
      {
        value: ',',
        translate_key: 'comma',
      },
    ] as Option[];

    const declinedSubidsAsStringSeparator = ref<string>(declinedSubidsAsStringSeparators[0].value as string);

    const declinedSubidsAsString = computed<string>(() => declinedSubids.value.join(declinedSubidsAsStringSeparator.value));

    const showDeclinedSubids = ref<boolean>(false);

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers(
      '/api/user/getUsers',
      {
        project_id: user.isSuperAdmin() ? endpoint.project_id : null,
        type: USER_TYPE_AFFILIATE,
      },
    );

    const fetchSitesWrapper = async (): Promise<void> => fetchSites(
      '/api/site/getSites',
      {
        project_id: user.isSuperAdmin() ? endpoint.project_id : null,
        user_id: endpoint.user_id,
      },
    );

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds(
      '/api/feed/getFeeds',
      {
        project_id: user.isSuperAdmin() ? endpoint.project_id : null,
        // Для эндпоинта отображаем максимально доступное покрытие
        traffic_type: TRAFFIC_TYPE_MIX.get(endpoint.traffic_type),
        sort_by: 'status',
        sort_by_direction: ENTRIES_SORT_BY_DIRECTION_ASC,
      },
    );

    const fetchEndpoint = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/endpoint/getEndpointById');

      if (entry) {
        Object.assign(endpoint, entry);
      }

      fetchSitesWrapper();

      if (user.isStaff()) {
        Promise.all([
          user.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
          fetchUsersWrapper(),
          fetchCountries('/api/country/getCountries'),
          fetchFeedsWrapper(),
          fetchAdvertisers('/api/user/getUsers'),
          fetchCampaigns('/api/campaign/getCampaigns'),
        ]);
      }
    };

    const storeEndpoint = async (): Promise<void> => {
      const response = await axios.post('/api/endpoint/storeEndpoint', endpoint);

      Object.assign(endpoint, response.data);
    };

    const cloneEndpoint = (): void => {
      endpoint.id = undefined as unknown as number;
    };

    const onTrafficTypeChange = (): void => {
      endpoint.feeds_ids = [];

      fetchFeedsWrapper();

      if (endpoint.traffic_type !== TRAFFIC_TYPE_POP && endpoint.protocol === PROTOCOL_DIRECT_LINK) {
        endpoint.protocol = PROTOCOL_LEGACY;
      }
    };

    const onTrafficCategoryChange = (): void => {
      if (endpoint.traffic_category === TRAFFIC_CATEGORY_ADULT) {
        endpoint.only_mainstream_coverage = false;
      }
    };

    const onUserChange = (): void => {
      endpoint.site_id = null;

      fetchSitesWrapper();
    };

    const onProjectChange = (): void => {
      endpoint.user_id = undefined as unknown as number;

      fetchUsersWrapper();

      onUserChange();

      onTrafficTypeChange();
    };

    const onCustomResponseProviderChange = (): void => {
      if (endpoint.custom_response_provider === CUSTOM_RESPONSE_PROVIDER_EXOCLICK) {
        endpoint.bid_type = BID_TYPE_PER_THOUSAND;
      }
    };

    const getDeclinedSubids = async (): Promise<void> => {
      declinedSubidsLoading.value = true;

      const response = await axios.post(
        '/api/subid/getDeclinedSubidsValuesByEndpointId',
        {
          endpoint_id: endpoint.id,
        },
      );

      declinedSubidsLoading.value = false;

      declinedSubids.value = response.data;

      showDeclinedSubids.value = true;
    };

    return {
      TRAFFIC_CATEGORY_MAINSTREAM,
      TRAFFIC_TYPE_PUSH,
      TRAFFIC_TYPES,
      TRAFFIC_CATEGORIES,
      REGIONS,
      BID_SUBJECTS,
      BID_TYPE_PER_THOUSAND,
      BID_TYPES,
      ENDPOINT_MAX_QPS_0,
      ENDPOINT_MAX_QPS,
      TARGETING_TYPE_DISABLED,
      TARGETING_TYPES,
      PROTOCOL_LEGACY,
      PROTOCOL_DIRECT_LINK,
      ENDPOINT_STATUSES,
      CUSTOM_RESPONSE_PROVIDERS,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      advertisersLoading,
      advertisers,
      sitesLoading,
      sites,
      countriesLoading,
      countries,
      feedsLoading,
      feeds,
      campaignsLoading,
      campaigns,
      endpoint,
      endpointErrorsMap,
      protocols,
      declinedSubids,
      declinedSubidsLoading,
      declinedSubidsAsString,
      declinedSubidsAsStringSeparators,
      declinedSubidsAsStringSeparator,
      showDeclinedSubids,
      fetchEndpoint,
      storeEndpoint,
      cloneEndpoint,
      onProjectChange,
      onUserChange,
      onTrafficTypeChange,
      onTrafficCategoryChange,
      onCustomResponseProviderChange,
      getDeclinedSubids,
    };
  },
});
