import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsTitle = _resolveComponent("StatisticsTitle")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_Creative = _resolveComponent("Creative")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_ViewsColumn = _resolveComponent("ViewsColumn")!
  const _component_PayableViewsColumn = _resolveComponent("PayableViewsColumn")!
  const _component_CTRColumn = _resolveComponent("CTRColumn")!
  const _component_ClicksColumn = _resolveComponent("ClicksColumn")!
  const _component_ImpressionsColumn = _resolveComponent("ImpressionsColumn")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createVNode(_component_StatisticsTitle, {
          title: _ctx.$t('creatives'),
          filters: _ctx.filters
        }, null, 8, ["title", "filters"])
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EntriesDataTableNext, {
        class: "p-datatable-sm statistics-table text-xs",
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        "data-key": "creative_id",
        "default-sort-by": "publishers_payout",
        "row-class": _ctx.getStatisticByCreativeRowClass,
        "show-gridlines": true
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('creative'),
                sortable: "",
                field: "creative_id"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions'),
                sortable: "",
                field: "views"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('payable_views'),
                sortable: "",
                field: "payable_views"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: "CTR",
                sortable: "",
                field: "ctr_not_pop"
              }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('rtb_clicks'),
                sortable: "",
                field: "clicks"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('direct_clicks'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                sortable: "",
                field: "publishers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                sortable: "",
                field: "publishers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('advertisers_payout'),
                sortable: "",
                field: "advertisers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('advertisers_cpc'),
                sortable: "",
                field: "advertisers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('conversions_short'),
                sortable: "",
                field: "conversions"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('conversions_payout'),
                sortable: "",
                field: "conversions_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: "CTC",
                sortable: "",
                field: "advertisers_ctc"
              }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('profit'),
                sortable: "",
                field: "profit"
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Creative, {
                    creative: data.creative
                  }, null, 8, ["creative"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_PayableViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CTRColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ClicksColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: _ctx.filters.date || null, hour: _ctx.filters.hour || null, creative_id: [data.creative_id] },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ImpressionsColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: _ctx.filters.date || null, hour: _ctx.filters.hour || null, creative_id: [data.creative_id] },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$n(data.advertisers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.conversions)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.conversions_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_ctc, 'percent_with_fraction')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$n(data.profit, 'payout')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler", "row-class"])
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.subStatisticsComponent.name)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subStatisticsComponent.name), {
              key: _ctx.subStatisticsComponent.key,
              "statistics-filters": _ctx.statisticsFilters,
              filters: _ctx.subStatisticsComponent.filters,
              "hide-requests-column": _ctx.hideRequestsColumn,
              "hide-feeds-payout-column": _ctx.hideFeedsPayoutColumn,
              "show-extra-data": _ctx.showExtraData,
              "show-feeds-section": _ctx.showFeedsSection,
              "show-campaigns-section": _ctx.showCampaignsSection,
              "icon-size": _ctx.iconSize
            }, null, 8, ["statistics-filters", "filters", "hide-requests-column", "hide-feeds-payout-column", "show-extra-data", "show-feeds-section", "show-campaigns-section", "icon-size"]))
          : _createCommentVNode("", true)
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
      ]),
      _: 1
    }))
  ], 64))
}