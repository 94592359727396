import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "block",
      type: "file",
      accept: "image/*",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
    }, null, 32),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "block mt-3",
          src: _ctx.modelValue,
          style: _normalizeStyle({ 'max-width': `${_ctx.maxWidth}px`  })
        }, null, 12, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.modelValue && _ctx.showClear)
      ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
          key: 2,
          class: "mt-3 p-button-danger p-button-outlined",
          label: _ctx.$t('clear'),
          icon: "pi pi-trash",
          onClick: _ctx.clear
        }, null, 8, ["label", "onClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.$t('max_file_size')) + ": " + _toDisplayString(_ctx.maxFileSizeMegabytes) + " MB ☝️", 1),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 3,
          class: "mt-3",
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}