import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid justify-content-between align-items-center mb-4" }
const _hoisted_2 = { class: "col-12 md:col-6" }
const _hoisted_3 = { class: "col-12 text-right md:col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.entryFetched)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.entryStoreHandler)
                ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
                    key: 0,
                    label: _ctx.$t('save'),
                    icon: "pi pi-save",
                    loading: _ctx.entryStoreLoading,
                    disabled: _ctx.entryDeleteLoading,
                    onClick: _ctx.storeEntry
                  }, null, 8, ["label", "loading", "disabled", "onClick"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "buttons-col-1-append")
            ]),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "buttons-col-2-prepend"),
              (!!_ctx.entry.id && _ctx.entryDeleteHandler)
                ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
                    key: 0,
                    label: _ctx.$t('delete'),
                    icon: "pi pi-trash",
                    loading: _ctx.entryDeleteLoading,
                    class: _normalizeClass({ 'p-button-warning': !_ctx.entryDeleteConfirmation, 'p-button-danger': _ctx.entryDeleteConfirmation }),
                    onClick: _ctx.deleteEntry
                  }, null, 8, ["label", "loading", "class", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_PrimeVueSkeleton, {
            class: "mb-4",
            width: "300px",
            height: "40px"
          }),
          _createVNode(_component_PrimeVueSkeleton, {
            width: "80px",
            height: "30px"
          })
        ], 64))
  ]))
}