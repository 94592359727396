
import type { PropType } from 'vue';

import {
  defineComponent,
  reactive,
  watch,
  onMounted,
} from 'vue';

import type {
  Countries,
  HardcodedBid,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import FieldBidCPC from '@/components/FieldBidCPC.vue';

export default defineComponent({
  components: {
    FieldBidCPC,
  },
  props: {
    modelValue: {
      type: Array as PropType<HardcodedBid[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    nothingTranslateKey: {
      type: String,
      default: 'nothing',
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries');

    const hardcodedBids = reactive<HardcodedBid[]>([...props.modelValue]);

    const addHardcodedBid = (): void => {
      hardcodedBids.push({
        country_id: null,
        bid: 0,
      });
    };

    const deleteHardcodedBid = (index: number): void => {
      hardcodedBids.splice(index, 1);
    };

    watch(
      hardcodedBids,
      () => emit('update:modelValue', hardcodedBids.filter((hardcodedBid: HardcodedBid) => hardcodedBid.country_id !== null)),
    );

    onMounted((): void => {
      Promise.all([
        fetchCountries(),
      ]);
    });

    return {
      countries,
      countriesLoading,
      hardcodedBids,
      addHardcodedBid,
      deleteHardcodedBid,
    };
  },
});
