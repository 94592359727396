
import moment from 'moment';

import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import PrimeVueCalendar from 'primevue/calendar';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  components: {
    PrimeVueCalendar,
  },
  props: {
    modelValue: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    showTime: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const value = computed((): Date|null => (props.modelValue ? moment(props.modelValue).toDate() : null));

    const onUpdateValue = (date: Date|null): void => {
      let format = 'YYYY-MM-DD';

      if (props.showTime) {
        format += ' HH:mm';
      }

      emit('update:modelValue', date ? moment(date).format(format) : null);
    };

    return {
      value,
      onUpdateValue,
    };
  },
});
