
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import numeral from 'numeral';

import type {
  GenericObject,
  Option,
  Projects,
  Users,
} from '@/types';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    User: defineAsyncComponent(() => import('@/components/User.vue')),
    Chartist: defineAsyncComponent(() => import('@/components/Chartist.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entriesLoading: advertisersLoading,
      entries: advertisers,
      fetchEntries: fetchAdvertisers,
    } = useEntriesNext<Users>('/api/user/getUsers');

    const {
      entriesLoading: chartDataLoading,
      entries: chartData,
      fetchEntries: fetchChartData,
    } = useEntriesNext<GenericObject[]>('/api/statistics/staff/getStatisticsByAdvertisersActivity', []);

    const chartDataFilters = reactive<GenericObject>({
      hour_offset: 24,
      project_id: null,
      advertiser_id: null,
    });

    const showChartDataFilters = ref<boolean>(false);

    const fetchAdvertisersWrapper = async (): Promise<void> => fetchAdvertisers({
      project_id: activeUser.isSuperAdmin() ? chartDataFilters.project_id : null,
    });

    const fetchChartDataWrapper = async (): Promise<void> => fetchChartData(chartDataFilters);

    const onProjectChange = (): void => {
      chartDataFilters.advertiser_id = null;

      fetchAdvertisersWrapper();
    };

    const hourOffsetOptions: Option[] = [3, 6, 12, 24, 48, 72, 168, 336].map((value: number) => {
      const labelMap: { [key: number]: string; } = {
        48: '2 days',
        72: '3 days',
        168: '1 week',
        336: '2 weeks',
      };

      return {
        value,
        label: `${labelMap[value] || value}`,
      };
    });

    const axisYLabelInterpolationFunction = (value: number): string => numeral(value).format('$0.0[0000]');

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        fetchAdvertisersWrapper(),
        fetchChartDataWrapper(),
      ]);
    });

    return {
      activeUser,
      projectsLoading,
      projects,
      advertisersLoading,
      advertisers,
      chartDataLoading,
      chartData,
      chartDataFilters,
      showChartDataFilters,
      fetchChartDataWrapper,
      onProjectChange,
      hourOffsetOptions,
      axisYLabelInterpolationFunction,
    };
  },
});
