import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "text-indigo-400",
    href: `/sites/${_ctx.site.id}`,
    target: "_blank"
  }, _toDisplayString(_ctx.site.host), 9, _hoisted_1))
}