
import moment from 'moment';

import { defineComponent } from 'vue';

import PrimeVueTabView from 'primevue/tabview';

import PrimeVueTabPanel from 'primevue/tabpanel';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    PrimeVueTabView,
    PrimeVueTabPanel,
  },
  setup() {
    const { activeUser: user } = useUser();

    const statisticsDirectPublisher = [
      {
        title: 'Statistics by days',
        method: 'getStatisticsByDays',
      },
      {
        title: 'Statistics by endpoints',
        method: 'getStatisticsByEndpoints',
      },
      {
        title: 'Statistics by subids',
        method: 'getStatisticsBySubids',
      },
      {
        title: 'Statistics by countries',
        method: 'getStatisticsByCountries',
      },
      {
        title: 'Statistics by platforms',
        method: 'getStatisticsByPlatforms',
      },
      {
        title: 'Statistics by browsers',
        method: 'getStatisticsByBrowsers',
      },
    ];

    const statisticsRTBPublisher = [
      {
        title: 'Statistics by days',
        method: 'getStatisticsByDays',
      },
      {
        title: 'Statistics by endpoints',
        method: 'getStatisticsByEndpoints',
      },
      {
        title: 'Statistics by subids',
        method: 'getStatisticsBySubids',
      },
      {
        title: 'Statistics by countries',
        method: 'getStatisticsByCountries',
      },
      {
        title: 'Statistics by platforms',
        method: 'getStatisticsByPlatforms',
      },
      {
        title: 'Statistics by browsers',
        method: 'getStatisticsByBrowsers',
      },
    ];

    const statisticsAdvertiser = [
      {
        title: 'Statistics by days',
        method: 'getStatisticsByDays',
      },
      {
        title: 'Statistics by campaigns',
        method: 'getStatisticsByCampaigns',
      },
      {
        title: 'Statistics by zones',
        method: 'getStatisticsByZones',
      },
      {
        title: 'Statistics by subids',
        method: 'getStatisticsBySubids',
      },
      {
        title: 'Statistics by countries',
        method: 'getStatisticsByCountries',
      },
      {
        title: 'Statistics by platforms',
        method: 'getStatisticsByPlatforms',
      },
      {
        title: 'Statistics by browsers',
        method: 'getStatisticsByBrowsers',
      },
    ];

    const dateFrom = moment().subtract(5, 'days').format('YYYY-MM-DD');

    const dateTo = moment().format('YYYY-MM-DD');

    return {
      user,
      statisticsDirectPublisher,
      statisticsRTBPublisher,
      statisticsAdvertiser,
      dateFrom,
      dateTo,
    };
  },
});
