// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import NProgress from 'nprogress';

import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';

import type {
  Project,
  User,
} from '@/types';

import store from '@/store';

import {
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPE_AFFILIATE,
  PAYOUT_TYPE_PUBLISHER_DIRECT,
  PAYOUT_TYPE_REFERRER,
} from '@/libs/consts';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_dashboard" */ '../views/Dashboard.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/projects',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_projects" */ '../views/projects/Projects.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_project" */ '../views/projects/Project.vue'),
      },
    ],
  },
  {
    path: '/project',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_project" */ '../views/projects/Project.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/users',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_users" */ '../views/users/Users.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_user" */ '../views/users/User.vue'),
      },
    ],
  },
  {
    path: '/endpoints',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_endpoints" */ '../views/endpoints/Endpoints.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_endpoint" */ '../views/endpoints/Endpoint.vue'),
      },
    ],
  },
  {
    path: '/endpoints-efficiency',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_endpoints_efficiency" */ '../views/EndpointsEfficiency.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/chains-diff',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_chains_diff" */ '../views/ChainsDiff.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/direct-link',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_direct_link" */ '../views/DirectLink.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/sites',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_sites" */ '../views/sites/Sites.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_site" */ '../views/sites/Site.vue'),
      },
    ],
  },
  {
    path: '/schemes',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_schemes" */ '../views/schemes/Schemes.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_scheme" */ '../views/schemes/Scheme.vue'),
      },
    ],
  },
  {
    path: '/feeds',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_feeds" */ '../views/feeds/Feeds.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_feed" */ '../views/feeds/Feed.vue'),
      },
    ],
  },
  {
    path: '/feeds-creatives',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_feeds_creatives" */ '../views/FeedsCreatives.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/creatives',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_creatives" */ '../views/creatives/Creatives.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_creative" */ '../views/creatives/Creative.vue'),
      },
    ],
  },
  {
    path: '/campaigns',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_campaigns" */ '../views/campaigns/Campaigns.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_campaign" */ '../views/campaigns/Campaign.vue'),
      },
    ],
  },
  {
    path: '/statistics/staff',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_staff" */ '../views/statistics/StatisticsStaff.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/statistics/publisher/direct',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_publisher_direct" */ '../views/statistics/StatisticsPublisherDirect.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/statistics/publisher/rtb',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_publisher_rtb" */ '../views/statistics/StatisticsPublisherRTB.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/statistics/advertiser',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_advertiser" */ '../views/statistics/StatisticsAdvertiser.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/activity/endpoints',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_activity_endpoints" */ '../views/activity/Endpoints.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/activity/feeds',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_activity_feeds" */ '../views/activity/Feeds.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/activity/advertisers',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_activity_advertisers" */ '../views/activity/Advertisers.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/activity/campaigns',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_statistics_activity_campaigns" */ '../views/activity/Campaigns.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/adsecure',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_adsecure" */ '../views/AdSecure.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/checker',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_checker" */ '../views/Checker.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/tool/users-report',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_tool_users_report" */ '../views/tool/UsersReport.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/tools',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_tools" */ '../views/Tools.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/conflicts',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_conflicts" */ '../views/Conflicts.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/quality-presets',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_quality_presets" */ '../views/quality-presets/QualityPresets.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_quality_preset" */ '../views/quality-presets/QualityPreset.vue'),
      },
    ],
  },
  {
    path: '/pseudosites',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_pseudosites" */ '../views/pseudosites/Pseudosites.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_pseudosite" */ '../views/pseudosites/Pseudosite.vue'),
      },
    ],
  },
  {
    path: '/payouts',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_payouts" */ '../views/payouts/Payouts.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_payout" */ '../views/payouts/Payout.vue'),
      },
    ],
  },
  {
    path: '/payouts/direct',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_payouts" */ '../views/payouts/Payouts.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_payout" */ '../views/payouts/Payout.vue'),
      },
    ],
    props: {
      type: PAYOUT_TYPE_PUBLISHER_DIRECT,
    },
  },
  {
    path: '/payouts/referrer',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_payouts" */ '../views/payouts/Payouts.vue'),
      },
      {
        path: ':id',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_payout" */ '../views/payouts/Payout.vue'),
      },
    ],
    props: {
      type: PAYOUT_TYPE_REFERRER,
    },
  },
  {
    path: '/payments',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_payments" */ '../views/Payments.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/links',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "view_route_proxy" */ '../views/RouteProxy.vue'),
    meta: {
      layout: 'Dashboard',
    },
    children: [
      {
        path: '',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        component: () => import(/* webpackChunkName: "view_links" */ '../views/links/Links.vue'),
      },
    ],
  },
  {
    path: '/login',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      layout: 'Empty',
    },
  },
  {
    path: '/register',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      layout: 'Empty',
    },
  },
  {
    path: '/profile',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/support',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/api',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "api" */ '../views/Api.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/postback',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "postbacks" */ '../views/Postback.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/voluum',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "voluum" */ '../views/Voluum.vue'),
    meta: {
      layout: 'Dashboard',
    },
  },
  {
    path: '/advertising-and-refund-policy',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "advertising-and-refund-policy" */ '../views/AdvertisingPolicy.vue'),
    meta: {
      layout: 'Empty',
    },
  },
  {
    path: '/403',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "500" */ '../views/403.vue'),
    meta: {
      layout: 'Empty',
    },
  },
  {
    path: '/500',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "500" */ '../views/500.vue'),
    meta: {
      layout: 'Empty',
    },
  },
  {
    path: '/link/:slug',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "link" */ '../views/Link.vue'),
    meta: {
      layout: 'Empty',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      layout: 'Empty',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * Получение тек. проекта.
 */
router.beforeEach(async (to, from, next) => {
  try {
    const response = await axios.post('/api/project/getProject');

    const project = response.data as Project;

    store.commit('SET_PROJECT', project);
  } catch (error) {
    return next({
      path: '/500',
    });
  }

  return next();
});

/**
 * Получение тек. проекта.
 */
router.beforeEach(async (to, from, next) => {
  const title = document.getElementById('title');

  if (title) {
    title.innerText = store.getters.project.name;
  }

  return next();
});

/**
 * Получение тек. авторизованного пользователя.
 */
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return next();
  }

  try {
    const response = await axios.post(
      '/api/user/getActiveUser',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const user = response.data as User;

    user.isSuperAdmin = (): boolean => user.type === USER_TYPE_SUPER_ADMIN;

    user.isStaff = (): boolean => [USER_TYPE_SUPER_ADMIN, USER_TYPE_ADMIN, USER_TYPE_MANAGER].includes(user.type);

    user.isAffiliate = (): boolean => user.type === USER_TYPE_AFFILIATE;

    store.commit('SET_USER', user);

    axios.defaults.headers.Authorization = `Bearer ${token}`;
  } catch (error: unknown | Error | AxiosError) {
    // В случае ошибки авторизации API возвращает ответ с кодом 401
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      store.commit('UNSET_TOKEN');
    }
  }

  return next();
});

/**
 * Перенаправление на страницу авторизации.
 */
router.beforeEach((to, from, next) => {
  if (
    !store.state.user
    && to.path !== '/login'
    && to.path !== '/register'
    && to.path !== '/advertising-and-refund-policy'
    && !to.path.startsWith('/link')
  ) {
    return next({
      path: '/login',
    });
  }

  return next();
});

/**
 * Индикатор загрузки страницы.
 */
axios.interceptors.request.use((config) => {
  NProgress.start();

  return config;
});

axios.interceptors.response.use(
  (response) => {
    NProgress.done();

    return response;
  },
  async (error) => {
    NProgress.done();

    if (error?.response?.status === 401) {
      window.location.pathname = '/login';
    }

    throw error;
  },
);

export default router;
