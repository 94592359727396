import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = {
  key: 1,
  class: "text-sm white-space-pre-wrap"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_9 = { class: "p-fluid" }
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "p-fluid" }
const _hoisted_13 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueTag = _resolveComponent("PrimeVueTag")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_Entries = _resolveComponent("Entries")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Entries, {
    title: _ctx.$t('feeds'),
    "href-prefix": "feeds"
  }, {
    tables: _withCtx(() => [
      _createVNode(_component_EntriesDataTable, {
        entries: _ctx.feeds,
        "entries-handler": _ctx.fetchFeeds,
        "href-prefix": "feeds"
      }, {
        actions: _withCtx(({ data }) => [
          _createElementVNode("a", {
            href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ feed_id: [data.id] })}`,
            target: "_blank"
          }, [
            _withDirectives(_createVNode(_component_PrimeVueButton, {
              class: "p-button-rounded p-button-text",
              icon: "pi pi-chart-bar"
            }, null, 512), [
              [
                _directive_tooltip,
                _ctx.$t('statistics'),
                void 0,
                { right: true }
              ]
            ])
          ], 8, _hoisted_1)
        ]),
        columns: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, {
            field: "id",
            header: "ID",
            sortable: ""
          }),
          (_ctx.showProjectColumn && _ctx.activeUser.isSuperAdmin())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 0,
                field: "project_id",
                header: _ctx.$t('project'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.project.name), 1)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, {
            field: "name",
            header: _ctx.$t('name'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.has_violations)
                ? _withDirectives((_openBlock(), _createBlock(_component_PrimeVueTag, {
                    key: 0,
                    class: "mr-2",
                    severity: "warning",
                    value: "🚨"
                  }, null, 512)), [
                    [_directive_tooltip, _ctx.$t('has_violations')]
                  ])
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_2, _toDisplayString(data.name), 1),
              (data.comment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(data.comment), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "adsecure_check_status",
            header: "AdSecure",
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_PrimeVueTag, {
                value: _ctx.ADSECURE_CHECK_STATUSES_MAP[data.adsecure_check_status].label,
                severity: _ctx.ADSECURE_CHECK_STATUSES_MAP[data.adsecure_check_status].severity
              }, null, 8, ["value", "severity"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, {
            field: "status",
            header: _ctx.$t('status'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_PrimeVueTag, {
                value: _ctx.$t(_ctx.FEED_STATUSES_MAP[data.status].translate_key),
                severity: _ctx.FEED_STATUSES_MAP[data.status].severity
              }, null, 8, ["value", "severity"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "traffic_type",
            header: _ctx.$t('type'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.TRAFFIC_TYPES_MAP[data.traffic_type].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "traffic_category",
            header: _ctx.$t('category'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.TRAFFIC_CATEGORIES_MAP[data.traffic_category].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "region",
            header: _ctx.$t('region'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.REGIONS_MAP[data.region].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "max_revenue_per_day",
            header: _ctx.$t('max_revenue_per_day'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.max_revenue_per_day)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$n(data.max_revenue_per_day, 'payout')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "—"))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "max_qps",
            header: "QPS",
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.max_qps)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$n(data.max_qps)), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, "—"))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, {
            field: "revshare",
            header: _ctx.$t('revshare'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.revshare) + "%", 1)
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["entries", "entries-handler"])
    ]),
    filters: _withCtx(() => [
      (_ctx.activeUser.isSuperAdmin())
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.feedsFilters.project_id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feedsFilters.project_id) = $event)),
              modelModifiers: { number: true },
              label: _ctx.$t('project'),
              loading: _ctx.projectsLoading,
              "show-clear": true,
              options: _ctx.projects.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false
            }, null, 8, ["modelValue", "label", "loading", "options"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_FieldText, {
          modelValue: _ctx.feedsFilters.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feedsFilters.name) = $event)),
          modelModifiers: { trim: true },
          label: _ctx.$t('name')
        }, null, 8, ["modelValue", "label"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.feedsFilters.traffic_type,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.feedsFilters.traffic_type) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('type'),
          "show-clear": true,
          options: _ctx.TRAFFIC_TYPES
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.feedsFilters.traffic_category,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.feedsFilters.traffic_category) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('category'),
          "show-clear": true,
          options: _ctx.TRAFFIC_CATEGORIES
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.feedsFilters.region,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.feedsFilters.region) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('region'),
          "show-clear": true,
          options: _ctx.REGIONS
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_FieldDropdown, {
          modelValue: _ctx.feedsFilters.status,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.feedsFilters.status) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('status'),
          "show-clear": true,
          options: _ctx.FEED_STATUSES
        }, null, 8, ["modelValue", "label", "options"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}