
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
} from 'vue';

import type {
  ErrorsMap,
  Scheme,
} from '@/types';

import {
  SCHEME_CONTENT_TYPE_JSON,
  SCHEME_CONTENT_TYPE_XML,
  SCHEME_CONTENT_TYPES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  setup() {
    const { activeUser } = useUser();

    const { getEntryByRoute } = useEntries();

    const scheme = reactive<Scheme>({
      content_type: SCHEME_CONTENT_TYPE_JSON,
      items_in_array: false,
      items_path: null,
      data_in_attributes: false,
      title_path: null,
      description_path: null,
      bid_is_string: false,
      bid_may_be_zero: false,
      icon_url_path: null,
      image_url_path: null,
      click_url_path: null,
      nurl_path: null,
    } as unknown as Scheme);

    const schemeErrorsMap = ref<ErrorsMap>({});

    const fetchSchemes = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/scheme/getSchemeById');

      if (entry) {
        Object.assign(scheme, entry);
      }
    };

    const storeScheme = async (): Promise<void> => {
      const response = await axios.post('/api/scheme/storeScheme', scheme);

      Object.assign(scheme, response.data);
    };

    return {
      SCHEME_CONTENT_TYPE_JSON,
      SCHEME_CONTENT_TYPE_XML,
      SCHEME_CONTENT_TYPES,
      activeUser,
      scheme,
      schemeErrorsMap,
      fetchSchemes,
      storeScheme,
    };
  },
});
