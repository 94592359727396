
import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
  watch,
} from 'vue';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
      default: 'CPM',
    },
    help: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 0.1,
    },
  },
  setup(props, { emit }) {
    const cpm = ref<number>(props.modelValue ? props.modelValue * 1000 : 0);

    watch(cpm, (currentValue: number) => emit('update:modelValue', Math.round(((currentValue / 1000) + Number.EPSILON) * 1000000) / 1000000));

    return {
      cpm,
    };
  },
});
