import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "p-fluid mb-3" }
const _hoisted_3 = { class: "p-fluid mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('subid_uuid')) + " -> " + _toDisplayString(_ctx.$t('subid_id')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FieldTextarea, {
        modelValue: _ctx.uuids,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.uuids) = $event)),
        modelModifiers: { trim: true },
        name: "uuids",
        "errors-map": _ctx.errorsMap,
        label: _ctx.$t('subid_uuid'),
        disabled: _ctx.loading
      }, null, 8, ["modelValue", "errors-map", "label", "disabled"])
    ]),
    _createVNode(_component_PrimeVueButton, {
      class: "p-button-secondary w-full mb-3",
      label: _ctx.$t('convert'),
      loading: _ctx.loading,
      disabled: !_ctx.uuids,
      onClick: _ctx.convert
    }, null, 8, ["label", "loading", "disabled", "onClick"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FieldTextarea, {
        "model-value": _ctx.ids,
        label: _ctx.$t('subid_id'),
        disabled: _ctx.loading
      }, null, 8, ["model-value", "label", "disabled"])
    ])
  ]))
}