
import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
  computed,
} from 'vue';

import PrimeVueDialog from 'primevue/dialog';

import 'vue-advanced-cropper/dist/style.css';

import { Cropper } from 'vue-advanced-cropper';

import { CREATIVE_IMAGE_ASPECT_RATIO_LABEL_MAP } from '@/libs/consts';

import type {
  GenericObject,
  ErrorsMap,
} from '@/types';

export default defineComponent({
  components: {
    PrimeVueDialog,
    Cropper,
  },
  props: {
    modelValue: {
      type: Array as PropType<GenericObject[]>,
    },
    imageUrl: {
      type: Array as PropType<GenericObject[]>,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    maxWidth: {
      type: Number,
      required: true,
    },
    aspectRatios: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const dialog = ref<boolean>(false);

    const imageBase64 = ref<ArrayBuffer|string|null>(null);

    const imageBase64Cropped = ref<GenericObject[]>([]);

    const imagePreview = computed(() => (props.modelValue?.length ? props.modelValue : props.imageUrl));

    const onFileChange = (event: Event): void => {
      const { files } = event.target as HTMLInputElement;

      if (!files || !files.length) {
        return;
      }

      const file = files[0];

      const reader = new FileReader();

      reader.onloadend = (): void => {
        imageBase64.value = reader.result;

        imageBase64Cropped.value = [];
      };

      reader.readAsDataURL(file);
    };

    const onCropperChange = (aspectRatio: number, attributes: { canvas: HTMLCanvasElement }): void => {
      const index = imageBase64Cropped.value.findIndex((image) => image.aspect_ratio === aspectRatio);

      if (index === -1) {
        imageBase64Cropped.value.push({
          aspect_ratio: aspectRatio,
          base64: attributes.canvas.toDataURL(),
        });
      } else {
        imageBase64Cropped.value[index].base64 = attributes.canvas.toDataURL();
      }
    };

    const save = (): void => {
      emit('update:modelValue', imageBase64Cropped.value);

      dialog.value = false;
    };

    return {
      CREATIVE_IMAGE_ASPECT_RATIO_LABEL_MAP,
      dialog,
      imageBase64,
      imagePreview,
      onFileChange,
      onCropperChange,
      save,
    };
  },
});
