
import _keyBy from 'lodash/keyBy';

import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import PrimeVueDropdown from 'primevue/dropdown';

import PrimeVueMultiSelect from 'primevue/multiselect';

import type {
  ErrorsMap,
  Option,
  OptionsMap,
} from '@/types';

export default defineComponent({
  components: {
    PrimeVueDropdown,
    PrimeVueMultiSelect,
  },
  props: {
    modelValue: {
      type: [Number, String, Array],
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    showClear: {
      type: Boolean,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    filterPlaceholder: {
      type: String,
    },
    options: {
      type: Array as PropType<Option[]>,
      default: [] as Option[],
    },
    optionValue: {
      type: String as PropType<keyof Option>,
      default: 'value',
    },
    // По задумке, опции с переводом будут использоваться чаще, поэтому значение по умолчанию для optionLabel = translate_key, optionLabelTranslate = true
    optionLabel: {
      type: String as PropType<keyof Option>,
      default: 'translate_key',
    },
    optionLabelTranslate: {
      type: Boolean,
      default: true,
    },
    optionDisabled: {
      type: String as PropType<keyof Option>,
      default: 'disabled',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multipleSortByValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const optionsMap = computed((): OptionsMap => _keyBy(props.options, props.optionValue));

    const componentName = computed((): string => (props.multiple ? 'PrimeVueMultiSelect' : 'PrimeVueDropdown'));

    // TODO: Разбить компонент на два, избавиться от этой ебли с типами

    const sortedOptions = computed((): Option[] => {
      if (!props.multiple || !props.modelValue || !(props.modelValue as number[]).length) {
        return props.options;
      }

      return [...props.options].sort((a, b) => {
        // Сортировка опций по "активности" + по значению или в алфавитном порядке

        const aSelected = Number((props.modelValue as number[]).includes(a[props.optionValue] as number));

        const bSelected = Number((props.modelValue as number[]).includes(b[props.optionValue] as number));

        let secondarySortCondition = 0;

        if (props.multipleSortByValue) {
          secondarySortCondition = (b[props.optionValue] as number) - (a[props.optionValue] as number);
        } else {
          secondarySortCondition = (a[props.optionLabel] as string).localeCompare(b[props.optionLabel] as string);
        }

        return bSelected - aSelected || secondarySortCondition;
      });
    });

    return {
      optionsMap,
      componentName,
      sortedOptions,
    };
  },
});
