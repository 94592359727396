
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';

import PrimeVueToast from 'primevue/toast';

import { useToast } from 'primevue/usetoast';

import PrimeVueConfirmPopup from 'primevue/confirmpopup';

import { useConfirm } from 'primevue/useconfirm';

import PrimeVueTriStateCheckbox from 'primevue/tristatecheckbox';

import PrimeVueOverlayPanel from 'primevue/overlaypanel';

import type {
  EntriesPaginator,
  Projects,
  Users,
  Campaigns,
  GenericObject,
} from '@/types';

import {
  TRAFFIC_TYPE_POP,
  TRAFFIC_TYPES,
  TRAFFIC_TYPES_MAP,
  TRAFFIC_CATEGORIES,
  TRAFFIC_CATEGORIES_MAP,
  ENTRIES,
  USER_TYPE_AFFILIATE,
  CAMPAIGN_PAYMENT_TYPE_CPC,
  CAMPAIGN_PAYMENT_TYPE_CPA,
  CAMPAIGN_PAYMENT_TYPES,
  CAMPAIGN_PAYMENT_TYPES_MAP,
  CAMPAIGN_TOO_HIGH_BID_POP,
  CAMPAIGN_APPROVAL_STATUSES,
  CAMPAIGN_APPROVAL_STATUSES_MAP,
  CAMPAIGN_STATUSES,
  CAMPAIGN_STATUSES_MAP,
  ADSECURE_CHECK_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    PrimeVueToast,
    PrimeVueConfirmPopup,
    PrimeVueOverlayPanel,
    PrimeVueTriStateCheckbox,
    CampaignApprovalStatus: defineAsyncComponent(() => import('@/components/CampaignApprovalStatus.vue')),
    CampaignStatus: defineAsyncComponent(() => import('@/components/CampaignStatus.vue')),
    CampaignArchivedAt: defineAsyncComponent(() => import('@/components/CampaignArchivedAt.vue')),
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    showProjectColumn: {
      type: Boolean,
      default: true,
    },
    showUserColumn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const i18n = useI18n();

    const toast = useToast();

    const confirm = useConfirm();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const { encodeStatisticsFilters } = useStatisticsLink();

    const campaigns = reactive<Campaigns>(ENTRIES);

    const campaignOverlayPanel = ref();

    const campaignData = reactive<GenericObject>({
      id: null,
      bid: null,
      trafficType: null,
      loading: false,
    });

    const campaignsFilters = reactive({
      project_id: null,
      user_id: null,
      name: null,
      traffic_type: null,
      traffic_category: null,
      payment_type: null,
      approval_status: null,
      status: null,
      archived: false,
    });

    const fetchCampaigns = async (campaignsPaginator?: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/campaign/getCampaignsPaginator',
        {
          ...(campaignsPaginator || {}),
          ...campaignsFilters,
          ...props.filters,
        },
      );

      Object.assign(campaigns, response.data);
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? campaignsFilters.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const onProjectChange = (): void => {
      campaignsFilters.user_id = null;

      fetchUsersWrapper();
    };

    const showArchived = (archived: boolean): void => {
      campaignsFilters.archived = archived;

      fetchCampaigns();
    };

    const cloneCampaign = async (campaignId: number): Promise<void> => {
      try {
        const response = await axios.post('/api/campaign/cloneCampaign', {
          id: campaignId,
        });

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_clone_campaign'),
          life: 5000,
        });

        await router.push(`/campaigns/${response.data.id}`);
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }
    };

    const confirmCloneCampaign = (event: PointerEvent, campaignId: number): void => {
      confirm.require({
        target: event.currentTarget as HTMLElement,
        message: 'Are you sure you want to clone this campaign?',
        icon: 'pi pi-question-circle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        acceptLabel: 'Clone',
        rejectLabel: 'Cancel',
        rejectClass: 'p-button-outlined p-button-sm',
        acceptClass: 'p-button-sm',
        accept: () => {
          cloneCampaign(campaignId);
        },
      });
    };

    const toggleCampaignOverlayPanel = (event: PointerEvent, campaignId: number, campaignBid: number, campaignTrafficType: number): void => {
      campaignData.id = campaignId;

      campaignData.bid = campaignBid;

      campaignData.trafficType = campaignTrafficType;

      campaignOverlayPanel.value.toggle(event);
    };

    const updateBid = async (event: PointerEvent): Promise<void> => {
      campaignData.loading = true;

      try {
        await axios.post('/api/campaign/updateBid', {
          campaigns_ids: [campaignData.id],
          bid: campaignData.bid,
        });

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_bid_update'),
          life: 5000,
        });

        campaignOverlayPanel.value.toggle(event);

        const updatedCampaignIndex = campaigns.data.findIndex((campaign) => campaign.id === campaignData.id);

        campaigns.data[updatedCampaignIndex].bid = campaignData.bid;
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      campaignData.loading = false;
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isAffiliate() ? Promise.resolve() : fetchUsersWrapper(),
      ]);
    });

    // TODO: Вынести в Vue 3 фильтры, чтобы использовать везде
    const truncateText = (text: string, length: number): string => {
      if (text.length < length) {
        return text;
      }

      return `${text.substring(1, length)}...`;
    };

    return {
      TRAFFIC_TYPE_POP,
      TRAFFIC_TYPES,
      TRAFFIC_TYPES_MAP,
      TRAFFIC_CATEGORIES,
      TRAFFIC_CATEGORIES_MAP,
      CAMPAIGN_PAYMENT_TYPE_CPC,
      CAMPAIGN_PAYMENT_TYPE_CPA,
      CAMPAIGN_PAYMENT_TYPES,
      CAMPAIGN_PAYMENT_TYPES_MAP,
      CAMPAIGN_TOO_HIGH_BID_POP,
      CAMPAIGN_APPROVAL_STATUSES,
      CAMPAIGN_APPROVAL_STATUSES_MAP,
      CAMPAIGN_STATUSES,
      CAMPAIGN_STATUSES_MAP,
      ADSECURE_CHECK_STATUSES_MAP,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      encodeStatisticsFilters,
      campaigns,
      campaignData,
      campaignOverlayPanel,
      campaignsFilters,
      fetchCampaigns,
      onProjectChange,
      showArchived,
      confirmCloneCampaign,
      toggleCampaignOverlayPanel,
      updateBid,
      truncateText,
    };
  },
});
