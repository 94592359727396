
import numeral from 'numeral';

import {
  PropType,
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import {
  GenericObject,
  Projects,
  Users,
  Feeds,
  Statistics,
} from '@/types';

import { PRESELECTED_PROJECTS_IDS } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Chartist: defineAsyncComponent(() => import('@/components/Chartist.vue')),
  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    showPublisherDropdown: {
      type: Boolean,
      default: false,
    },
    showAdvertiserDropdown: {
      type: Boolean,
      default: false,
    },
    showFeedDropdown: {
      type: Boolean,
      default: false,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    preselectedProjectsIds: {
      type: Array as PropType<number[]>,
      default: () => ([]),
    },
  },
  setup(props) {
    const { activeUser: user } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entriesLoading: usersLoading,
      entries: users,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers');

    const {
      entriesLoading: feedsLoading,
      entries: feeds,
      fetchEntries: fetchFeeds,
    } = useEntriesNext<Feeds>('/api/feed/getFeeds');

    const {
      entriesFetched: statisticsFetched,
      entries: statistics,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>(props.apiUrl);

    const preselectedProjectsIds = props.preselectedProjectsIds.length ? [...props.preselectedProjectsIds] : PRESELECTED_PROJECTS_IDS;

    const statisticsFilters = reactive<GenericObject>({
      project_id: user.isSuperAdmin() ? preselectedProjectsIds : null,
      publisher_id: null,
      advertiser_id: null,
      feed_id: null,
    });

    const statisticsFiltersKeyCounter = ref<number>(0);

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : null,
    });

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : null,
    });

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics(statisticsFilters);

      statisticsFiltersKeyCounter.value += 1;
    };

    const onProjectChange = async (): Promise<void> => {
      statisticsFilters.publisher_id = null;

      statisticsFilters.advertiser_id = null;

      statisticsFilters.feed_id = null;

      if (props.showPublisherDropdown || props.showAdvertiserDropdown) {
        fetchUsersWrapper();
      }

      if (props.showFeedDropdown) {
        fetchFeedsWrapper();
      }

      fetchStatisticsWrapper();
    };

    const onFilterDropdownChange = async (): Promise<void> => fetchStatisticsWrapper();

    const axisYLabelInterpolationFunction = (value: number): string => numeral(value).format('$0.0[0000]');

    onMounted(() => Promise.all([
      user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
      (props.showPublisherDropdown || props.showAdvertiserDropdown) ? fetchUsers() : Promise.resolve(),
      props.showFeedDropdown ? fetchFeeds() : Promise.resolve(),
      fetchStatisticsWrapper(),
    ]));

    return {
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      feedsLoading,
      feeds,
      statisticsFetched,
      statistics,
      statisticsFilters,
      statisticsFiltersKeyCounter,
      onProjectChange,
      onFilterDropdownChange,
      axisYLabelInterpolationFunction,
    };
  },
});
