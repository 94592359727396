
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import type { Feed } from '@/types';

import {
  FEED_STATUS_DISABLED,
  FEED_STATUSES_MAP,
} from '@/libs/consts';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  props: {
    feed: {
      type: Object as PropType<Feed>,
      required: true,
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
  setup() {
    const { encodeStatisticsFilters } = useStatisticsLink();

    return {
      FEED_STATUS_DISABLED,
      FEED_STATUSES_MAP,
      encodeStatisticsFilters,
    };
  },
});
