
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    DecodePayload: defineAsyncComponent(() => import('@/components/Tools/DecodePayload.vue')),
    SubidsIDsToSubidsUUIDs: defineAsyncComponent(() => import('@/components/Tools/SubidsIDsToSubidsUUIDs.vue')),
    SubidsUUIDsToSubidsIDs: defineAsyncComponent(() => import('@/components/Tools/SubidsUUIDsToSubidsIDs.vue')),
    UsersEmailsToUsersIDs: defineAsyncComponent(() => import('@/components/Tools/UsersEmailsToUsersIDs.vue')),
  },
  setup() {
    const router = useRouter();

    const { activeUser } = useUser();

    // TODO: Вынести подобные проверки в router.ts
    onMounted(async (): Promise<void> => {
      if (activeUser.isAffiliate()) {
        await router.push('/403');
      }
    });
  },
});
