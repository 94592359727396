
import { defineComponent } from 'vue';

import useProject from '@/composable/useProject';

export default defineComponent({
  setup() {
    const { activeProject: project } = useProject();

    return {
      project,
    };
  },
});
