
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { Browser } from '@/types';

export default defineComponent({
  props: {
    browser: {
      type: Object as PropType<Browser>,
    },
  },
});
