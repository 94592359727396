
import type { PropType } from 'vue';

import {
  defineComponent,
  onMounted,
} from 'vue';

import type { Project } from '@/types';

export default defineComponent({
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      if (props.project.analytics_yandex_id) {
        const script = document.createElement('script');

        script.type = 'text/javascript';

        script.src = '/assets/js/analytics-yandex.js';

        script.setAttribute('data-id', props.project.analytics_yandex_id);

        document.head.appendChild(script);
      }

      if (props.project.analytics_google_id) {
        const script = document.createElement('script');

        script.type = 'text/javascript';

        script.src = '/assets/js/analytics-google.js';

        script.setAttribute('data-id', props.project.analytics_google_id);

        document.head.appendChild(script);
      }
    });
  },
});
