
import numeral from 'numeral';

import {
  defineComponent,
  defineAsyncComponent,
  computed,
  onMounted,
} from 'vue';

import { GenericObject } from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Chartist: defineAsyncComponent(() => import('@/components/Chartist.vue')),
  },
  setup() {
    const {
      entriesFetched: statisticsFetched,
      entries: statistics,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<GenericObject[]>('/api/widget/getStatisticsByPayments', []);

    const labels = computed((): string[] => statistics.map((value: GenericObject) => value.date));

    const axisYLabelInterpolationFunction = (value: number): string => numeral(value).format('$0');

    onMounted(() => fetchStatistics());

    return {
      statisticsFetched,
      statistics,
      labels,
      axisYLabelInterpolationFunction,
    };
  },
});
