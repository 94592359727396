
import axios from 'axios';

import {
  defineComponent,
  onMounted,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import PrimeVueMessage from 'primevue/message';

import { ENTRIES } from '@/libs/consts';

import useEntriesNext from '@/composable/useEntries@next';

import type { Conflicts } from '@/types';

export default defineComponent({
  components: {
    PrimeVueMessage,
  },
  setup() {
    const i18n = useI18n();

    const toast = useToast();

    const {
      entries: conflicts,
      entriesLoading: conflictsLoading,
      entriesFetched: conflictsFetched,
      fetchEntries: fetchConflicts,
    } = useEntriesNext<Conflicts>('/api/conflict/getConflicts ', ENTRIES);

    const addEndpointToFeedWhitelist = async (endpointId: number, feedId: number, index: number): Promise<void> => {
      if (conflicts.data[index].loadingDelete) {
        return;
      }

      conflicts.data[index].loadingAdd = true;

      try {
        await axios.post(
          'api/conflict/addEndpointToFeedWhitelist',
          {
            endpoint_id: endpointId,
            feed_id: feedId,
          },
        );

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      conflicts.data[index].loadingAdd = false;

      conflicts.data[index].processed = true;
    };

    const deleteFeedFromEndpointWhitelist = async (feedId: number, endpointId: number, index: number): Promise<void> => {
      if (conflicts.data[index].loadingAdd) {
        return;
      }

      conflicts.data[index].loadingDelete = true;

      try {
        await axios.post(
          '/api/conflict/deleteFeedFromEndpointWhitelist',
          {
            feed_id: feedId,
            endpoint_id: endpointId,
          },
        );

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      conflicts.data[index].loadingDelete = false;

      conflicts.data[index].processed = true;
    };

    onMounted((): void => {
      Promise.all([
        fetchConflicts(),
      ]);
    });

    return {
      conflicts,
      conflictsLoading,
      conflictsFetched,
      fetchConflicts,
      addEndpointToFeedWhitelist,
      deleteFeedFromEndpointWhitelist,
    };
  },
});
