
import axios from 'axios';

import {
  defineComponent,
  ref,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import {
  CAMPAIGN_APPROVAL_STATUS_PENDING_APPROVAL,
  CAMPAIGN_APPROVAL_STATUS_APPROVED,
  CAMPAIGN_APPROVAL_STATUS_DECLINED,
} from '@/libs/consts';

export default defineComponent({
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
    campaignApprovalStatus: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const toast = useToast();

    const changeApprovalStatusLoading = ref<boolean>(false);

    const changeApprovalStatus = async (approvalStatus: number): Promise<void> => {
      if (changeApprovalStatusLoading.value) {
        return;
      }

      changeApprovalStatusLoading.value = true;

      try {
        await axios.post(
          '/api/campaign/changeApprovalStatusById',
          {
            id: props.campaignId,
            approval_status: approvalStatus,
          },
        );

        emit('update:campaignApprovalStatus', approvalStatus);

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_campaign_approval_status_change'),
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      changeApprovalStatusLoading.value = false;
    };

    return {
      CAMPAIGN_APPROVAL_STATUS_PENDING_APPROVAL,
      CAMPAIGN_APPROVAL_STATUS_APPROVED,
      CAMPAIGN_APPROVAL_STATUS_DECLINED,
      changeApprovalStatusLoading,
      changeApprovalStatus,
    };
  },
});
