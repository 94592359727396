
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  ErrorsMap,
  Projects,
  Users,
  Site,
} from '@/types';

import {
  ENTRIES,
  TRAFFIC_CATEGORY_ADULT,
  TRAFFIC_CATEGORIES,
  USER_TYPE_AFFILIATE,
  SITE_APPROVAL_STATUS_APPROVED,
  SITE_APPROVAL_STATUSES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  setup() {
    const { activeUser: user } = useUser();

    const { getEntryByRoute } = useEntries();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const site = reactive<Site>({
      traffic_category: TRAFFIC_CATEGORY_ADULT,
      approval_status: SITE_APPROVAL_STATUS_APPROVED,
      comment: null,
    });

    const siteErrorsMap = ref<ErrorsMap>({});

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? site.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const fetchSite = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/site/getSiteById');

      if (entry) {
        Object.assign(site, entry);
      }
    };

    const storeSite = async (): Promise<void> => {
      const response = await axios.post('/api/site/storeSite', site);

      Object.assign(site, response.data);
    };

    const onProjectChange = (): void => {
      site.user_id = undefined;

      fetchUsersWrapper();
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isStaff() ? fetchUsersWrapper() : Promise.resolve(),
      ]);
    });

    return {
      TRAFFIC_CATEGORIES,
      SITE_APPROVAL_STATUSES,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      site,
      siteErrorsMap,
      fetchSite,
      storeSite,
      onProjectChange,
    };
  },
});
