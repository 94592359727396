
import numeral from 'numeral';

import {
  defineComponent,
  defineAsyncComponent,
  computed,
  onMounted,
} from 'vue';

import {
  GenericObject,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Chartist: defineAsyncComponent(() => import('@/components/Chartist.vue')),
  },
  setup() {
    const {
      entriesFetched: statisticsFetched,
      entries: statistics,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/advertiser/getStatisticsByDays');

    const statisticsData = computed((): GenericObject[] => (statisticsFetched ? [...statistics.data].reverse() : []));

    const labels = computed((): string[] => statisticsData.value.map((value: GenericObject) => value.date));

    const axisYLabelInterpolationFunction = (value: number): string => numeral(value).format('$0.0[0000]');

    onMounted(() => fetchStatistics());

    return {
      statisticsFetched,
      statisticsData,
      labels,
      axisYLabelInterpolationFunction,
    };
  },
});
