import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-indigo-400"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.referrer)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.referrer.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.referrer.value), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('empty')), 1))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('unknown')), 1))
  ]))
}