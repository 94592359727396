
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import type { User } from '@/types';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const user = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      user,
    };
  },
});
