
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import type { GenericObject } from '@/types';

import {
  BIDDER_FEED,
  BIDDER_ADVERTISER,
} from '@/libs/consts';

export default defineComponent({
  props: {
    handler: {
      type: Function as PropType<(name: string, key: string, filters: GenericObject) => void>,
      required: true,
    },
    subStatisticsComponentKey: {
      type: String,
      required: true,
    },
    statisticsFilters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      default: () => ({}),
    },
  },
  setup() {
    return {
      BIDDER_FEED,
      BIDDER_ADVERTISER,
    };
  },
});
