
import { defineComponent } from 'vue';

import { v4 as uuidv4 } from 'uuid';

import PrimeVueCheckbox from 'primevue/checkbox';

export default defineComponent({
  components: {
    PrimeVueCheckbox,
  },
  props: {
    modelValue: {
      type: Boolean,
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      uuid: uuidv4(),
    };
  },
});
