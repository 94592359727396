import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm white-space-pre-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueTag = _resolveComponent("PrimeVueTag")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_Entries = _resolveComponent("Entries")!

  return (_openBlock(), _createBlock(_component_Entries, {
    title: _ctx.$t('projects'),
    "show-filters-button": false,
    "href-prefix": "projects"
  }, {
    tables: _withCtx(() => [
      _createVNode(_component_EntriesDataTable, {
        entries: _ctx.projects,
        "entries-handler": _ctx.fetchProjects,
        "href-prefix": "projects"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, {
            field: "id",
            header: "ID",
            sortable: ""
          }),
          _createVNode(_component_PrimeVueColumn, {
            field: "name",
            header: _ctx.$t('name'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(data.name), 1),
              (data.comment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(data.comment), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "status",
            header: _ctx.$t('status'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_PrimeVueTag, {
                value: _ctx.$t(_ctx.PROJECT_STATUSES_MAP[data.status].translate_key),
                severity: _ctx.PROJECT_STATUSES_MAP[data.status].severity
              }, null, 8, ["value", "severity"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "updated_at",
            header: _ctx.$t('updated_at'),
            sortable: ""
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["entries", "entries-handler"])
    ]),
    _: 1
  }, 8, ["title"]))
}