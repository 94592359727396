import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PrimeVueDataTable = _resolveComponent("PrimeVueDataTable")!

  return (_openBlock(), _createBlock(_component_PrimeVueDataTable, _mergeProps({
    value: _ctx.entries.data,
    lazy: _ctx.lazy,
    paginator: _ctx.paginator,
    "paginator-template": "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    "current-page-report-template": _ctx.$t('current_page_report_template').replaceAll('[', '{').replaceAll(']', '}'),
    "data-key": "id",
    rows: _ctx.ENTRIES_PER_PAGE,
    "expanded-rows": _ctx.expandedRows,
    "total-records": _ctx.entries.meta.total,
    loading: _ctx.entriesLoading,
    "responsive-layout": "scroll",
    "row-class": _ctx.rowClass,
    "striped-rows": "",
    "scroll-height": _ctx.scrollHeight,
    "sort-field": _ctx.defaultSortBy,
    "sort-order": _ctx.defaultSortByDirection === _ctx.ENTRIES_SORT_BY_DIRECTION_DESC ? -1 : 1,
    class: { 'p-datatable-thead-sticky': !!_ctx.scrollHeight },
    "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:expandedRows', $event)))
  }, _toHandlers(_ctx.entriesPaginatorEvents)), {
    expansion: _withCtx((slotProps) => [
      _renderSlot(_ctx.$slots, "columns-expansion", {
        data: slotProps.data
      })
    ]),
    default: _withCtx(() => [
      (_ctx.expandedRows)
        ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
            key: 0,
            expander: true,
            "header-style": "width: 3rem;"
          }))
        : _createCommentVNode("", true),
      (_ctx.showActionsColumn)
        ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
            key: 1,
            header: _ctx.$t('actions')
          }, {
            body: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, "actions", {
                data: slotProps.data,
                index: slotProps.index
              }),
              (_ctx.showEditButton)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: `/${_ctx.hrefPrefix}/${slotProps.data.id}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PrimeVueButton, {
                        class: "p-button-rounded p-button-text",
                        icon: "pi pi-pencil"
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["header"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "columns")
    ]),
    _: 3
  }, 16, ["value", "lazy", "paginator", "current-page-report-template", "rows", "expanded-rows", "total-records", "loading", "row-class", "scroll-height", "sort-field", "sort-order", "class"]))
}