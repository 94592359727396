import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-4" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = {
  key: 1,
  class: "card"
}
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = { class: "col-12 lg:col-4" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = {
  key: 0,
  class: "col-12 lg:col-4"
}
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_Entry = _resolveComponent("Entry")!

  return (_openBlock(), _createBlock(_component_Entry, {
    "entry-errors-map": _ctx.qualityPresetErrorsMap,
    "onUpdate:entry-errors-map": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.qualityPresetErrorsMap) = $event)),
    title: _ctx.$t('quality_preset'),
    entry: _ctx.qualityPreset,
    "entry-fetch-handler": _ctx.fetchQualityPreset,
    "entry-store-handler": _ctx.storeQualityPreset
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.qualityPreset.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.qualityPreset.id,
                    label: "ID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeUser.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_FieldDropdown, {
                    modelValue: _ctx.qualityPreset.project_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.qualityPreset.project_id) = $event)),
                    modelModifiers: { number: true },
                    name: "project_id",
                    "errors-map": _ctx.qualityPresetErrorsMap,
                    label: _ctx.$t('project'),
                    loading: _ctx.projectsLoading,
                    disabled: !!_ctx.qualityPreset.id,
                    options: _ctx.projects.data,
                    "option-value": "id",
                    "option-label": "name",
                    "option-label-translate": false
                  }, null, 8, ["modelValue", "errors-map", "label", "loading", "disabled", "options"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.qualityPreset.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.qualityPreset.name) = $event)),
                modelModifiers: { trim: true },
                name: "name",
                "errors-map": _ctx.qualityPresetErrorsMap,
                label: _ctx.$t('name')
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h5", null, "🕵️ " + _toDisplayString(_ctx.$t('filters_and_metrics')), 1),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.QUALITY_PRESET_COMPARATORS, (attribute) => {
                return (_openBlock(), _createBlock(_component_FieldNumber, {
                  key: attribute,
                  modelValue: _ctx.qualityPreset[attribute],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.qualityPreset[attribute]) = $event),
                  modelModifiers: { number: true },
                  name: attribute,
                  "errors-map": _ctx.qualityPresetErrorsMap,
                  label: _ctx.$t(attribute),
                  min: 0,
                  max: 100,
                  step: 5,
                  "show-buttons": "",
                  "button-layout": "horizontal",
                  "increment-button-icon": "pi pi-plus",
                  "decrement-button-icon": "pi pi-minus",
                  suffix: " %"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "errors-map", "label"]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.qualityPreset.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.qualityPreset.created_at,
                    label: _ctx.$t('created_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"]),
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.qualityPreset.updated_at,
                    label: _ctx.$t('updated_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["entry-errors-map", "title", "entry", "entry-fetch-handler", "entry-store-handler"]))
}