
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  ErrorsMap,
  User,
  Payout,
} from '@/types';

import { PAYOUT_TYPES } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useClipboard from '@/composable/useClipboard';

export default defineComponent({
  components: {
    PublisherPayoutInformation: defineAsyncComponent(() => import('@/components/PublisherPayoutInformation.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const { getEntryByRoute } = useEntries();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: usersLoading,
      entries: users,
      fetchEntries: fetchUsers,
    } = useEntries();

    const { copyToClipboard } = useClipboard();

    const payout = reactive<Payout>({
      extra_amount: null,
      extra_amount_comment: null,
      payout_method: null,
      payout_method_crypto_wallet_address: null,
      payout_method_payoneer_email: null,
      payout_method_paypal_email: null,
      payout_method_wire: null,
      payout_method_webmoney_wallet: null,
      comment: null,
      paid_at: null,
    } as Payout);

    // Для сверки плат. информации
    const payoutUser = ref<User|null>(null);

    const payoutErrorsMap = ref<ErrorsMap>({});

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers(
      '/api/user/getUsers',
      {
        project_id: activeUser.isSuperAdmin() ? payout.project_id : undefined,
      },
    );

    const onUserChange = async (): Promise<void> => {
      const response = await axios.post('/api/user/getUserById', { id: payout.user_id });

      payoutUser.value = response.data;
    };

    const fetchPayout = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/payout/getPayoutById');

      if (entry) {
        Object.assign(payout, entry);
      }

      if (!activeUser.isAffiliate()) {
        Promise.all([
          onUserChange(),
          fetchUsersWrapper(),
        ]);
      }
    };

    const storePayout = async (): Promise<void> => {
      const response = await axios.post('/api/payout/storePayout', payout);

      Object.assign(payout, response.data);
    };

    const onProjectChange = (): void => {
      payout.user_id = undefined;

      fetchUsersWrapper();
    };

    onMounted((): void => {
      Promise.all([
        fetchProjects('/api/project/getProjects'),
        fetchUsersWrapper(),
      ]);
    });

    return {
      PAYOUT_TYPES,
      activeUser,
      projects,
      projectsLoading,
      users,
      usersLoading,
      copyToClipboard,
      payout,
      payoutUser,
      payoutErrorsMap,
      fetchPayout,
      storePayout,
      onProjectChange,
      onUserChange,
    };
  },
});
