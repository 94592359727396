
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ids = computed({
      get: () => props.modelValue.join('\n'),
      set: (value: string) => {
        emit(
          'update:modelValue',
          value
            .split('\n')
            .map((id: string) => parseInt(id, 10))
            .filter((id: number) => !!id),
        );
      },
    });

    return {
      ids,
    };
  },
});
