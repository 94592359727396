import type { FeedApiProvider } from '@/types';

import _keyBy from 'lodash/keyBy';

export const FEED_API_PROVIDER_EXOCLICK = 1;

export const FEED_API_PROVIDER_ADSTERRA = 3;

export const FEED_API_PROVIDER_ADKERNEL = 4;

export const FEED_API_PROVIDER_HILLTOPADS = 7;

export const FEED_API_PROVIDER_DAOAD = 8;

export const FEED_API_PROVIDER_KADAM = 9;

export const FEED_API_PROVIDER_CLICKADU = 10;

export const FEED_API_PROVIDER_APPSHAKE = 11;

export const FEED_API_PROVIDER_PLATFORMIO = 12;

export const FEED_API_PROVIDERS = [
  {
    value: FEED_API_PROVIDER_EXOCLICK,
    label: 'ExoClick',
    fields: [
      {
        name: 'api_token',
        label: 'API token',
        component: 'FieldText',
      },
      {
        name: 'zone_id',
        label: 'Zone ID',
        component: 'FieldText',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_ADSTERRA,
    label: 'Adsterra',
    fields: [
      {
        name: 'api_token',
        label: 'API token',
        component: 'FieldText',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_ADKERNEL,
    label: 'AdKernel',
    fields: [
      {
        name: 'login',
        label: 'Login',
        component: 'FieldText',
      },
      {
        name: 'password',
        label: 'Password',
        component: 'FieldText',
        help_translate_key: 'password_will_be_encrypted',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_HILLTOPADS,
    label: 'HilltopAds',
    fields: [
      {
        name: 'key',
        label: 'Key',
        component: 'FieldText',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_DAOAD,
    label: 'DaoAd',
    fields: [],
  },
  {
    value: FEED_API_PROVIDER_KADAM,
    label: 'Kadam',
    fields: [
      {
        name: 'secret',
        label: 'Secret',
        component: 'FieldText',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_CLICKADU,
    label: 'Clickadu',
    fields: [
      {
        name: 'token',
        label: 'Token',
        component: 'FieldText',
      },
      {
        name: 'zone_id',
        label: 'Zone ID',
        component: 'FieldText',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_APPSHAKE,
    label: 'AppShake',
    fields: [
      {
        name: 'auth',
        label: 'Auth',
        component: 'FieldText',
      },
    ],
  },
  {
    value: FEED_API_PROVIDER_PLATFORMIO,
    label: 'Platform.IO',
    fields: [
      {
        name: 'api_key',
        label: 'API key',
        component: 'FieldText',
      },
      {
        name: 'ssp_id',
        label: 'SSP ID',
        component: 'FieldText',
      },
    ],
  },
] as FeedApiProvider[];

export const FEED_API_PROVIDERS_MAP = _keyBy(FEED_API_PROVIDERS, 'value');
