
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onBeforeUnmount,
} from 'vue';

import { useStore } from 'vuex';

import { useRoute } from 'vue-router';

import type {
  ErrorsMap,
  Project,
} from '@/types';

import {
  THEMES_COLORS,
  PROJECT_STATUSES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  components: {
    Macros: defineAsyncComponent(() => import('@/components/Macros.vue')),
  },
  setup() {
    const store = useStore();

    const route = useRoute();

    const { activeUser: user } = useUser();

    const { getEntryByRoute } = useEntries();

    const isSelfProject = route.path === '/project';

    const project = reactive<Project>({
      dev_host: null,
      passback_url_mainstream: null,
      passback_url_adult: null,
      comment: null,
    } as unknown as Project);

    const projectErrorsMap = ref<ErrorsMap>({});

    const previewOnThemeColorChange = ref<boolean>(true);

    const fetchProject = async (): Promise<void> => {
      let entry;

      if (isSelfProject) {
        const response = await axios.post('/api/project/getProject');

        entry = response.data;
      } else {
        entry = await getEntryByRoute('/api/project/getProjectById');
      }

      if (entry) {
        Object.assign(project, entry);
      }
    };

    const storeProject = async (): Promise<void> => {
      const response = await axios.post('/api/project/storeProject', project);

      Object.assign(project, response.data);
    };

    const onThemeColorChange = (): void => {
      if (previewOnThemeColorChange.value) {
        store.commit('SET_THEME_COLOR', project.theme_color);
      }
    };

    onBeforeUnmount((): void => store.commit('SET_THEME_COLOR', null));

    return {
      THEMES_COLORS,
      PROJECT_STATUSES,
      user,
      project,
      projectErrorsMap,
      previewOnThemeColorChange,
      fetchProject,
      storeProject,
      onThemeColorChange,
    };
  },
});
