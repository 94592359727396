// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import { ref } from 'vue';

import type { NavigationFailure } from 'vue-router';

import {
  useRouter,
  useRoute,
} from 'vue-router';

import type {
  Entry,
  Entries,
} from '@/types';

import { ENTRIES } from '@/libs/consts';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default () => {
  const router = useRouter();

  const route = useRoute();

  const entriesLoading = ref<boolean>(false);

  const entriesFetched = ref<boolean>(false);

  const entries = ref<Entries>(ENTRIES);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchEntries = async (url: string, data?: any): Promise<void> => {
    entriesLoading.value = true;

    // TODO: Обработка ошибки из ответа API
    const response = await axios.post(url, data);

    entries.value = response.data as Entries;

    entriesLoading.value = false;

    entriesFetched.value = true;
  };

  const getEntryById = async (url: string, id: number): Promise<Entry | NavigationFailure | null | void> => {
    try {
      const response = await axios.post(url, { id });

      return response.data as Entry;
    } catch (error: unknown | AxiosError) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          return router.push('/403');
        }

        if (error.response?.status === 422) {
          return router.push('/404');
        }

        return router.push('/500');
      }

      return router.push('/500');
    }
  };

  const getEntryByRoute = async (url: string): Promise<Entry | NavigationFailure | null | void> => {
    const { id } = route.params;

    if (id === 'add') {
      return null;
    }

    return getEntryById(url, id as unknown as number);
  };

  return {
    entriesLoading,
    entriesFetched,
    entries,
    fetchEntries,
    getEntryById,
    getEntryByRoute,
  };
};
