
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { Creative } from '@/types';

import useUser from '@/composable/useUser';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  props: {
    creative: {
      type: Object as PropType<Creative>,
      required: true,
    },
  },
  setup() {
    const { activeUser: user } = useUser();

    const { encodeStatisticsFilters } = useStatisticsLink();

    return {
      user,
      encodeStatisticsFilters,
    };
  },
});
