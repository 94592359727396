import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card operations" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "grid align-items-center" }
const _hoisted_5 = { class: "col-1 mt-3" }
const _hoisted_6 = { class: "col-10 pb-0" }
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "col-6 pb-0" }
const _hoisted_9 = { class: "p-fluid" }
const _hoisted_10 = { class: "col-6 pb-0" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "col-1 mt-3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('creative_type_multiple')), 1)
      ])
    ]),
    (_ctx.creatives.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.creatives, (creative, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "insights mb-3",
            key: index
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives(_createVNode(_component_PrimeVueButton, {
                  class: "p-button-rounded p-button-text p-button-secondary",
                  icon: "pi pi-eye",
                  disabled: !creative.creative_id,
                  onClick: ($event: any) => (_ctx.$emit('previewCreative', creative))
                }, null, 8, ["disabled", "onClick"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('preview'),
                    void 0,
                    { left: true }
                  ]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _renderSlot(_ctx.$slots, "creative", { creative: creative })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_FieldNumber, {
                        modelValue: creative.weight,
                        "onUpdate:modelValue": ($event: any) => ((creative.weight) = $event),
                        modelModifiers: { number: true },
                        label: _ctx.$t('weight'),
                        min: 0,
                        max: 100,
                        step: 5,
                        "show-buttons": "",
                        "button-layout": "horizontal",
                        "increment-button-icon": "pi pi-plus",
                        "decrement-button-icon": "pi pi-minus"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createVNode(_component_IconPark, {
                  class: "cursor-pointer text-pink-600",
                  type: "delete",
                  theme: "outline",
                  size: "16",
                  onClick: ($event: any) => (_ctx.deleteCreative(index))
                }, null, 8, ["onClick"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('delete'),
                    void 0,
                    { right: true }
                  ]
                ])
              ])
            ])
          ]))
        }), 128))
      : _createCommentVNode("", true),
    _createVNode(_component_PrimeVueButton, {
      class: "p-button-success p-button-outlined w-full",
      icon: "pi pi-plus",
      onClick: _ctx.addCreative
    }, null, 8, ["onClick"])
  ]))
}