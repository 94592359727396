
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
} from 'vue';

import type {
  EntriesPaginator,
  QualityPresets,
  Statistics,
  GenericObject,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

import StatisticsBy from '@/components/Statistics/Staff/StatisticsBy.vue';

export default defineComponent({
  extends: StatisticsBy,
  components: {
    // Компоненты колонок
    EfficiencyColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/EfficiencyColumn.vue')),
    RequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/RequestsColumn.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ViewsColumn.vue')),
    PayableViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/PayableViewsColumn.vue')),
    FeedsRequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/FeedsRequestsColumn.vue')),
    ClicksColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ClicksColumn.vue')),
    ImpressionsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ImpressionsColumn.vue')),
    AdvertisersROIColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/AdvertisersROIColumn.vue')),
    CTRColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/CTRColumn.vue')),
    Subid: defineAsyncComponent(() => import('@/components/Subid.vue')),
    // Компоненты статистики
    StatisticsByFilteredClicks: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredClicks.vue')),
    StatisticsByFilteredImpressions: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredImpressions.vue')),
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsBySubids');

    const {
      entries: qualityPresets,
      entriesFetched: qualityPresetsFetched,
      fetchEntries: fetchQualityPresets,
    } = useEntriesNext<QualityPresets>('/api/quality-preset/getQualityPresets');

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const fetchStatisticsWrapper = async (subidsPaginator: EntriesPaginator): Promise<void> => {
      fetchStatistics({
        ...props.statisticsFilters,
        ...props.filters,
        ...subidsPaginator,
      });

      resetSubStatisticComponent();
    };

    const getStatisticBySubidRowClass = (row: GenericObject): string => (row.subid_uuid === subStatisticsComponent.filters.subid_uuid ? 'statistics-table-row-active' : '');

    onMounted((): Promise<void> => fetchQualityPresets());

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      qualityPresets,
      qualityPresetsFetched,
      fetchStatisticsWrapper,
      subStatisticsComponent,
      setSubStatisticsComponent,
      getStatisticBySubidRowClass,
    };
  },
});
