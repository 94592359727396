
import _findIndex from 'lodash/findIndex';

import _keys from 'lodash/keys';

import moment from 'moment';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';

import { useRoute } from 'vue-router';

import type {
  GenericObject,
  Projects,
  Users,
  Links,
  Feeds,
  Endpoints,
  Campaigns,
  Creatives,
  Countries,
  Platforms,
  Browsers,
  Statistics,
} from '@/types';

import {
  TRAFFIC_SOURCES,
  BIDDER_FEED,
  BIDDER_ADVERTISER,
  BIDDERS,
  USER_TYPE_AFFILIATE,
  TRAFFIC_CATEGORIES,
  TRAFFIC_TYPES,
  PRESELECTED_PROJECTS_IDS,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useStatisticsLink from '@/composable/useStatisticsLink';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

export default defineComponent({
  components: {
    // Компоненты колонок
    EfficiencyColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/EfficiencyColumn.vue')),
    RequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/RequestsColumn.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ViewsColumn.vue')),
    PayableViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/PayableViewsColumn.vue')),
    FeedsRequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/FeedsRequestsColumn.vue')),
    ClicksColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ClicksColumn.vue')),
    ImpressionsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ImpressionsColumn.vue')),
    AdvertisersROIColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/AdvertisersROIColumn.vue')),
    CTRColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/CTRColumn.vue')),
    // Компоненты статистики
    Actions: defineAsyncComponent(() => import('@/components/Statistics/Staff/Actions.vue')),
    StatisticsByHours: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByHours.vue')),
    StatisticsByPublishers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByPublishers.vue')),
    StatisticsByEndpoints: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByEndpoints.vue')),
    StatisticsBySubids: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsBySubids.vue')),
    StatisticsByReferrers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByReferrers.vue')),
    StatisticsByCountries: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByCountries.vue')),
    StatisticsByPlatforms: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByPlatforms.vue')),
    StatisticsByBrowsers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByBrowsers.vue')),
    StatisticsByAdvertisers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByAdvertisers.vue')),
    StatisticsByCampaigns: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByCampaigns.vue')),
    StatisticsByCreatives: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByCreatives.vue')),
    StatisticsByFeeds: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFeeds.vue')),
    StatisticsByFilteredClicks: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredClicks.vue')),
    StatisticsByFilteredImpressions: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredImpressions.vue')),
  },
  setup() {
    const route = useRoute();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers');

    const {
      entries: links,
      entriesLoading: linksLoading,
      fetchEntries: fetchLinks,
    } = useEntriesNext<Links>('/api/link/getLinks');

    const {
      entries: feeds,
      entriesLoading: feedsLoading,
      fetchEntries: fetchFeeds,
    } = useEntriesNext<Feeds>('/api/feed/getFeeds');

    const {
      entries: endpoints,
      entriesLoading: endpointsLoading,
      fetchEntries: fetchEndpoints,
    } = useEntriesNext<Endpoints>('/api/endpoint/getEndpoints');

    const {
      entries: campaigns,
      entriesLoading: campaignsLoading,
      fetchEntries: fetchCampaigns,
    } = useEntriesNext<Campaigns>('/api/campaign/getCampaigns');

    const {
      entries: creatives,
      entriesLoading: creativesLoading,
      fetchEntries: fetchCreatives,
    } = useEntriesNext<Creatives>('/api/creative/getCreatives');

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries');

    const {
      entries: platforms,
      entriesLoading: platformsLoading,
      fetchEntries: fetchPlatforms,
    } = useEntriesNext<Platforms>('/api/platform/getPlatforms');

    const {
      entries: browsers,
      entriesLoading: browsersLoading,
      fetchEntries: fetchBrowsers,
    } = useEntriesNext<Browsers>('/api/browser/getBrowsers');

    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsByDays');

    const {
      encodeStatisticsFilters,
      decodeStatisticsFilters,
    } = useStatisticsLink();

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const defaultStatisticsFilters = {
      project_id: user.isSuperAdmin() ? PRESELECTED_PROJECTS_IDS : null,
      date_from: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      link_id: null,
      traffic_source: null,
      traffic_type: null,
      traffic_category: null,
      publisher_id: null,
      endpoint_id: null,
      subid_uuid: null,
      bidder: null,
      feed_id: null,
      advertiser_id: null,
      campaign_id: null,
      creative_id: null,
      country_id: null,
      platform_id: null,
      browser_id: null,
      show_requests: false,
      show_feeds_requests: false,
      show_unique_clicks: false,
    } as GenericObject;

    const queryStatisticsFilters = {} as GenericObject;

    if (route.query.filters) {
      Object.assign(queryStatisticsFilters, decodeStatisticsFilters(route.query.filters as string));
    }

    const statisticsFilters = reactive<GenericObject>({
      ...defaultStatisticsFilters,
      ...queryStatisticsFilters,
    });

    watch(
      statisticsFilters,
      (value: GenericObject) => {
        const encodedStatisticsFilters = encodeStatisticsFilters(value);

        // eslint-disable-next-line no-restricted-globals
        history.pushState(
          {},
          '',
          `${route.path}?${new URLSearchParams({ filters: encodedStatisticsFilters }).toString()}`,
        );
      },
    );

    const statisticsFiltersKeyCounter = ref<number>(0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statisticsFilterIsEmpty = (value: any): boolean => value === null || value === '' || (Array.isArray(value) && !value.length);

    const resetStatisticsFilters = (): void => {
      Object.assign(statisticsFilters, defaultStatisticsFilters);

      statisticsFiltersKeyCounter.value += 1;
    };

    // 🎨

    const showExtraData = ref<boolean>(false);

    const showFeedsSection = ref<boolean>(true);

    const showCampaignsSection = ref<boolean>(true);

    const getStatisticByDayRowClass = (row: GenericObject): string => (row.date === subStatisticsComponent.filters.date ? 'statistics-table-row-active' : '');

    const iconSize = 10;

    //

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics(statisticsFilters);

      resetSubStatisticComponent();

      statisticsFiltersKeyCounter.value += 1;
    };

    const fetchLinksWrapper = async (): Promise<void> => fetchLinks({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
    });

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
      type: USER_TYPE_AFFILIATE,
      link_id: statisticsFilters.link_id,
    });

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
      user_id: statisticsFilters.publisher_id,
    });

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
    });

    const fetchCampaignsWrapper = async (): Promise<void> => fetchCampaigns({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
      user_id: statisticsFilters.advertiser_id,
    });

    const onPublisherChange = (): void => {
      statisticsFilters.endpoint_id = [];

      fetchEndpointsWrapper();
    };

    const onAdvertiserChange = (): void => {
      statisticsFilters.campaign_id = [];

      fetchCampaignsWrapper();
    };

    const onProjectChange = (): void => {
      statisticsFilters.link_id = [];

      fetchLinksWrapper();

      fetchUsersWrapper();

      statisticsFilters.publisher_id = [];

      onPublisherChange();

      statisticsFilters.subid_uuid = null;

      statisticsFilters.advertiser_id = [];

      onAdvertiserChange();

      statisticsFilters.feed_id = [];

      fetchFeedsWrapper();
    };

    const onLinkChange = (): void => {
      statisticsFilters.publisher_id = [];

      statisticsFilters.advertiser_id = [];

      fetchUsersWrapper();
    };

    const onBidderChange = (): void => {
      if (statisticsFilters.bidder === BIDDER_FEED) {
        statisticsFilters.advertiser_id = [];

        showFeedsSection.value = true;

        showCampaignsSection.value = false;
      }

      if (statisticsFilters.bidder === BIDDER_ADVERTISER) {
        statisticsFilters.feed_id = [];

        showFeedsSection.value = false;

        showCampaignsSection.value = true;
      }

      if (statisticsFilters.bidder === null) {
        showFeedsSection.value = false;

        showCampaignsSection.value = false;
      }
    };

    // Скрытие колонки с количеством запросов

    // Список не поддерживаемых фильтров нужно синхронизировать с RequestsColumn в platform-api
    const requestsColumnUnsupportedFilters = [
      'feed_id',
      'advertiser_id',
      'campaign_id',
    ];

    // 😨
    const hideRequestsColumn = computed((): boolean => _findIndex(requestsColumnUnsupportedFilters, (key: keyof GenericObject) => !statisticsFilterIsEmpty(statisticsFilters[key])) !== -1 || !statisticsFilters.show_requests);

    // Скрытие колонки с количеством запросов на фиды

    // Список не поддерживаемых фильтров нужно синхронизировать с FeedsRequestsColumn в platform-api
    const feedsRequestsColumnUnsupportedFilters = [
      'advertiser_id',
      'campaign_id',
    ];

    // 😨
    const hideFeedsRequestsColumn = computed((): boolean => _findIndex(feedsRequestsColumnUnsupportedFilters, (key: keyof GenericObject) => !statisticsFilterIsEmpty(statisticsFilters[key])) !== -1 || !statisticsFilters.show_feeds_requests);

    // Скрытие колонки с фактической выплатой фидов

    // Список поддерживаемых фильтров нужно синхронизировать с getStatisticsByDays и getStatisticsByFeeds в platform-api
    const feedsPayoutColumnSupportedFilters = [
      'project_id',
      'date_from',
      'date_to',
      'link_id',
      'date',
      'publisher_id',
      'endpoint_id',
      'bidder',
      'feed_id',
      'traffic_type',
      'traffic_category',
      'show_requests',
      'show_feeds_requests',
      'show_unique_clicks',
    ];

    const feedsPayoutColumnUnsupportedFilters = _keys(statisticsFilters).filter((key: string) => !feedsPayoutColumnSupportedFilters.includes(key));

    // 😨
    const hideFeedsPayoutColumn = computed((): boolean => _findIndex(feedsPayoutColumnUnsupportedFilters, (key: keyof GenericObject) => {
      // Костыли с доп. проверкой подъехали
      if (key === 'bidder') {
        return !statisticsFilterIsEmpty(statisticsFilters[key]) && statisticsFilters.bidder !== BIDDER_FEED;
      }

      return !statisticsFilterIsEmpty(statisticsFilters[key]);
    }) !== -1);

    onMounted(() => Promise.all([
      user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
      fetchLinks(),
      fetchFeeds(),
      fetchUsersWrapper(),
      fetchEndpointsWrapper(),
      fetchCampaigns(),
      fetchCreatives(),
      fetchCountries(),
      fetchPlatforms(),
      fetchBrowsers(),
    ]));

    return {
      TRAFFIC_SOURCES,
      BIDDER_FEED,
      BIDDER_ADVERTISER,
      BIDDERS,
      TRAFFIC_CATEGORIES,
      TRAFFIC_TYPES,
      user,
      projects,
      projectsLoading,
      links,
      linksLoading,
      feeds,
      feedsLoading,
      users,
      usersLoading,
      endpoints,
      endpointsLoading,
      campaigns,
      campaignsLoading,
      creatives,
      creativesLoading,
      countries,
      countriesLoading,
      platforms,
      platformsLoading,
      browsers,
      browsersLoading,
      statistics,
      statisticsLoading,
      statisticsFetched,
      subStatisticsComponent,
      setSubStatisticsComponent,
      statisticsFilters,
      statisticsFiltersKeyCounter,
      resetStatisticsFilters,
      fetchStatisticsWrapper,
      onProjectChange,
      onLinkChange,
      onPublisherChange,
      onAdvertiserChange,
      onBidderChange,
      hideRequestsColumn,
      hideFeedsRequestsColumn,
      hideFeedsPayoutColumn,
      showExtraData,
      showFeedsSection,
      showCampaignsSection,
      getStatisticByDayRowClass,
      iconSize,
    };
  },
});
