import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "white-space-nowrap" }
const _hoisted_3 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_4 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsTitle = _resolveComponent("StatisticsTitle")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_Feed = _resolveComponent("Feed")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_EfficiencyColumn = _resolveComponent("EfficiencyColumn")!
  const _component_FeedsRequestsColumn = _resolveComponent("FeedsRequestsColumn")!
  const _component_ViewsColumn = _resolveComponent("ViewsColumn")!
  const _component_PayableViewsColumn = _resolveComponent("PayableViewsColumn")!
  const _component_CTRColumn = _resolveComponent("CTRColumn")!
  const _component_ClicksColumn = _resolveComponent("ClicksColumn")!
  const _component_ImpressionsColumn = _resolveComponent("ImpressionsColumn")!
  const _component_NumberWithPercent = _resolveComponent("NumberWithPercent")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueDialog = _resolveComponent("PrimeVueDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createVNode(_component_StatisticsTitle, {
          title: _ctx.$t('feeds'),
          filters: _ctx.filters
        }, null, 8, ["title", "filters"])
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EntriesDataTableNext, {
        class: "p-datatable-sm statistics-table text-xs",
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        "data-key": "feed_id",
        "default-sort-by": "feeds_payout_estimated",
        "row-class": _ctx.getStatisticByFeedRowClass,
        "show-gridlines": true
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('feed'),
                sortable: "",
                field: "feed_id"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('efficiency'),
                sortable: "",
                field: "efficiency"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('requests'),
                sortable: "",
                field: "feeds_requests"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions'),
                sortable: "",
                field: "views"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('payable_views'),
                sortable: "",
                field: "payable_views"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: "CTR",
                sortable: "",
                field: "ctr_not_pop"
              }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('rtb_clicks'),
                sortable: "",
                field: "clicks"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('direct_clicks'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                sortable: "",
                field: "publishers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                sortable: "",
                field: "publishers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('feeds_payout'),
                sortable: "",
                field: "feeds_payout_estimated"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('profit'),
                sortable: "",
                field: "profit"
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Feed, {
                    feed: data.feed,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["feed", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_EfficiencyColumn, {
                    data: data,
                    "hide-requests-column": _ctx.hideRequestsColumn
                  }, null, 8, ["data", "hide-requests-column"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_FeedsRequestsColumn, {
                    data: data,
                    "hide-feeds-requests-column": _ctx.hideFeedsRequestsColumn
                  }, null, 8, ["data", "hide-feeds-requests-column"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_PayableViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CTRColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ClicksColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: _ctx.filters.date || null, hour: _ctx.filters.hour || null, feed_id: [data.feed_id] },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ImpressionsColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: _ctx.filters.date || null, hour: _ctx.filters.hour || null, feed_id: [data.feed_id] },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$n(data.feeds_payout_estimated, 'payout')) + " ℮", 1),
              (!_ctx.overrideHideFeedsPayoutColumn && data.feeds_payout !== undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(), _createBlock(_component_NumberWithPercent, {
                      class: "mr-1 font-semibold",
                      key: `${data.feed_id}_feeds_payout_${data.feeds_payout}`,
                      number: data.feeds_payout,
                      "number-format": "payout",
                      "number-percent": data.feeds_payout_percent,
                      "positive-context": true
                    }, null, 8, ["number", "number-percent"])),
                    (!!_ctx.filters.date)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (!data.feed.api_provider)
                            ? (_openBlock(), _createBlock(_component_IconPark, {
                                key: 0,
                                class: "cursor-pointer text-indigo-400",
                                type: "write",
                                theme: "outline",
                                size: _ctx.iconSize,
                                onClick: ($event: any) => (_ctx.showFeedPayoutDialog(data))
                              }, null, 8, ["size", "onClick"]))
                            : _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
                                key: 1,
                                type: "robot-one",
                                theme: "outline",
                                size: _ctx.iconSize
                              }, null, 8, ["size"])), [
                                [_directive_tooltip, _ctx.$t('autofilling')]
                              ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (!_ctx.overrideHideFeedsPayoutColumn && data.profit !== undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$n(data.profit, 'payout')), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, "—"))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler", "row-class"])
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.subStatisticsComponent.name)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subStatisticsComponent.name), {
              key: _ctx.subStatisticsComponent.key,
              "statistics-filters": _ctx.statisticsFilters,
              filters: _ctx.subStatisticsComponent.filters,
              "hide-requests-column": _ctx.hideRequestsColumn,
              "hide-feeds-payout-column": _ctx.hideFeedsPayoutColumn,
              "show-extra-data": _ctx.showExtraData,
              "show-feeds-section": _ctx.showFeedsSection,
              "show-campaigns-section": _ctx.showCampaignsSection,
              "icon-size": _ctx.iconSize
            }, null, 8, ["statistics-filters", "filters", "hide-requests-column", "hide-feeds-payout-column", "show-extra-data", "show-feeds-section", "show-campaigns-section", "icon-size"]))
          : _createCommentVNode("", true)
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
      ]),
      _: 1
    })),
    _createVNode(_component_PrimeVueDialog, {
      visible: _ctx.feedPayoutDialog.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feedPayoutDialog.visible) = $event)),
      header: _ctx.$t('feed_payout'),
      style: { width: '25vw' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_PrimeVueButton, {
          label: _ctx.$t('save'),
          loading: _ctx.feedPayoutDialog.loading,
          onClick: _ctx.storeFeedPayout
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.feedPayoutDialog.feedPayout.payout,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feedPayoutDialog.feedPayout.payout) = $event)),
                modelModifiers: { number: true },
                label: _ctx.$t('sum'),
                min: 0,
                step: 0.5,
                "show-buttons": "",
                "button-layout": "horizontal",
                "increment-button-icon": "pi pi-plus",
                "decrement-button-icon": "pi pi-minus"
              }, null, 8, ["modelValue", "label", "step"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}