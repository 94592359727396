
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  computed,
} from 'vue';

import { useStore } from 'vuex';

import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import PrimeVueInputText from 'primevue/inputtext';

import PrimeVuePassword from 'primevue/password';

import { VueRecaptcha } from 'vue-recaptcha';

import type {
  ErrorsMap,
  Project,
} from '@/types';

import {
  THEME_LIGHT,
  PROJECT_ID_ADBISON,
  USER_ROLE_PUBLISHER,
  USER_ROLE_ADVERTISER,
  USER_ROLES,
} from '@/libs/consts';

export default defineComponent({
  components: {
    PrimeVueInputText,
    PrimeVuePassword,
    VueRecaptcha,
  },
  setup() {
    const store = useStore();

    const router = useRouter();

    const i18n = useI18n();

    const toast = useToast();

    store.commit('SET_THEME', THEME_LIGHT);

    const project = computed((): Project => store.getters.project);

    const form = reactive({
      role: project.value.preferred_user_role || USER_ROLE_PUBLISHER,
      email: '',
      password: '',
      repeat_password: '',
      firstname: '',
      lastname: '',
      telegram: '',
      skype: '',
      recaptcha_token: '',
      link_slug: window.localStorage.getItem('link_slug'),
    });

    const accept = ref<boolean>(false);

    const acceptAdvertiser = ref<boolean>(false);

    const errorsMap = reactive<ErrorsMap>({});

    const recaptchaVerify = (token: string): void => {
      form.recaptcha_token = token;
    };

    const register = async (): Promise<void> => {
      try {
        const response = await axios.post('/api/user/register', form);

        store.commit('SET_TOKEN', response.data.token);

        await router.push('/statistics/publisher/direct');
      } catch (error: unknown | Error | AxiosError) {
        // В случае ошибки валидации API возвращает ответ с кодом 422
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          Object.assign(errorsMap, error.response.data);
        } else {
          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('unknown_error'),
            life: 5000,
          });
        }
      }
    };

    return {
      PROJECT_ID_ADBISON,
      USER_ROLE_ADVERTISER,
      USER_ROLES,
      project,
      form,
      accept,
      acceptAdvertiser,
      errorsMap,
      recaptchaVerify,
      register,
    };
  },
});
