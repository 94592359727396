
import {
  defineComponent,
  onMounted,
} from 'vue';

import PrimeVueMessage from 'primevue/message';

import { ENTRIES } from '@/libs/consts';

import useEntriesNext from '@/composable/useEntries@next';

import type { EndpointsEfficiency } from '@/types';

export default defineComponent({
  components: {
    PrimeVueMessage,
  },
  setup() {
    const {
      entries: endpointsEfficiency,
      entriesLoading: endpointsEfficiencyLoading,
      entriesFetched: endpointsEfficiencyFetched,
      fetchEntries: fetchEndpointsEfficiency,
    } = useEntriesNext<EndpointsEfficiency>('/api/endpoint-efficiency ', ENTRIES);

    onMounted(() => fetchEndpointsEfficiency());

    return {
      endpointsEfficiency,
      endpointsEfficiencyLoading,
      endpointsEfficiencyFetched,
      fetchEndpointsEfficiency,
    };
  },
});
