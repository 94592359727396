
import moment from 'moment';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  watch,
  onMounted,
} from 'vue';

import { useRoute } from 'vue-router';

import type {
  GenericObject,
  EntriesPaginator,
  Option,
  Projects,
  QualityPresets,
  Users,
  Links,
  Endpoints,
  Countries,
  Platforms,
  Browsers,
  Statistics,
} from '@/types';

import {
  TRAFFIC_SOURCES,
  USER_TYPE_AFFILIATE,
  SUBID_STATUS_AUTO_DECLINED,
  SUBID_STATUS_DECLINED,
  PRESELECTED_PROJECTS_IDS,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    Subid: defineAsyncComponent(() => import('@/components/Subid.vue')),
    SubidStatus: defineAsyncComponent(() => import('@/components/SubidStatus.vue')),
    Platform: defineAsyncComponent(() => import('@/components/Platform.vue')),
    Browser: defineAsyncComponent(() => import('@/components/Browser.vue')),
  },
  setup() {
    const route = useRoute();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entries: qualityPresets,
      entriesFetched: qualityPresetsFetched,
      fetchEntries: fetchQualityPresets,
    } = useEntriesNext<QualityPresets>('/api/quality-preset/getQualityPresets');

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers');

    const {
      entries: links,
      entriesLoading: linksLoading,
      fetchEntries: fetchLinks,
    } = useEntriesNext<Links>('/api/link/getLinks');

    const {
      entries: endpoints,
      entriesLoading: endpointsLoading,
      fetchEntries: fetchEndpoints,
    } = useEntriesNext<Endpoints>('/api/endpoint/getEndpoints');

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries');

    const {
      entries: platforms,
      entriesLoading: platformsLoading,
      fetchEntries: fetchPlatforms,
    } = useEntriesNext<Platforms>('/api/platform/getPlatforms');

    const {
      entries: browsers,
      entriesLoading: browsersLoading,
      fetchEntries: fetchBrowsers,
    } = useEntriesNext<Browsers>('/api/browser/getBrowsers');

    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsBySubidsMetrics');

    const {
      encodeStatisticsFilters,
      decodeStatisticsFilters,
    } = useStatisticsLink();

    const defaultStatisticsFilters: GenericObject = {
      project_id: user.isSuperAdmin() ? PRESELECTED_PROJECTS_IDS : null,
      date_from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      link_id: null,
      traffic_source: null,
      publisher_id: null,
      endpoint_id: null,
      subid_uuid: null,
      country_id: null,
      platform_id: null,
      browser_id: null,
      show_unique_clicks: false,
    };

    const queryStatisticsFilters: GenericObject = {};

    if (route.query.filters) {
      Object.assign(queryStatisticsFilters, decodeStatisticsFilters(route.query.filters as string));
    }

    const statisticsFilters = reactive<GenericObject>({
      ...defaultStatisticsFilters,
      ...queryStatisticsFilters,
    });

    watch(
      statisticsFilters,
      (value: GenericObject) => {
        const encodedStatisticsFilters = encodeStatisticsFilters(value);

        // eslint-disable-next-line no-restricted-globals
        history.pushState(
          {},
          '',
          `${route.path}?${new URLSearchParams({ filters: encodedStatisticsFilters }).toString()}`,
        );
      },
    );

    const statisticsFiltersKeyCounter = ref<number>(0);

    const resetStatisticsFilters = (): void => {
      Object.assign(statisticsFilters, defaultStatisticsFilters);

      statisticsFiltersKeyCounter.value += 1;
    };

    // TODO: Использовать только сортировку (!)
    const previousStatisticsPaginator = reactive<EntriesPaginator | GenericObject>({});

    const fetchStatisticsWrapper = async (statisticsPaginator: EntriesPaginator): Promise<void> => {
      Object.assign(previousStatisticsPaginator, statisticsPaginator);

      fetchStatistics({
        ...statisticsFilters,
        ...statisticsPaginator,
      });
    };

    const fetchLinksWrapper = async (): Promise<void> => fetchLinks({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
    });

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
      type: USER_TYPE_AFFILIATE,
      link_id: statisticsFilters.link_id,
    });

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints({
      project_id: user.isSuperAdmin() ? statisticsFilters.project_id : undefined,
      user_id: statisticsFilters.publisher_id,
    });

    const onPublisherChange = (): void => {
      statisticsFilters.endpoint_id = [];

      fetchEndpointsWrapper();
    };

    const onProjectChange = (): void => {
      statisticsFilters.link_id = [];

      fetchLinksWrapper();

      fetchUsersWrapper();

      statisticsFilters.publisher_id = [];

      onPublisherChange();

      statisticsFilters.subid_uuid = null;
    };

    const onLinkChange = (): void => {
      statisticsFilters.publisher_id = [];

      fetchUsersWrapper();
    };

    // 🎨

    const iconSize = 10;

    const getStatisticRowClass = (data: GenericObject): string => {
      // if (data.subid && data.subid.status === SUBID_STATUS_NEW) {
      //   return 'statistics-table-row-subid-new';
      // }

      if (data.subid && (data.subid.status === SUBID_STATUS_AUTO_DECLINED || data.subid.status === SUBID_STATUS_DECLINED)) {
        return 'statistics-table-row-subid-declined';
      }

      return '';
    };

    const hourOffsetOptions: Option[] = [24, 48, 72].map((value: number): Option => ({
      value,
      label: value.toString(),
    }));

    onMounted(() => Promise.all([
      fetchProjects(),
      fetchQualityPresets(),
      fetchUsers(),
      fetchLinks(),
      fetchEndpoints(),
      fetchCountries(),
      fetchPlatforms(),
      fetchBrowsers(),
    ]));

    return {
      TRAFFIC_SOURCES,
      user,
      projects,
      projectsLoading,
      qualityPresets,
      qualityPresetsFetched,
      users,
      usersLoading,
      links,
      linksLoading,
      endpoints,
      endpointsLoading,
      countries,
      countriesLoading,
      platforms,
      platformsLoading,
      browsers,
      browsersLoading,
      statistics,
      statisticsLoading,
      statisticsFetched,
      statisticsFilters,
      statisticsFiltersKeyCounter,
      resetStatisticsFilters,
      previousStatisticsPaginator,
      fetchStatisticsWrapper,
      onProjectChange,
      onLinkChange,
      onPublisherChange,
      iconSize,
      getStatisticRowClass,
      hourOffsetOptions,
    };
  },
});
