
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  props: {
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const errors = computed((): string[] => props.errorsMap[props.name] || []);

    return {
      errors,
    };
  },
});
