import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-6" }
const _hoisted_3 = { class: "col-12 lg:col-6" }
const _hoisted_4 = { class: "col-12 lg:col-6" }
const _hoisted_5 = { class: "col-12 lg:col-6" }
const _hoisted_6 = {
  key: 0,
  class: "col-12 lg:col-6"
}
const _hoisted_7 = { class: "grid mt-1" }
const _hoisted_8 = { class: "col-12 lg:col-6" }
const _hoisted_9 = {
  key: 1,
  class: "grid mt-1"
}
const _hoisted_10 = { class: "col-12 lg:col-6" }
const _hoisted_11 = {
  key: 0,
  class: "col-12 lg:col-3"
}
const _hoisted_12 = {
  key: 2,
  class: "grid"
}
const _hoisted_13 = {
  key: 0,
  class: "col-12 lg:col-6"
}
const _hoisted_14 = {
  key: 1,
  class: "col-12 lg:col-6"
}
const _hoisted_15 = {
  key: 2,
  class: "col-12 lg:col-6"
}
const _hoisted_16 = {
  key: 3,
  class: "col-12 lg:col-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_StatisticsByRevenue = _resolveComponent("StatisticsByRevenue")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_StatisticsByPayments = _resolveComponent("StatisticsByPayments")!
  const _component_StatisticsByNewSubids = _resolveComponent("StatisticsByNewSubids")!
  const _component_JobsCounters = _resolveComponent("JobsCounters")!
  const _component_StatisticsByDaysDirect = _resolveComponent("StatisticsByDaysDirect")!
  const _component_StatisticsByDaysRTB = _resolveComponent("StatisticsByDaysRTB")!
  const _component_StatisticsByDaysAdvertiser = _resolveComponent("StatisticsByDaysAdvertiser")!
  const _component_Manager = _resolveComponent("Manager")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: _ctx.$t('dashboard')
    }, null, 8, ["title"]),
    (_ctx.user.isStaff())
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_StatisticsByRevenue, {
                    subtitle: _ctx.$t('revenue_publishers_rtb'),
                    "show-publisher-dropdown": "",
                    "api-url": "/api/widget/getStatisticsByRevenuePublishersRTB"
                  }, null, 8, ["subtitle"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, {
                    class: "mb-3",
                    height: "120px"
                  })
                ]),
                _: 1
              }))
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_StatisticsByRevenue, {
                    subtitle: _ctx.$t('revenue_publishers_direct'),
                    "show-publisher-dropdown": "",
                    "api-url": "/api/widget/getStatisticsByRevenuePublishersDirect"
                  }, null, 8, ["subtitle"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, {
                    class: "mb-3",
                    height: "120px"
                  })
                ]),
                _: 1
              }))
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_StatisticsByRevenue, {
                    subtitle: _ctx.$t('revenue_advertisers'),
                    "show-advertiser-dropdown": "",
                    "api-url": "/api/widget/getStatisticsByRevenueAdvertisers"
                  }, null, 8, ["subtitle"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, {
                    class: "mb-3",
                    height: "120px"
                  })
                ]),
                _: 1
              }))
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_StatisticsByRevenue, {
                    subtitle: _ctx.$t('revenue_feeds'),
                    "show-feed-dropdown": "",
                    "api-url": "/api/widget/getStatisticsByRevenueFeeds"
                  }, null, 8, ["subtitle"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, {
                    class: "mb-3",
                    height: "120px"
                  })
                ]),
                _: 1
              }))
            ]),
            (_ctx.user.isSuperAdmin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_StatisticsByRevenue, {
                        subtitle: "Revenue by AdKela feeds",
                        "show-feed-dropdown": "",
                        "api-url": "/api/widget/getStatisticsByRevenueFeeds",
                        "preselected-projects-ids": [_ctx.PROJECT_ID_ADKELA]
                      }, null, 8, ["preselected-projects-ids"])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueSkeleton, {
                        class: "mb-3",
                        height: "120px"
                      })
                    ]),
                    _: 1
                  }))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_StatisticsByPayments)
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, {
                    class: "mb-3",
                    height: "120px"
                  })
                ]),
                _: 1
              }))
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.user.isStaff())
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _createVNode(_component_StatisticsByNewSubids)
              ]),
              fallback: _withCtx(() => [
                _createVNode(_component_PrimeVueSkeleton, {
                  class: "mb-3",
                  height: "120px"
                })
              ]),
              _: 1
            }))
          ]),
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_JobsCounters)
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.user.isAffiliate())
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_ctx.user.ui_publisher_direct)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_StatisticsByDaysDirect)
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.ui_publisher_rtb)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_StatisticsByDaysRTB)
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.ui_advertiser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_StatisticsByDaysAdvertiser)
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.manager_id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Manager, {
                      user: _ctx.user.manager
                    }, null, 8, ["user"])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, { height: "120px" })
                  ]),
                  _: 1
                }))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}