import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueColumnGroup = _resolveComponent("PrimeVueColumnGroup")!
  const _component_PrimeVueDataTable = _resolveComponent("PrimeVueDataTable")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!

  return (_ctx.entriesFetched)
    ? (_openBlock(), _createBlock(_component_PrimeVueDataTable, _mergeProps({
        key: 0,
        value: _ctx.entries.data,
        loading: _ctx.entriesLoading,
        lazy: _ctx.lazy,
        paginator: _ctx.paginator,
        rows: _ctx.ENTRIES_PER_PAGE,
        "total-records": _ctx.entries.meta.total,
        "paginator-template": "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        "current-page-report-template": _ctx.$t('current_page_report_template').replaceAll('[', '{').replaceAll(']', '}'),
        "sort-field": _ctx.defaultSortBy,
        "sort-order": _ctx.defaultSortByDirection === _ctx.ENTRIES_SORT_BY_DIRECTION_DESC ? -1 : 1,
        "striped-rows": "",
        "row-class": _ctx.rowClass,
        "show-gridlines": _ctx.showGridlines,
        responsiveLayout: "scroll"
      }, _toHandlers(_ctx.paginatorEvents)), {
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumnGroup, { type: "header" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header")
            ]),
            _: 3
          }),
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_PrimeVueColumnGroup, { type: "footer" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer")
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 16, ["value", "loading", "lazy", "paginator", "rows", "total-records", "current-page-report-template", "sort-field", "sort-order", "row-class", "show-gridlines"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PrimeVueSkeleton, {
          class: "mb-3",
          height: "50px"
        }),
        _createVNode(_component_PrimeVueSkeleton, {
          class: "mb-3",
          height: "50px"
        }),
        _createVNode(_component_PrimeVueSkeleton, {
          class: "mb-3",
          height: "50px"
        }),
        _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
      ]))
}