
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
  ref,
} from 'vue';

import { validate as validateUuid } from 'uuid';

const MODE_ADD = 1;

const MODE_DELETE = 2;

const MODE_REPLACE = 3;

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<(string | number)[]>,
      required: true,
    },
    allowAny: {
      type: Boolean,
      default: false,
    },
    allowUuid: {
      type: Boolean,
      default: false,
    },
    allowInt: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const values = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const valuesAsString = computed((): string => values.value.join('\n'));

    const valuesDialog = ref<boolean>(false);

    const valuesInput = ref<string>('');

    const validateLine = (line: string): boolean => {
      if (!line.length) {
        return false;
      }

      if (props.allowAny) {
        return true;
      }

      if (props.allowUuid && validateUuid(line)) {
        return true;
      }

      if (props.allowInt && !Number.isNaN(line as unknown as number - 0)) { // Магия ебаная
        return true;
      }

      return false;
    };

    const processValuesInput = (mode: number): void => {
      const valuesInputFiltered = valuesInput
        .value
        .split('\n')
        .filter((line: string) => validateLine(line))
        .map((line: string) => {
          if (props.allowInt) {
            return parseInt(line, 10);
          }

          return line;
        });

      switch (mode) {
        case MODE_ADD:
          values.value.push(...valuesInputFiltered);
          break;

        case MODE_DELETE:
          values.value = values.value.filter((value: string|number) => !valuesInputFiltered.includes(value));
          break;

        case MODE_REPLACE:
          values.value = valuesInputFiltered;
          break;

        default:
          throw new Error('invalid mode');
      }

      valuesDialog.value = false;

      valuesInput.value = '';
    };

    return {
      MODE_ADD,
      MODE_DELETE,
      MODE_REPLACE,
      values,
      valuesAsString,
      valuesDialog,
      valuesInput,
      processValuesInput,
    };
  },
});
