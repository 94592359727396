import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Suspense as _Suspense, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "grid mb-4" }
const _hoisted_3 = { class: "col-12 lg:col-4" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "p-fluid" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "col-12 lg:col-4" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "p-fluid" }
const _hoisted_10 = { class: "p-fluid" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "p-fluid" }
const _hoisted_13 = { class: "col-12 lg:col-4" }
const _hoisted_14 = { class: "card" }
const _hoisted_15 = { class: "p-fluid" }
const _hoisted_16 = { class: "p-fluid" }
const _hoisted_17 = { class: "p-fluid" }
const _hoisted_18 = { class: "col-12 lg:col-2" }
const _hoisted_19 = { class: "col-12 lg:col-2" }
const _hoisted_20 = { class: "mb-0" }
const _hoisted_21 = { class: "card" }
const _hoisted_22 = { class: "flex align-items-center" }
const _hoisted_23 = { class: "mr-1" }
const _hoisted_24 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FieldDate = _resolveComponent("FieldDate")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_ViewsColumn = _resolveComponent("ViewsColumn")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_Actions = _resolveComponent("Actions")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: _ctx.$t('statistics')
    }, null, 8, ["title"]),
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('filters')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FieldDate, {
              modelValue: _ctx.statisticsFilters.date_from,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statisticsFilters.date_from) = $event)),
              label: _ctx.$t('date_from')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FieldDate, {
              modelValue: _ctx.statisticsFilters.date_to,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statisticsFilters.date_to) = $event)),
              label: _ctx.$t('date_to')
            }, null, 8, ["modelValue", "label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(), _createBlock(_component_FieldDropdownNext, {
              key: `statistics_filters_traffic_type_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.traffic_type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.statisticsFilters.traffic_type) = $event)),
              label: _ctx.$t('traffic_type'),
              "show-clear": true,
              options: _ctx.TRAFFIC_TYPES,
              multiple: ""
            }, null, 8, ["modelValue", "label", "options"]))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.statisticsFilters.traffic_category,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.statisticsFilters.traffic_category) = $event)),
              modelModifiers: { number: true },
              label: _ctx.$t('traffic_category'),
              "show-clear": true,
              options: _ctx.TRAFFIC_CATEGORIES
            }, null, 8, ["modelValue", "label", "options"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_campaign_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.campaign_id,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.statisticsFilters.campaign_id) = $event)),
              label: _ctx.$t('campaign'),
              filter: true,
              "show-clear": true,
              loading: _ctx.campaignsLoading,
              options: _ctx.campaigns.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_creative_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.creative_id,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.statisticsFilters.creative_id) = $event)),
              label: _ctx.$t('creative'),
              filter: true,
              "show-clear": true,
              loading: _ctx.creativesLoading,
              options: _ctx.creatives.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_country_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.country_id,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.statisticsFilters.country_id) = $event)),
              label: _ctx.$t('country'),
              filter: true,
              "show-clear": true,
              loading: _ctx.countriesLoading,
              options: _ctx.countries.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ]),
          _createElementVNode("div", _hoisted_16, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_platform_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.platform_id,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.statisticsFilters.platform_id) = $event)),
              label: _ctx.$t('platforms'),
              filter: true,
              "show-clear": true,
              loading: _ctx.platformsLoading,
              options: _ctx.platforms.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false,
              multiple: true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_browser_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.browser_id,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.statisticsFilters.browser_id) = $event)),
              label: _ctx.$t('browser'),
              filter: true,
              "show-clear": true,
              loading: _ctx.browsersLoading,
              options: _ctx.browsers.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false,
              multiple: true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_PrimeVueButton, {
          class: "p-button-primary w-full",
          label: _ctx.$t('apply'),
          loading: _ctx.statisticsLoading,
          onClick: _ctx.fetchStatisticsWrapper
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_PrimeVueButton, {
          class: "p-button-outlined p-button-secondary w-full",
          label: _ctx.$t('reset'),
          onClick: _ctx.resetStatisticsFilters
        }, null, 8, ["label", "onClick"])
      ])
    ]),
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createElementVNode("h4", _hoisted_20, _toDisplayString(_ctx.$t('days')), 1)
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_21, [
      _createVNode(_component_EntriesDataTableNext, {
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        paginator: false,
        "row-class": _ctx.getStatisticByDayRowClass
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('date')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, { header: "CTR" }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('clicks')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, { header: "CPC" }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('spent')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('conversions')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('actions')
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, _toDisplayString(data.date), 1),
                _withDirectives(_createVNode(_component_IconPark, {
                  class: "cursor-pointer text-indigo-400",
                  type: "time",
                  theme: "outline",
                  size: "10",
                  onClick: ($event: any) => (_ctx.setSubStatisticsComponent('StatisticsByHours', data.date, { date: data.date }))
                }, null, 8, ["onClick"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('hours'),
                    void 0,
                    { left: true }
                  ]
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.ctr_not_pop, 'percent_with_fraction')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.clicks)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.spent, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.conversions)) + " (" + _toDisplayString(_ctx.$n(data.conversions_payout, 'payout')) + ")", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Actions, {
                    handler: _ctx.setSubStatisticsComponent,
                    "sub-statistics-component-key": data.date,
                    filters: { date: data.date }
                  }, null, 8, ["handler", "sub-statistics-component-key", "filters"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          (_ctx.statisticsFetched && _ctx.statistics.data.length)
            ? (_openBlock(), _createBlock(_component_PrimeVueRow, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('total')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ViewsColumn, {
                            data: _ctx.statistics.meta.summarized
                          }, null, 8, ["data"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.ctr_not_pop, 'percent_with_fraction')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.clicks)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.cpc, 'bid')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.spent, 'payout')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.conversions)) + " (" + _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.conversions_payout, 'payout')) + ")", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Actions, {
                            handler: _ctx.setSubStatisticsComponent,
                            "sub-statistics-component-key": "footer"
                          }, null, 8, ["handler"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler", "row-class"])
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.subStatisticsComponent.name)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subStatisticsComponent.name), {
              key: _ctx.subStatisticsComponent.key,
              "statistics-filters": _ctx.statisticsFilters,
              filters: _ctx.subStatisticsComponent.filters
            }, null, 8, ["statistics-filters", "filters"]))
          : _createCommentVNode("", true)
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
      ]),
      _: 1
    }))
  ], 64))
}