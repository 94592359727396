import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Suspense as _Suspense, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "grid mb-4" }
const _hoisted_3 = { class: "col-12 lg:col-3" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "p-fluid" }
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = { class: "col-12 lg:col-3" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "p-fluid" }
const _hoisted_13 = { class: "p-fluid" }
const _hoisted_14 = { class: "p-fluid" }
const _hoisted_15 = { class: "p-fluid" }
const _hoisted_16 = { class: "p-fluid" }
const _hoisted_17 = { class: "col-12 lg:col-3" }
const _hoisted_18 = { class: "card" }
const _hoisted_19 = { class: "p-fluid" }
const _hoisted_20 = { class: "p-fluid" }
const _hoisted_21 = { class: "p-fluid" }
const _hoisted_22 = { class: "p-fluid" }
const _hoisted_23 = { class: "p-fluid" }
const _hoisted_24 = { class: "col-12 lg:col-3" }
const _hoisted_25 = { class: "card" }
const _hoisted_26 = { class: "p-fluid" }
const _hoisted_27 = { class: "p-fluid" }
const _hoisted_28 = { class: "p-fluid" }
const _hoisted_29 = { class: "col-12 lg:col-3" }
const _hoisted_30 = { class: "col-12 lg:col-3" }
const _hoisted_31 = { class: "col-12 lg:col-3" }
const _hoisted_32 = { class: "col-12 lg:col-3" }
const _hoisted_33 = { class: "mb-0" }
const _hoisted_34 = { class: "card" }
const _hoisted_35 = { class: "flex align-items-center" }
const _hoisted_36 = { class: "mr-1" }
const _hoisted_37 = { key: 0 }
const _hoisted_38 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_39 = { class: "font-semibold" }
const _hoisted_40 = { class: "font-bold" }
const _hoisted_41 = { key: 0 }
const _hoisted_42 = {
  key: 1,
  class: "font-bold white-space-nowrap"
}
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_45 = { class: "font-bold" }
const _hoisted_46 = { class: "font-bold" }
const _hoisted_47 = { class: "white-space-nowrap" }
const _hoisted_48 = { class: "font-bold" }
const _hoisted_49 = { class: "font-bold" }
const _hoisted_50 = { class: "font-bold" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_53 = { class: "font-bold" }
const _hoisted_54 = { class: "font-bold" }
const _hoisted_55 = { class: "font-bold" }
const _hoisted_56 = { class: "font-bold" }
const _hoisted_57 = { class: "font-bold" }
const _hoisted_58 = { class: "font-bold" }
const _hoisted_59 = { class: "font-bold" }
const _hoisted_60 = { class: "font-bold" }
const _hoisted_61 = { class: "font-bold" }
const _hoisted_62 = { key: 0 }
const _hoisted_63 = {
  key: 1,
  class: "font-bold white-space-nowrap"
}
const _hoisted_64 = { class: "font-bold" }
const _hoisted_65 = { key: 0 }
const _hoisted_66 = {
  key: 1,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldDate = _resolveComponent("FieldDate")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldCheckbox = _resolveComponent("FieldCheckbox")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_EfficiencyColumn = _resolveComponent("EfficiencyColumn")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_RequestsColumn = _resolveComponent("RequestsColumn")!
  const _component_ViewsColumn = _resolveComponent("ViewsColumn")!
  const _component_PayableViewsColumn = _resolveComponent("PayableViewsColumn")!
  const _component_CTRColumn = _resolveComponent("CTRColumn")!
  const _component_ClicksColumn = _resolveComponent("ClicksColumn")!
  const _component_ImpressionsColumn = _resolveComponent("ImpressionsColumn")!
  const _component_FeedsRequestsColumn = _resolveComponent("FeedsRequestsColumn")!
  const _component_NumberWithPercent = _resolveComponent("NumberWithPercent")!
  const _component_Actions = _resolveComponent("Actions")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: _ctx.$t('statistics')
    }, null, 8, ["title"]),
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('filters')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(), _createBlock(_component_FieldDropdown, {
                  key: `statistics_filters_project_id_${_ctx.statisticsFiltersKeyCounter}`,
                  modelValue: _ctx.statisticsFilters.project_id,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statisticsFilters.project_id) = $event)),
                    _ctx.onProjectChange
                  ],
                  label: _ctx.$t('project'),
                  "show-clear": true,
                  loading: _ctx.projectsLoading,
                  options: _ctx.projects.data,
                  "option-value": "id",
                  "option-label": "name",
                  "option-label-translate": false,
                  multiple: true
                }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"]))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FieldDate, {
              modelValue: _ctx.statisticsFilters.date_from,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statisticsFilters.date_from) = $event)),
              label: _ctx.$t('date_from')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FieldDate, {
              modelValue: _ctx.statisticsFilters.date_to,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.statisticsFilters.date_to) = $event)),
              label: _ctx.$t('date_to')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_link_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.link_id,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.statisticsFilters.link_id) = $event)),
                _ctx.onLinkChange
              ],
              label: _ctx.$t('ref_link'),
              filter: true,
              "show-clear": true,
              loading: _ctx.linksLoading,
              options: _ctx.links.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.statisticsFilters.traffic_source,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.statisticsFilters.traffic_source) = $event)),
              modelModifiers: { number: true },
              name: "traffic_source",
              label: _ctx.$t('traffic_source'),
              options: _ctx.TRAFFIC_SOURCES
            }, null, 8, ["modelValue", "label", "options"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(), _createBlock(_component_FieldDropdownNext, {
              key: `statistics_filters_traffic_type_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.traffic_type,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.statisticsFilters.traffic_type) = $event)),
              label: _ctx.$t('traffic_type'),
              "show-clear": true,
              options: _ctx.TRAFFIC_TYPES,
              multiple: ""
            }, null, 8, ["modelValue", "label", "options"]))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.statisticsFilters.traffic_category,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.statisticsFilters.traffic_category) = $event)),
              modelModifiers: { number: true },
              label: _ctx.$t('traffic_category'),
              "show-clear": true,
              options: _ctx.TRAFFIC_CATEGORIES
            }, null, 8, ["modelValue", "label", "options"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_publisher_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.publisher_id,
              "onUpdate:modelValue": [
                _cache[7] || (_cache[7] = ($event: any) => ((_ctx.statisticsFilters.publisher_id) = $event)),
                _ctx.onPublisherChange
              ],
              label: _ctx.$t('publisher'),
              filter: true,
              "show-clear": true,
              loading: _ctx.usersLoading,
              options: _ctx.users.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"]))
          ]),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_endpoint_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.endpoint_id,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.statisticsFilters.endpoint_id) = $event)),
              label: _ctx.$t('endpoint'),
              filter: true,
              "show-clear": true,
              loading: _ctx.endpointsLoading,
              options: _ctx.endpoints.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_FieldText, {
              modelValue: _ctx.statisticsFilters.subid_uuid,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.statisticsFilters.subid_uuid) = $event)),
              modelModifiers: { trim: true },
              label: _ctx.$t('subid_uuid')
            }, null, 8, ["modelValue", "label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_bidder_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.bidder,
              "onUpdate:modelValue": [
                _cache[10] || (_cache[10] = ($event: any) => ((_ctx.statisticsFilters.bidder) = $event)),
                _ctx.onBidderChange
              ],
              modelModifiers: { number: true },
              name: "bidder",
              label: _ctx.$t('bidder'),
              options: _ctx.BIDDERS
            }, null, 8, ["modelValue", "label", "options", "onUpdate:modelValue"]))
          ]),
          _createElementVNode("div", _hoisted_20, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_advertiser_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.advertiser_id,
              "onUpdate:modelValue": [
                _cache[11] || (_cache[11] = ($event: any) => ((_ctx.statisticsFilters.advertiser_id) = $event)),
                _ctx.onAdvertiserChange
              ],
              label: _ctx.$t('advertiser'),
              filter: true,
              "show-clear": true,
              loading: _ctx.usersLoading,
              disabled: _ctx.statisticsFilters.bidder === _ctx.BIDDER_FEED,
              options: _ctx.users.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "disabled", "options", "onUpdate:modelValue"]))
          ]),
          _createElementVNode("div", _hoisted_21, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_campaign_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.campaign_id,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.statisticsFilters.campaign_id) = $event)),
              label: _ctx.$t('campaign'),
              filter: true,
              "show-clear": true,
              loading: _ctx.campaignsLoading,
              disabled: _ctx.statisticsFilters.bidder === _ctx.BIDDER_FEED,
              options: _ctx.campaigns.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "disabled", "options"]))
          ]),
          _createElementVNode("div", _hoisted_22, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_creative_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.creative_id,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.statisticsFilters.creative_id) = $event)),
              label: _ctx.$t('creative'),
              filter: true,
              "show-clear": true,
              loading: _ctx.creativesLoading,
              disabled: _ctx.statisticsFilters.bidder === _ctx.BIDDER_FEED,
              options: _ctx.creatives.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "disabled", "options"]))
          ]),
          _createElementVNode("div", _hoisted_23, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_feed_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.feed_id,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.statisticsFilters.feed_id) = $event)),
              label: _ctx.$t('feed'),
              filter: true,
              "show-clear": true,
              loading: _ctx.feedsLoading,
              disabled: _ctx.statisticsFilters.bidder === _ctx.BIDDER_ADVERTISER,
              options: _ctx.feeds.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "disabled", "options"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_country_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.country_id,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.statisticsFilters.country_id) = $event)),
              label: _ctx.$t('country'),
              filter: true,
              "show-clear": true,
              loading: _ctx.countriesLoading,
              options: _ctx.countries.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ]),
          _createElementVNode("div", _hoisted_27, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_platform_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.platform_id,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.statisticsFilters.platform_id) = $event)),
              label: _ctx.$t('platforms'),
              filter: true,
              "show-clear": true,
              loading: _ctx.platformsLoading,
              options: _ctx.platforms.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false,
              multiple: true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ]),
          _createElementVNode("div", _hoisted_28, [
            (_openBlock(), _createBlock(_component_FieldDropdown, {
              key: `statistics_filters_browser_id_${_ctx.statisticsFiltersKeyCounter}`,
              modelValue: _ctx.statisticsFilters.browser_id,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.statisticsFilters.browser_id) = $event)),
              label: _ctx.$t('browser'),
              filter: true,
              "show-clear": true,
              loading: _ctx.browsersLoading,
              options: _ctx.browsers.data,
              "option-value": "id",
              "option-label": "name",
              "option-label-translate": false,
              multiple: true
            }, null, 8, ["modelValue", "label", "loading", "options"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createVNode(_component_PrimeVueButton, {
          class: "p-button-primary w-full",
          label: _ctx.$t('apply'),
          loading: _ctx.statisticsLoading,
          onClick: _ctx.fetchStatisticsWrapper
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createVNode(_component_PrimeVueButton, {
          class: "p-button-outlined p-button-secondary w-full",
          label: _ctx.$t('reset'),
          onClick: _ctx.resetStatisticsFilters
        }, null, 8, ["label", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_FieldCheckbox, {
          modelValue: _ctx.showFeedsSection,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.showFeedsSection) = $event)),
          label: _ctx.$t('show_feeds'),
          disabled: !!_ctx.statisticsFilters.bidder
        }, null, 8, ["modelValue", "label", "disabled"]),
        _createVNode(_component_FieldCheckbox, {
          modelValue: _ctx.showCampaignsSection,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.showCampaignsSection) = $event)),
          label: _ctx.$t('show_campaigns'),
          disabled: !!_ctx.statisticsFilters.bidder
        }, null, 8, ["modelValue", "label", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createVNode(_component_FieldCheckbox, {
          modelValue: _ctx.showExtraData,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.showExtraData) = $event)),
          label: _ctx.$t('show_extra_data')
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_FieldCheckbox, {
          class: "mb-0",
          modelValue: _ctx.statisticsFilters.show_requests,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.statisticsFilters.show_requests) = $event)),
          label: _ctx.$t('show_requests')
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_FieldCheckbox, {
          class: "mb-0",
          modelValue: _ctx.statisticsFilters.show_feeds_requests,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.statisticsFilters.show_feeds_requests) = $event)),
          label: _ctx.$t('show_feeds_requests')
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_FieldCheckbox, {
          class: "mb-0",
          modelValue: _ctx.statisticsFilters.show_unique_clicks,
          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.statisticsFilters.show_unique_clicks) = $event)),
          label: _ctx.$t('show_unique_clicks')
        }, null, 8, ["modelValue", "label"])
      ])
    ]),
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createElementVNode("h4", _hoisted_33, _toDisplayString(_ctx.$t('days')), 1)
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_34, [
      _createVNode(_component_EntriesDataTableNext, {
        class: "p-datatable-sm statistics-table text-xs",
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        "data-key": "date",
        paginator: false,
        "row-class": _ctx.getStatisticByDayRowClass,
        "show-gridlines": true
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('main'),
                colspan: 9
              }, null, 8, ["header"]),
              (_ctx.showFeedsSection)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 0,
                    header: _ctx.$t('feeds'),
                    colspan: 5
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              (_ctx.showCampaignsSection)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 1,
                    header: _ctx.$t('campaigns'),
                    colspan: 7
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                rowspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('feeds_plus_advertisers_plus_conversions_payout'),
                rowspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                rowspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('profit'),
                rowspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('actions'),
                rowspan: 2
              }, null, 8, ["header"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('date')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('efficiency')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('requests')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('payable_views')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, { header: "CTR" }),
              _createVNode(_component_PrimeVueColumn, { header: "CPM" }),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('rtb_clicks')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('direct_clicks')
              }, null, 8, ["header"]),
              (_ctx.showFeedsSection)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('requests')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_payout')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('feeds_payout')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, { header: "CPC" }),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('profit')
                    }, null, 8, ["header"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.showCampaignsSection)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_payout')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('advertisers_payout')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, { header: "CPC" }),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('conversions_short')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('conversions_payout')
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, { header: "CTС" }),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('profit')
                    }, null, 8, ["header"])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, _toDisplayString(data.date), 1),
                _withDirectives(_createVNode(_component_IconPark, {
                  class: "cursor-pointer text-indigo-400",
                  type: "time",
                  theme: "outline",
                  size: _ctx.iconSize,
                  onClick: ($event: any) => (_ctx.setSubStatisticsComponent('StatisticsByHours', data.date, { date: data.date }))
                }, null, 8, ["size", "onClick"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('hours'),
                    void 0,
                    { left: true }
                  ]
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_EfficiencyColumn, {
                    data: data,
                    "hide-requests-column": _ctx.hideRequestsColumn
                  }, null, 8, ["data", "hide-requests-column"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_RequestsColumn, {
                    data: data,
                    "hide-requests-column": _ctx.hideRequestsColumn,
                    "show-extra-data": _ctx.showExtraData
                  }, null, 8, ["data", "hide-requests-column", "show-extra-data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_PayableViewsColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CTRColumn, { data: data }, null, 8, ["data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$n(data.cpm_native_adv, 'payout_3signs')), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.$n(data.cpm_native_pub, 'payout_3signs')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ClicksColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: data.date },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ImpressionsColumn, {
                    data: data,
                    "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                    filters: { date: data.date },
                    "show-extra-data": _ctx.showExtraData,
                    "icon-size": _ctx.iconSize
                  }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          (_ctx.showFeedsSection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    (_openBlock(), _createBlock(_Suspense, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_FeedsRequestsColumn, {
                          data: data,
                          "hide-feeds-requests-column": _ctx.hideFeedsRequestsColumn
                        }, null, 8, ["data", "hide-feeds-requests-column"])
                      ]),
                      fallback: _withCtx(() => [
                        _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                      ]),
                      _: 2
                    }, 1024))
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout_by_feeds, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$n(data.feeds_payout_estimated, 'payout')) + " ℮", 1),
                    (!_ctx.hideFeedsPayoutColumn && data.feeds_payout !== undefined)
                      ? (_openBlock(), _createBlock(_component_NumberWithPercent, {
                          class: "font-semibold",
                          key: `${data.date}_feeds_payout`,
                          number: data.feeds_payout,
                          "number-format": "payout",
                          "number-percent": data.feeds_payout_percent,
                          "positive-context": true
                        }, null, 8, ["number", "number-percent"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _withDirectives((_openBlock(), _createElementBlock("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc_by_feeds, 'bid')), 1)
                    ])), [
                      [_directive_tooltip, _ctx.$t('publishers_cpc')]
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$n(data.feeds_cpc, 'bid')), 1)
                    ])), [
                      [_directive_tooltip, _ctx.$t('feeds_cpc')]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    (_ctx.hideFeedsPayoutColumn || data.profit_by_feeds === undefined)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, _toDisplayString(_ctx.$n(data.profit_by_feeds_estimated, 'payout')) + " ℮", 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(_ctx.$n(data.profit_by_feeds, 'payout')), 1))
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.showCampaignsSection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout_by_campaigns, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$n(data.advertisers_payout, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _withDirectives((_openBlock(), _createElementBlock("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc_by_campaigns, 'bid')), 1)
                    ])), [
                      [_directive_tooltip, _ctx.$t('publishers_cpc')]
                    ]),
                    _withDirectives((_openBlock(), _createElementBlock("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_cpc, 'bid')), 1)
                    ])), [
                      [_directive_tooltip, _ctx.$t('advertisers_cpc')]
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.conversions)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.conversions_payout, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_ctc, 'percent_with_fraction')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$n(data.profit_by_campaigns, 'payout')), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (data.feeds_plus_advertisers_plus_conversions_payout === undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_ctx.$n(data.feeds_plus_advertisers_plus_conversions_payout_estimated, 'payout')) + " ℮", 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.$n(data.feeds_plus_advertisers_plus_conversions_payout, 'payout')) + " Σ", 1))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (data.profit === undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_ctx.$n(data.profit_estimated, 'payout')) + " ℮", 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.$n(data.profit, 'payout')), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Actions, {
                    handler: _ctx.setSubStatisticsComponent,
                    "sub-statistics-component-key": data.date,
                    "statistics-filters": _ctx.statisticsFilters,
                    filters: { date: data.date }
                  }, null, 8, ["handler", "sub-statistics-component-key", "statistics-filters", "filters"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          (_ctx.statisticsFetched && _ctx.statistics.data.length)
            ? (_openBlock(), _createBlock(_component_PrimeVueRow, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_PrimeVueColumn),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_EfficiencyColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized,
                            "hide-requests-column": _ctx.hideRequestsColumn
                          }, null, 8, ["data", "hide-requests-column"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_RequestsColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized,
                            "hide-requests-column": _ctx.hideRequestsColumn,
                            "show-extra-data": _ctx.showExtraData
                          }, null, 8, ["data", "hide-requests-column", "show-extra-data"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ViewsColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized
                          }, null, 8, ["data"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_PayableViewsColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized
                          }, null, 8, ["data"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_CTRColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized
                          }, null, 8, ["data"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.cpm_native_adv, 'payout_3signs')), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.cpm_native_pub, 'payout_3signs')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ClicksColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized,
                            "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                            filters: { date_from: _ctx.statisticsFilters.date_from, date_to: _ctx.statisticsFilters.date_to },
                            "show-extra-data": _ctx.showExtraData,
                            "icon-size": _ctx.iconSize
                          }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ImpressionsColumn, {
                            class: "font-bold",
                            data: _ctx.statistics.meta.summarized,
                            "set-sub-statistics-component-function": _ctx.setSubStatisticsComponent,
                            filters: { date_from: _ctx.statisticsFilters.date_from, date_to: _ctx.statisticsFilters.date_to },
                            "show-extra-data": _ctx.showExtraData,
                            "icon-size": _ctx.iconSize
                          }, null, 8, ["data", "set-sub-statistics-component-function", "filters", "show-extra-data", "icon-size"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }),
                  (_ctx.showFeedsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 0 }, {
                        footer: _withCtx(() => [
                          (_openBlock(), _createBlock(_Suspense, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_FeedsRequestsColumn, {
                                class: "font-bold",
                                data: _ctx.statistics.meta.summarized,
                                "hide-feeds-requests-column": _ctx.hideFeedsRequestsColumn
                              }, null, 8, ["data", "hide-feeds-requests-column"])
                            ]),
                            fallback: _withCtx(() => [
                              _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                            ]),
                            _: 1
                          }))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showFeedsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 1 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.publishers_payout_by_feeds, 'payout')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showFeedsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 2 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_46, [
                            _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.feeds_payout_estimated, 'payout')) + " ℮", 1),
                            (!_ctx.hideFeedsPayoutColumn && _ctx.statistics.meta.summarized.feeds_payout !== undefined)
                              ? (_openBlock(), _createBlock(_component_NumberWithPercent, {
                                  key: "footer_feeds_payout",
                                  number: _ctx.statistics.meta.summarized.feeds_payout,
                                  "number-format": "payout",
                                  "number-percent": _ctx.statistics.meta.summarized.feeds_payout_percent,
                                  "positive-context": true
                                }, null, 8, ["number", "number-percent"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showFeedsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 3 }, {
                        footer: _withCtx(() => [
                          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_48, [
                            _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.publishers_cpc_by_feeds, 'bid')), 1)
                          ])), [
                            [_directive_tooltip, _ctx.$t('publishers_cpc')]
                          ]),
                          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_49, [
                            _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.feeds_cpc, 'bid')), 1)
                          ])), [
                            [_directive_tooltip, _ctx.$t('feeds_cpc')]
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showFeedsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 4 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_50, [
                            (_ctx.hideFeedsPayoutColumn || _ctx.statistics.meta.summarized.profit_by_feeds === undefined)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_51, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.profit_by_feeds_estimated, 'payout')) + " ℮", 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_52, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.profit_by_feeds, 'payout')), 1))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 5 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.publishers_payout_by_campaigns, 'payout')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 6 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.advertisers_payout, 'payout')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 7 }, {
                        footer: _withCtx(() => [
                          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_55, [
                            _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.publishers_cpc_by_campaigns, 'bid')), 1)
                          ])), [
                            [_directive_tooltip, _ctx.$t('publishers_cpc')]
                          ]),
                          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_56, [
                            _createTextVNode(_toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.advertisers_cpc, 'bid')), 1)
                          ])), [
                            [_directive_tooltip, _ctx.$t('advertisers_cpc')]
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 8 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.conversions)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 9 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.conversions_payout, 'payout')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 10 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.advertisers_ctc, 'percent_with_fraction')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.showCampaignsSection)
                    ? (_openBlock(), _createBlock(_component_PrimeVueColumn, { key: 11 }, {
                        footer: _withCtx(() => [
                          _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.profit_by_campaigns, 'payout')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.publishers_payout, 'payout')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_ctx.statistics.meta.summarized.feeds_plus_advertisers_plus_conversions_payout === undefined)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_62, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.feeds_plus_advertisers_plus_conversions_payout_estimated, 'payout')) + " ℮", 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_63, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.feeds_plus_advertisers_plus_conversions_payout, 'payout')) + " Σ", 1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.publishers_cpc, 'bid')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_ctx.statistics.meta.summarized.profit === undefined)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_65, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.profit_estimated, 'payout')) + " ℮", 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_66, _toDisplayString(_ctx.$n(_ctx.statistics.meta.summarized.profit, 'payout')), 1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_PrimeVueColumn, null, {
                    footer: _withCtx(() => [
                      (_openBlock(), _createBlock(_Suspense, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Actions, {
                            handler: _ctx.setSubStatisticsComponent,
                            "sub-statistics-component-key": "footer",
                            "statistics-filters": _ctx.statisticsFilters,
                            filters: { date_from: _ctx.statisticsFilters.date_from, date_to: _ctx.statisticsFilters.date_to }
                          }, null, 8, ["handler", "statistics-filters", "filters"])
                        ]),
                        fallback: _withCtx(() => [
                          _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler", "row-class"])
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.subStatisticsComponent.name)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subStatisticsComponent.name), {
              key: _ctx.subStatisticsComponent.key,
              "statistics-filters": _ctx.statisticsFilters,
              filters: _ctx.subStatisticsComponent.filters,
              "hide-requests-column": _ctx.hideRequestsColumn,
              "hide-feeds-requests-column": _ctx.hideFeedsRequestsColumn,
              "hide-feeds-payout-column": _ctx.hideFeedsPayoutColumn,
              "show-extra-data": _ctx.showExtraData,
              "show-feeds-section": _ctx.showFeedsSection,
              "show-campaigns-section": _ctx.showCampaignsSection,
              "icon-size": _ctx.iconSize
            }, null, 8, ["statistics-filters", "filters", "hide-requests-column", "hide-feeds-requests-column", "hide-feeds-payout-column", "show-extra-data", "show-feeds-section", "show-campaigns-section", "icon-size"]))
          : _createCommentVNode("", true)
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
      ]),
      _: 1
    }))
  ], 64))
}