
import axios from 'axios';

import 'highlight.js/styles/a11y-light.css';

import hljs from 'highlight.js/lib/core';

import hljsJSON from 'highlight.js/lib/languages/json';

import {
  defineComponent,
  ref,
  computed,
} from 'vue';

import { ErrorsMap } from '@/types';

hljs.registerLanguage('json', hljsJSON);

export default defineComponent({
  setup() {
    const payload = ref<string>('');

    const errorsMap = ref<ErrorsMap>({});

    const payloadDecoded = ref<string>('');

    const isAdvertiserClickUrl = ref<boolean>(false);

    const payloadDecodeLoading = ref<boolean>(false);

    const decodePayload = async (value: string): Promise<void> => {
      errorsMap.value = {};

      payloadDecoded.value = '';

      if (isAdvertiserClickUrl.value) {
        payloadDecodeLoading.value = true;

        const response = await axios.post('api/tool/getAdSecureByClickURL', {
          click_url: payload.value,
        });

        payloadDecoded.value = JSON.stringify(response.data);

        payloadDecodeLoading.value = false;

        return;
      }

      try {
        const url = new URL(value);

        payload.value = url.searchParams.get('payload') || '';
      } catch (error) {
        //
      }

      if (!payload.value) {
        return;
      }

      try {
        payloadDecoded.value = atob(payload.value);
      } catch (error) {
        errorsMap.value = { payload: ['❌'] };

        payloadDecoded.value = '';
      }
    };

    const payloadDecodedHeightlighted = computed((): string => hljs.highlight(payloadDecoded.value, { language: 'json' }).value);

    return {
      payload,
      errorsMap,
      payloadDecoded,
      payloadDecodedHeightlighted,
      payloadDecodeLoading,
      isAdvertiserClickUrl,
      decodePayload,
    };
  },
});
