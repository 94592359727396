
import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  GenericObject,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

import StatisticsBy from '@/components/Statistics/Staff/StatisticsBy.vue';

export default defineComponent({
  extends: StatisticsBy,
  components: {
    // Компоненты колонок
    EfficiencyColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/EfficiencyColumn.vue')),
    RequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/RequestsColumn.vue')),
    FeedsRequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/FeedsRequestsColumn.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ViewsColumn.vue')),
    PayableViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/PayableViewsColumn.vue')),
    ClicksColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ClicksColumn.vue')),
    ImpressionsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ImpressionsColumn.vue')),
    AdvertisersROIColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/AdvertisersROIColumn.vue')),
    CTRColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/CTRColumn.vue')),
    Browser: defineAsyncComponent(() => import('@/components/Browser.vue')),
    // Компоненты статистики
    StatisticsByFilteredClicks: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredClicks.vue')),
    StatisticsByFilteredImpressions: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredImpressions.vue')),
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsByBrowsers');

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics({
        ...props.statisticsFilters,
        ...props.filters,
      });

      resetSubStatisticComponent();
    };

    const getStatisticByBrowserRowClass = (row: GenericObject): string => (Array.isArray(subStatisticsComponent.filters.browser_id) && subStatisticsComponent.filters.browser_id.includes(row.browser_id) ? 'statistics-table-row-active' : '');

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
      subStatisticsComponent,
      setSubStatisticsComponent,
      getStatisticByBrowserRowClass,
    };
  },
});
