
import axios from 'axios';

import {
  defineComponent,
  ref,
} from 'vue';

import type {
  EntriesPaginator,
  Projects,
} from '@/types';

import {
  ENTRIES,
  PROJECT_STATUSES_MAP,
} from '@/libs/consts';

export default defineComponent({
  setup() {
    const projects = ref<Projects>({ ...ENTRIES });

    const fetchProjects = async (projectsPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/project/getProjectsPaginator',
        {
          ...projectsPaginator,
        },
      );

      projects.value = response.data;
    };

    return {
      PROJECT_STATUSES_MAP,
      projects,
      fetchProjects,
    };
  },
});
