
import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import {
  PROJECT_ID_ADBISON,
  PROJECT_ID_TRAFFBAY,
  PROJECT_ID_ADKELA,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    StatisticsByRevenue: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByRevenue.vue')),
    StatisticsByDaysDirect: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByDaysDirect.vue')),
    StatisticsByDaysRTB: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByDaysRTB.vue')),
    StatisticsByDaysAdvertiser: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByDaysAdvertiser.vue')),
    StatisticsByPayments: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByPayments.vue')),
    StatisticsByNewSubids: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByNewSubids.vue')),
    JobsCounters: defineAsyncComponent(() => import('@/components/Widgets/JobsCounters.vue')),
    Manager: defineAsyncComponent(() => import('@/components/Manager.vue')),
  },
  setup() {
    const { activeUser: user } = useUser();

    return {
      PROJECT_ID_ADBISON,
      PROJECT_ID_TRAFFBAY,
      PROJECT_ID_ADKELA,
      user,
    };
  },
});
