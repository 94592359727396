import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-3" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = { class: "col-12 lg:col-3" }
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "p-fluid" }
const _hoisted_15 = { class: "card" }
const _hoisted_16 = { class: "p-fluid" }
const _hoisted_17 = { class: "card" }
const _hoisted_18 = { class: "p-fluid" }
const _hoisted_19 = { class: "card" }
const _hoisted_20 = { class: "p-fluid" }
const _hoisted_21 = { class: "p-fluid" }
const _hoisted_22 = { class: "col-12 lg:col-3" }
const _hoisted_23 = { class: "card" }
const _hoisted_24 = { class: "p-fluid" }
const _hoisted_25 = { class: "col-12 lg:col-3" }
const _hoisted_26 = {
  key: 0,
  class: "card"
}
const _hoisted_27 = { class: "p-fluid" }
const _hoisted_28 = {
  key: 1,
  class: "card"
}
const _hoisted_29 = {
  key: 2,
  class: "card"
}
const _hoisted_30 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_Macros = _resolveComponent("Macros")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldCheckbox = _resolveComponent("FieldCheckbox")!
  const _component_Entry = _resolveComponent("Entry")!

  return (_openBlock(), _createBlock(_component_Entry, {
    "entry-errors-map": _ctx.projectErrorsMap,
    "onUpdate:entry-errors-map": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.projectErrorsMap) = $event)),
    title: _ctx.$t('project'),
    entry: _ctx.project,
    "entry-fetch-handler": _ctx.fetchProject,
    "entry-store-handler": _ctx.storeProject
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!!_ctx.project.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.project.id,
                    label: "ID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.project.name) = $event)),
                modelModifiers: { trim: true },
                name: "name",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('name')
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.host,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.project.host) = $event)),
                modelModifiers: { trim: true },
                name: "host",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('host')
              }, null, 8, ["modelValue", "errors-map", "label"])
            ]),
            (_ctx.user.isSuperAdmin())
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_FieldText, {
                    modelValue: _ctx.project.dev_host,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.project.dev_host) = $event)),
                    modelModifiers: { trim: true },
                    name: "dev_host",
                    "errors-map": _ctx.projectErrorsMap,
                    label: _ctx.$t('dev_host')
                  }, null, 8, ["modelValue", "errors-map", "label"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.click_host_pop,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.project.click_host_pop) = $event)),
                modelModifiers: { trim: true },
                name: "click_host_pop",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('click_host_pop'),
                help: "For feeds"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.click_host_pop_campaigns,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.project.click_host_pop_campaigns) = $event)),
                modelModifiers: { trim: true },
                name: "click_host_pop_campaigns",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('click_host_pop'),
                help: "For campaigns"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.click_host_pop_sensitive,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.project.click_host_pop_sensitive) = $event)),
                modelModifiers: { trim: true },
                name: "click_host_pop_sensitive",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('click_host_pop_sensitive'),
                help: "For feeds"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.click_host_pop_campaigns_sensitive,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.project.click_host_pop_campaigns_sensitive) = $event)),
                modelModifiers: { trim: true },
                name: "click_host_pop_campaigns_sensitive",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('click_host_pop_sensitive'),
                help: "For campaigns"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.click_host_push,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.project.click_host_push) = $event)),
                modelModifiers: { trim: true },
                name: "click_host_push",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('click_host_push')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.click_host_push_sensitive,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.project.click_host_push_sensitive) = $event)),
                modelModifiers: { trim: true },
                name: "click_host_push_sensitive",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('click_host_push_sensitive')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.image_host,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.project.image_host) = $event)),
                modelModifiers: { trim: true },
                name: "image_host",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('image_host')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.push_host,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.project.push_host) = $event)),
                modelModifiers: { trim: true },
                name: "push_host",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('push_host')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.project.ssp_host,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.project.ssp_host) = $event)),
                modelModifiers: { trim: true },
                name: "ssp_host",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('ssp_host')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldTextarea, {
                modelValue: _ctx.project.extra_hosts,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.project.extra_hosts) = $event)),
                modelModifiers: { trim: true },
                name: "extra_hosts",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('extra_hosts'),
                help: _ctx.$t('extra_hosts_help')
              }, null, 8, ["modelValue", "errors-map", "label", "help"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.min_bid_pop,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.project.min_bid_pop) = $event)),
                modelModifiers: { number: true },
                name: "min_bid_pop",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('min_bid_pop'),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 6,
                prefix: "$"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.max_bid_pop,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.project.max_bid_pop) = $event)),
                modelModifiers: { number: true },
                name: "max_bid_pop",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('max_bid_pop'),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 6,
                prefix: "$"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.min_bid_push,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.project.min_bid_push) = $event)),
                modelModifiers: { number: true },
                name: "min_bid_push",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('min_bid_push'),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 6,
                prefix: "$"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.max_bid_push,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.project.max_bid_push) = $event)),
                modelModifiers: { number: true },
                name: "max_bid_push",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('max_bid_push'),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 6,
                prefix: "$"
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.min_bid_native,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.project.min_bid_native) = $event)),
                modelModifiers: { number: true },
                name: "min_bid_native",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('min_bid_native'),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 6
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.max_bid_native,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.project.max_bid_native) = $event)),
                modelModifiers: { number: true },
                name: "max_bid_native",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('max_bid_native'),
                min: 0,
                "min-fraction-digits": 1,
                "max-fraction-digits": 6,
                prefix: "$"
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.ttl_pop,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.project.ttl_pop) = $event)),
                modelModifiers: { number: true },
                name: "ttl_pop",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('ttl_pop'),
                min: 0,
                step: 60,
                "show-buttons": "",
                "button-layout": "horizontal",
                "increment-button-icon": "pi pi-plus",
                "decrement-button-icon": "pi pi-minus",
                suffix: ` ${_ctx.$t('sec')}`
              }, null, 8, ["modelValue", "errors-map", "label", "suffix"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.ttl_push,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.project.ttl_push) = $event)),
                modelModifiers: { number: true },
                name: "ttl_push",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('ttl_push'),
                min: 0,
                step: 60,
                "show-buttons": "",
                "button-layout": "horizontal",
                "increment-button-icon": "pi pi-plus",
                "decrement-button-icon": "pi pi-minus",
                suffix: ` ${_ctx.$t('sec')}`
              }, null, 8, ["modelValue", "errors-map", "label", "suffix"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.ttl_native,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.project.ttl_native) = $event)),
                modelModifiers: { number: true },
                name: "ttl_native",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('ttl_native'),
                min: 0,
                step: 60,
                "show-buttons": "",
                "button-layout": "horizontal",
                "increment-button-icon": "pi pi-plus",
                "decrement-button-icon": "pi pi-minus",
                suffix: ` ${_ctx.$t('sec')}`
              }, null, 8, ["modelValue", "errors-map", "label", "suffix"]),
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.timeout,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.project.timeout) = $event)),
                modelModifiers: { number: true },
                name: "timeout",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('timeout'),
                min: 0,
                step: 10,
                "show-buttons": "",
                "button-layout": "horizontal",
                "increment-button-icon": "pi pi-plus",
                "decrement-button-icon": "pi pi-minus",
                suffix: ` ${_ctx.$t('ms')}`
              }, null, 8, ["modelValue", "errors-map", "label", "suffix"])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_FieldNumber, {
                modelValue: _ctx.project.max_subids_per_day,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.project.max_subids_per_day) = $event)),
                modelModifiers: { number: true },
                name: "max_subids_per_day",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('max_subids_per_day'),
                min: 0,
                step: 100,
                "show-buttons": "",
                "button-layout": "horizontal",
                "increment-button-icon": "pi pi-plus",
                "decrement-button-icon": "pi pi-minus"
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_FieldTextarea, {
                modelValue: _ctx.project.passback_url_mainstream,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.project.passback_url_mainstream) = $event)),
                modelModifiers: { trim: true },
                name: "passback_url_mainstream",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('passback_url_mainstream'),
                rows: 2
              }, null, 8, ["modelValue", "errors-map", "label"]),
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Macros, {
                    class: "mt-3 mb-3",
                    macros: ['endpoint_id', 'subid', 'subid_uuid', 'reason']
                  })
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 1
              }))
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(_component_FieldTextarea, {
                modelValue: _ctx.project.passback_url_adult,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.project.passback_url_adult) = $event)),
                modelModifiers: { trim: true },
                name: "passback_url_adult",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('passback_url_adult'),
                rows: 2
              }, null, 8, ["modelValue", "errors-map", "label"]),
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Macros, {
                    class: "mt-3",
                    macros: ['endpoint_id', 'subid', 'subid_uuid', 'reason']
                  })
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 1
              }))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_FieldDropdown, {
                modelValue: _ctx.project.theme_color,
                "onUpdate:modelValue": [
                  _cache[26] || (_cache[26] = ($event: any) => ((_ctx.project.theme_color) = $event)),
                  _ctx.onThemeColorChange
                ],
                name: "theme_color",
                "errors-map": _ctx.projectErrorsMap,
                label: _ctx.$t('theme_color'),
                options: _ctx.THEMES_COLORS,
                "option-label": "label",
                "option-label-translate": false
              }, null, 8, ["modelValue", "errors-map", "label", "options", "onUpdate:modelValue"]),
              _createVNode(_component_FieldCheckbox, {
                modelValue: _ctx.previewOnThemeColorChange,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.previewOnThemeColorChange) = $event)),
                label: _ctx.$t('preview_on_theme_color_change')
              }, null, 8, ["modelValue", "label"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_FieldDropdown, {
                    modelValue: _ctx.project.status,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.project.status) = $event)),
                    modelModifiers: { number: true },
                    name: "status",
                    "errors-map": _ctx.projectErrorsMap,
                    label: _ctx.$t('status'),
                    options: _ctx.PROJECT_STATUSES
                  }, null, 8, ["modelValue", "errors-map", "label", "options"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createVNode(_component_FieldTextarea, {
                  modelValue: _ctx.project.comment,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.project.comment) = $event)),
                  modelModifiers: { trim: true },
                  name: "comment",
                  "errors-map": _ctx.projectErrorsMap,
                  label: _ctx.$t('comment')
                }, null, 8, ["modelValue", "errors-map", "label"])
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.project.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.project.created_at,
                    label: _ctx.$t('created_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"]),
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.project.updated_at,
                    label: _ctx.$t('updated_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["entry-errors-map", "title", "entry", "entry-fetch-handler", "entry-store-handler"]))
}