
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import { useI18n } from 'vue-i18n';

import PrimeVueMessage from 'primevue/message';

import type {
  Option,
  ErrorsMap,
  Feed,
} from '@/types';

import {
  ENTRIES_SORT_BY_DIRECTION_ASC,
  TRAFFIC_TYPE_POP,
  TRAFFIC_TYPE_NATIVE,
  TRAFFIC_TYPES,
  TRAFFIC_TYPE_MIX,
  TRAFFIC_CATEGORIES,
  REGIONS,
  BID_SUBJECT_CLICK,
  BID_SUBJECTS,
  BID_TYPES,
  TARGETING_TYPE_DISABLED,
  TARGETING_TYPES,
  TARGETING_TYPES_BY_TRAFFIC_CATEGORY,
  TARGETING_TYPES_BY_TRAFFIC_SOURCE,
  TARGETING_TYPES_BY_PSEUDOSITES,
  PROTOCOL_LEGACY,
  PROTOCOLS,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  FEED_STATUSES,
  FEED_CUSTOMIZERS,
  FEED_CUSTOMIZERS_MAP,
} from '@/libs/consts';

import {
  FEED_API_PROVIDERS,
  FEED_API_PROVIDERS_MAP,
} from '@/libs/feedApiProvider';

import useUser from '@/composable/useUser';

import useClipboard from '@/composable/useClipboard';

import useEntries from '@/composable/useEntries';

import TargetingByValues from '@/components/TargetingByValues.vue';

import FieldBidCPC from '@/components/FieldBidCPC.vue';

export default defineComponent({
  components: {
    PrimeVueMessage,
    TargetingByValues,
    FieldBidCPC,
    AdSecure: defineAsyncComponent(() => import('@/views/AdSecure.vue')),
  },
  setup() {
    const i18n = useI18n();

    const { activeUser: user } = useUser();

    const { copyToClipboard } = useClipboard();

    const { getEntryByRoute } = useEntries();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: schemesLoading,
      entries: schemes,
      fetchEntries: fetchSchemes,
    } = useEntries();

    const {
      entriesLoading: qualityPresetsLoading,
      entries: qualityPresets,
      fetchEntries: fetchQualityPresets,
    } = useEntries();

    const {
      entriesLoading: managersLoading,
      entries: managers,
      fetchEntries: fetchManagers,
    } = useEntries();

    const {
      entriesLoading: countriesLoading,
      entries: countries,
      fetchEntries: fetchCountries,
    } = useEntries();

    const {
      entriesLoading: platformsLoading,
      entries: platforms,
      fetchEntries: fetchPlatforms,
    } = useEntries();

    const {
      entriesLoading: browsersLoading,
      entries: browsers,
      fetchEntries: fetchBrowsers,
    } = useEntries();

    const {
      entriesLoading: pseudositesLoading,
      entries: pseudosites,
      fetchEntries: fetchPseudosites,
    } = useEntries();

    const {
      entriesLoading: endpointsLoading,
      entries: endpoints,
      fetchEntries: fetchEndpoints,
    } = useEntries();

    const feed = reactive<Feed>({
      traffic_type: TRAFFIC_TYPE_POP,
      mix_traffic_type: false,
      customizer: null,
      bid_subject: BID_SUBJECT_CLICK,
      revshare: 100,
      fixed_bid: null,
      max_clicks_per_ip: null,
      max_revenue_per_day: null,
      filter_ip_mismatch: true,
      filter_proxy: true,
      filter_has_no_js: true,
      filter_iframe: true,
      filter_headless: true,
      filter_timezone_mismatch: false,
      filter_user_agent_mismatch: true,
      filter_referrer_mismatch: true,
      filter_click_without_impression: true,
      filter_double_click: true,
      quality_presets_ids: [],
      targeting_by_countries: TARGETING_TYPE_DISABLED,
      countries_ids: [],
      targeting_by_platforms: TARGETING_TYPE_DISABLED,
      platforms_ids: [],
      targeting_by_browsers: TARGETING_TYPE_DISABLED,
      browsers_ids: [],
      targeting_by_pseudosites: TARGETING_TYPE_DISABLED,
      pseudosites_ids: [],
      targeting_by_subids: TARGETING_TYPE_DISABLED,
      subids_uuids: [],
      targeting_by_traffic_category: TARGETING_TYPE_DISABLED,
      targeting_by_traffic_source: TARGETING_TYPE_DISABLED,
      meta_refresh: true,
      referrer_spoofing: false,
      referrer_spoofing_host: null,
      max_qps: null,
      requests_optimization_delay_to: null,
      comment: null,
      manager_id: null,
      api_provider: null,
      api_provider_config: {},
      adsecure: true,
      has_violations: false,
      targeting_by_endpoints_ids: TARGETING_TYPE_DISABLED,
      endpoints_ids: [],
    } as unknown as Feed);

    const feedErrorsMap = ref<ErrorsMap>({});

    const feedApiProvidersOptions = FEED_API_PROVIDERS.map((provider) => ({
      value: provider.value,
      label: provider.label,
    } as Option));

    const fetchQualityPresetsWrapper = async (): Promise<void> => fetchQualityPresets(
      '/api/quality-preset/getQualityPresets',
      {
        project_id: user.isSuperAdmin() ? feed.project_id : undefined,
      },
    );

    const fetchManagersWrapper = async (): Promise<void> => fetchManagers(
      '/api/user/getUsers',
      {
        project_id: user.isSuperAdmin() ? feed.project_id : undefined,
        type: [
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
    );

    const fetchPseudositesWrapper = async (): Promise<void> => fetchPseudosites(
      '/api/pseudosite/getPseudosites',
      {
        project_id: user.isSuperAdmin() ? feed.project_id : undefined,
        traffic_category: feed.traffic_category,
      },
    );

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints(
      '/api/endpoint/getEndpoints',
      {
        project_id: user.isSuperAdmin() ? feed.project_id : undefined,
        // Для фида отображаем максимально доступное покрытие
        traffic_type: TRAFFIC_TYPE_MIX.get(feed.traffic_type),
        sort_by: 'status',
        sort_by_direction: ENTRIES_SORT_BY_DIRECTION_ASC,
      },
    );

    const fetchFeed = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/feed/getFeedById');

      if (entry) {
        Object.assign(feed, entry);
      }

      Promise.all([
        fetchQualityPresetsWrapper(),
        fetchManagersWrapper(),
        fetchPseudositesWrapper(),
        fetchEndpointsWrapper(),
      ]);
    };

    const storeFeed = async (): Promise<void> => {
      const response = await axios.post('/api/feed/storeFeed', feed);

      Object.assign(feed, response.data);
    };

    const cloneFeed = (): void => {
      feed.id = null as unknown as number;

      feed.name += ` - ${i18n.t('copy_female')}`;
    };

    const onTrafficTypeChange = (): void => {
      feed.endpoints_ids = [];

      fetchEndpointsWrapper();
    };

    const onTrafficCategoryChange = (): void => {
      feed.pseudosites_ids = [];

      fetchPseudositesWrapper();
    };

    const onReferrerSpoofingChange = (): void => {
      if (!feed.referrer_spoofing) {
        feed.referrer_spoofing_host = null;

        feed.pseudosites_ids = [];

        feed.targeting_by_pseudosites = TARGETING_TYPE_DISABLED;
      }
    };

    const onProjectChange = (): void => {
      feed.quality_presets_ids = [];

      fetchQualityPresetsWrapper();

      feed.manager_id = null;

      fetchManagersWrapper();

      onTrafficTypeChange();

      onTrafficCategoryChange();
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
        fetchCountries('/api/country/getCountries'),
        fetchPlatforms('/api/platform/getPlatforms'),
        fetchBrowsers('/api/browser/getBrowsers'),
        fetchSchemes('/api/scheme/getSchemes'),
      ]);
    });

    return {
      TRAFFIC_TYPE_POP,
      TRAFFIC_TYPE_NATIVE,
      TRAFFIC_TYPES,
      TRAFFIC_CATEGORIES,
      REGIONS,
      BID_SUBJECTS,
      BID_TYPES,
      TARGETING_TYPE_DISABLED,
      TARGETING_TYPES,
      TARGETING_TYPES_BY_TRAFFIC_CATEGORY,
      TARGETING_TYPES_BY_TRAFFIC_SOURCE,
      TARGETING_TYPES_BY_PSEUDOSITES,
      PROTOCOL_LEGACY,
      PROTOCOLS,
      FEED_STATUSES,
      FEED_CUSTOMIZERS,
      FEED_CUSTOMIZERS_MAP,
      FEED_API_PROVIDERS_MAP,
      user,
      copyToClipboard,
      projectsLoading,
      projects,
      schemesLoading,
      schemes,
      qualityPresetsLoading,
      qualityPresets,
      managersLoading,
      managers,
      countriesLoading,
      countries,
      platformsLoading,
      platforms,
      browsersLoading,
      browsers,
      pseudositesLoading,
      pseudosites,
      endpointsLoading,
      endpoints,
      feed,
      feedErrorsMap,
      feedApiProvidersOptions,
      fetchFeed,
      storeFeed,
      cloneFeed,
      onTrafficTypeChange,
      onTrafficCategoryChange,
      onReferrerSpoofingChange,
      onProjectChange,
    };
  },
});
