import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_Entries = _resolveComponent("Entries")!

  return (_openBlock(), _createBlock(_component_Entries, {
    title: _ctx.$t('schemes'),
    "href-prefix": "schemes"
  }, {
    tables: _withCtx(() => [
      _createVNode(_component_EntriesDataTable, {
        entries: _ctx.schemes,
        "entries-handler": _ctx.fetchSchemes,
        "href-prefix": "schemes"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, {
            field: "id",
            header: "ID",
            sortable: ""
          }),
          _createVNode(_component_PrimeVueColumn, {
            field: "name",
            header: _ctx.$t('name'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(data.compiled_name), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "updated_at",
            header: _ctx.$t('updated_at'),
            sortable: ""
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["entries", "entries-handler"])
    ]),
    filters: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FieldText, {
          modelValue: _ctx.schemesFilters.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.schemesFilters.name) = $event)),
          modelModifiers: { trim: true },
          label: _ctx.$t('name')
        }, null, 8, ["modelValue", "label"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}