
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
} from 'vue';

import PrimeVueMessage from 'primevue/message';

import type {
  ErrorsMap,
  User,
} from '@/types';

import { PAYOUT_PERIODS } from '@/libs/consts';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    PrimeVueMessage,
    UserContactInformation: defineAsyncComponent(() => import('@/components/UserContactInformation.vue')),
    PublisherPayoutInformation: defineAsyncComponent(() => import('@/components/PublisherPayoutInformation.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const profile = reactive<User>(activeUser);

    const profileErrorsMap = ref<ErrorsMap>({});

    const storeProfile = async (): Promise<void> => {
      const response = await axios.post('/api/user/storeActiveUser', profile);

      Object.assign(profile, response.data);
    };

    const generatePassword = (): void => {
      profile.password = Math.random().toString(20).substr(2, 12);
    };

    return {
      PAYOUT_PERIODS,
      activeUser,
      profile,
      profileErrorsMap,
      storeProfile,
      generatePassword,
    };
  },
});
