import { useStore } from 'vuex';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export default () => {
  const store = useStore();

  return {
    activeUser: store.getters.user,
  };
};
