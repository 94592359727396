import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.creativeApprovalStatus === _ctx.CREATIVE_APPROVAL_STATUS_PENDING_APPROVAL || _ctx.creativeApprovalStatus === _ctx.CREATIVE_APPROVAL_STATUS_DECLINED)
      ? _withDirectives((_openBlock(), _createBlock(_component_PrimeVueButton, {
          key: 0,
          class: "p-button-rounded p-button-text p-button-success",
          icon: "pi pi-thumbs-up",
          loading: _ctx.changeApprovalStatusLoading,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeApprovalStatus(_ctx.CREATIVE_APPROVAL_STATUS_APPROVED)))
        }, null, 8, ["loading"])), [
          [_directive_tooltip, _ctx.$t('approve')]
        ])
      : _createCommentVNode("", true),
    (_ctx.creativeApprovalStatus === _ctx.CREATIVE_APPROVAL_STATUS_PENDING_APPROVAL || _ctx.creativeApprovalStatus === _ctx.CREATIVE_APPROVAL_STATUS_APPROVED)
      ? _withDirectives((_openBlock(), _createBlock(_component_PrimeVueButton, {
          key: 1,
          class: "p-button-rounded p-button-text p-button-danger",
          icon: "pi pi-thumbs-down",
          loading: _ctx.changeApprovalStatusLoading,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeApprovalStatus(_ctx.CREATIVE_APPROVAL_STATUS_DECLINED)))
        }, null, 8, ["loading"])), [
          [_directive_tooltip, _ctx.$t('decline')]
        ])
      : _createCommentVNode("", true)
  ], 64))
}