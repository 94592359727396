
import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  GenericObject,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Campaign: defineAsyncComponent(() => import('@/components/Campaign.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/Columns/ViewsColumn.vue')),
  },
  props: {
    statisticsFilters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/advertiser/getStatisticsByCampaigns');

    const fetchStatisticsWrapper = async (): Promise<void> => fetchStatistics({
      ...props.statisticsFilters,
      ...props.filters,
    });

    // TODO: Вынести в composable?
    const downloadTSV = (): void => {
      const headers = [
        'Campaign',
        'Views',
        'Clicks',
        'Conversions',
        'CPC',
        'CPM',
        'Spent',
      ].join('\t');

      const csv = statistics.data.map((data: GenericObject) => [
        data.campaign.compiled_name,
        data.views,
        data.clicks,
        data.conversions,
        data.cpc?.toLocaleString(),
        data.cpm?.toLocaleString(),
        data.spent?.toLocaleString(),
      ].join('\t')).join('\n');

      const blob = new Blob([`${headers}\n${csv}`], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.setAttribute('href', url);

      a.setAttribute('download', 'StatisticsByCampaigns.tsv');

      a.click();
    };

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
      downloadTSV,
    };
  },
});
