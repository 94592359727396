
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
  computed,
} from 'vue';

import PrimeVueMessage from 'primevue/message';

import type {
  EntriesPaginator,
  Payouts,
} from '@/types';

import {
  ENTRIES,
  PAYOUT_TYPES_MAP,
  PAYOUT_PERIOD_WEEKLY,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  components: {
    PrimeVueMessage,
    User: defineAsyncComponent(() => import('@/components/User.vue')),
    PayoutMethodColumn: defineAsyncComponent(() => import('@/components/PayoutMethodColumn.vue')),
  },
  props: {
    title_translate_key: {
      type: String,
      default: 'payouts',
    },
    type: {
      type: Number,
      default: null,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    showTerms: {
      type: Boolean,
      default: true,
    },
    showProjectColumn: {
      type: Boolean,
      default: true,
    },
    showUserColumn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: usersLoading,
      entries: users,
      fetchEntries: fetchUsers,
    } = useEntries();

    const payouts = ref<Payouts>(ENTRIES);

    const payoutsFilters = reactive({
      project_id: null,
      user_id: null,
    });

    const fetchPayouts = async (payoutsPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/payout/getPayoutsPaginator',
        {
          ...payoutsPaginator,
          ...payoutsFilters,
          type: props.type,
          ...props.filters,
        },
      );

      payouts.value = response.data as Payouts;
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers(
      '/api/user/getUsers',
      {
        project_id: activeUser.isSuperAdmin() ? payoutsFilters.project_id : null,
      },
    );

    const onProjectChange = (): void => {
      payoutsFilters.user_id = null;

      fetchUsersWrapper();
    };

    const payoutInfo = computed((): string => {
      let info = '';

      if (activeUser.payout_period === PAYOUT_PERIOD_WEEKLY) {
        info = 'payout_terms_weekly';
      } else {
        info = 'payout_terms';
      }

      return info;
    });

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
        !activeUser.isAffiliate() ? fetchUsersWrapper() : Promise.resolve(),
      ]);
    });

    return {
      PAYOUT_TYPES_MAP,
      activeUser,
      projectsLoading,
      projects,
      usersLoading,
      users,
      payouts,
      payoutsFilters,
      payoutInfo,
      fetchPayouts,
      onProjectChange,
    };
  },
});
