
import axios from 'axios';

import {
  defineComponent,
  computed,
  ref,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import {
  CAMPAIGN_STATUS_ENABLED,
  CAMPAIGN_STATUS_DISABLED,
} from '@/libs/consts';

export default defineComponent({
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
    campaignStatus: {
      type: Number,
      required: true,
    },
    asIcon: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const toast = useToast();

    const changeStatusLoading = ref<boolean>(false);

    const buttonClass = computed(() => ({
      'p-button-danger': props.campaignStatus === CAMPAIGN_STATUS_ENABLED,
      'p-button-success': props.campaignStatus === CAMPAIGN_STATUS_DISABLED,
    }));

    const buttonIcon = computed((): string|null => {
      if (props.campaignStatus === CAMPAIGN_STATUS_ENABLED) {
        return 'pi pi-pause';
      }

      if (props.campaignStatus === CAMPAIGN_STATUS_DISABLED) {
        return 'pi pi-play';
      }

      return null;
    });

    const iconClass = computed((): string|null => {
      if (changeStatusLoading.value) {
        return 'text-blue-600';
      }

      if (props.campaignStatus === CAMPAIGN_STATUS_ENABLED) {
        return 'text-pink-600';
      }

      if (props.campaignStatus === CAMPAIGN_STATUS_DISABLED) {
        return 'text-green-600';
      }

      return null;
    });

    const iconType = computed((): string|null => {
      if (changeStatusLoading.value) {
        return 'loading-three';
      }

      if (props.campaignStatus === CAMPAIGN_STATUS_ENABLED) {
        return 'pause-one';
      }

      if (props.campaignStatus === CAMPAIGN_STATUS_DISABLED) {
        return 'play';
      }

      return null;
    });

    const changeStatus = async (): Promise<void> => {
      if (changeStatusLoading.value) {
        return;
      }

      let status;

      if (props.campaignStatus === CAMPAIGN_STATUS_ENABLED) {
        status = CAMPAIGN_STATUS_DISABLED;
      }

      if (props.campaignStatus === CAMPAIGN_STATUS_DISABLED) {
        status = CAMPAIGN_STATUS_ENABLED;
      }

      changeStatusLoading.value = true;

      try {
        await axios.post(
          '/api/campaign/changeStatusById',
          {
            id: props.campaignId,
            status,
          },
        );

        emit('update:campaignStatus', status);

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_campaign_status_change'),
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      changeStatusLoading.value = false;
    };

    return {
      buttonClass,
      buttonIcon,
      iconClass,
      iconType,
      changeStatusLoading,
      changeStatus,
    };
  },
});
