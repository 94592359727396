import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Suspense as _Suspense, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "text-bluegray-300 ml-2" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_5 = { class: "p-fluid" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "mb-3 text-xs text-center" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "mb-3 text-xs text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FiltersSidebar = _resolveComponent("FiltersSidebar")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_Feed = _resolveComponent("Feed")!
  const _component_Chartist = _resolveComponent("Chartist")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: `👩‍💻 ${_ctx.$t('feeds_activity')}`,
      "show-refresh-button": true,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper,
      "refresh-button-loading": _ctx.statisticsLoading,
      "show-filters-button": true,
      "show-filters": _ctx.showStatisticsFilters,
      "onUpdate:show-filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showStatisticsFilters) = $event)),
      loading: _ctx.statisticsLoading
    }, {
      title: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, [
          _createTextVNode("👨‍💻 " + _toDisplayString(_ctx.$t('feeds_activity')), 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('for_last')) + " " + _toDisplayString(_ctx.statisticsFilters.interval_hours) + " " + _toDisplayString(_ctx.$t('hours_abbr')), 1)
        ])
      ]),
      _: 1
    }, 8, ["title", "refresh-button-handler", "refresh-button-loading", "show-filters", "loading"]),
    _createVNode(_component_FiltersSidebar, {
      visible: _ctx.showStatisticsFilters,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showStatisticsFilters) = $event)),
      "apply-button-handler": _ctx.fetchStatisticsWrapper,
      "apply-button-loading": _ctx.statisticsLoading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FieldDropdown, {
            modelValue: _ctx.statisticsFilters.interval_hours,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statisticsFilters.interval_hours) = $event)),
            label: _ctx.$t('hours_interval'),
            "show-clear": true,
            options: _ctx.intervalHoursOptions,
            "option-label": "label",
            "option-label-translate": false
          }, null, 8, ["modelValue", "label", "options"])
        ]),
        (_ctx.activeUser.isSuperAdmin())
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_FieldDropdown, {
                modelValue: _ctx.statisticsFilters.project_id,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.statisticsFilters.project_id) = $event)),
                  _ctx.onProjectChange
                ],
                label: _ctx.$t('project'),
                filter: true,
                loading: _ctx.projectsLoading,
                options: _ctx.projects.data,
                "option-value": "id",
                "option-label": "name",
                "option-label-translate": false,
                multiple: true
              }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_FieldDropdown, {
            modelValue: _ctx.statisticsFilters.feed_id,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.statisticsFilters.feed_id) = $event)),
            label: _ctx.$t('feed'),
            filter: true,
            loading: _ctx.feedsLoading,
            options: _ctx.feeds.data,
            "option-value": "id",
            "option-label": "compiled_name",
            "option-label-translate": false,
            multiple: true,
            "multiple-sort-by-value": true
          }, null, 8, ["modelValue", "label", "loading", "options"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "apply-button-handler", "apply-button-loading"]),
    (_ctx.statisticsLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (index) => {
          return _createVNode(_component_PrimeVueSkeleton, {
            class: "mb-3",
            key: index,
            height: "25px"
          })
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.statistics.data, (entry) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "card mb-3 pb-0",
            key: entry.feed.id
          }, [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _createVNode(_component_Feed, {
                  class: "mb-3",
                  feed: entry.feed
                }, {
                  "title-append": _withCtx(() => [
                    _createElementVNode("a", {
                      href: `/statistics/staff?filters=${_ctx.encodeStatisticsFilters({ feed_id: [entry.feed.id] })}`,
                      target: "_blank"
                    }, [
                      _withDirectives(_createVNode(_component_IconPark, {
                        class: "ml-1 text-blue-600 cursor-pointer",
                        type: "chart-histogram-two",
                        theme: "outline",
                        size: "14"
                      }, null, 512), [
                        [
                          _directive_tooltip,
                          _ctx.$t('statistics'),
                          void 0,
                          { right: true }
                        ]
                      ])
                    ], 8, _hoisted_6)
                  ]),
                  _: 2
                }, 1032, ["feed"])
              ]),
              fallback: _withCtx(() => [
                _createVNode(_component_PrimeVueSkeleton, {
                  class: "mb-3",
                  height: "50px"
                })
              ]),
              _: 2
            }, 1024)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('requests')), 1),
                      _createVNode(_component_Chartist, {
                        class: "activity-chart activity-chart-clicks",
                        "axis-y-label-interpolation-function": _ctx.axisYLabelInterpolationFunction,
                        entries: entry.statistics,
                        "entry-key": "feed_request_count"
                      }, null, 8, ["axis-y-label-interpolation-function", "entries"])
                    ])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 2
                }, 1024))
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(), _createBlock(_Suspense, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('clicks')), 1),
                      _createVNode(_component_Chartist, {
                        class: "activity-chart activity-chart-clicks",
                        "axis-y-label-interpolation-function": _ctx.axisYLabelInterpolationFunction,
                        entries: entry.statistics,
                        "entry-key": "click_count"
                      }, null, 8, ["axis-y-label-interpolation-function", "entries"])
                    ])
                  ]),
                  fallback: _withCtx(() => [
                    _createVNode(_component_PrimeVueSkeleton, {
                      class: "mb-3",
                      height: "120px"
                    })
                  ]),
                  _: 2
                }, 1024))
              ])
            ])
          ]))
        }), 128))
  ], 64))
}