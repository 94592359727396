
import axios from 'axios';

import {
  defineComponent,
  ref,
  onMounted,
} from 'vue';

export default defineComponent({
  setup() {
    const balancesSum = ref<number>(0);

    const balancesSumLoading = ref<boolean>(false);

    const getBalancesSum = async (): Promise<void> => {
      balancesSumLoading.value = true;

      const response = await axios.post('/api/widget/getBalancesSum');

      balancesSum.value = response.data.sum;

      balancesSumLoading.value = false;
    };

    onMounted(() => getBalancesSum());

    return {
      balancesSum,
      balancesSumLoading,
    };
  },
});
