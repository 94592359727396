import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberWithPercent = _resolveComponent("NumberWithPercent")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$n(_ctx.data.clicks)), 1),
    (_ctx.showExtraData)
      ? _withDirectives((_openBlock(), _createBlock(_component_NumberWithPercent, {
          key: "clicks_shaved",
          number: _ctx.data.clicks_shaved,
          "number-percent": _ctx.data.clicks_shaved_percent
        }, null, 8, ["number", "number-percent"])), [
          [
            _directive_tooltip,
            _ctx.$t('shave'),
            void 0,
            { left: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.showExtraData && _ctx.data.clicks_unique)
      ? _withDirectives((_openBlock(), _createBlock(_component_NumberWithPercent, {
          key: "clicks_unique",
          number: _ctx.data.clicks_unique,
          "number-percent": _ctx.data.clicks_unique_percent,
          "positive-context": true
        }, null, 8, ["number", "number-percent"])), [
          [
            _directive_tooltip,
            _ctx.$t('unique'),
            void 0,
            { left: true }
          ]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NumberWithPercent, {
        class: "mr-1",
        key: "clicks_filtered",
        number: _ctx.data.clicks_filtered,
        "number-percent": _ctx.data.clicks_filtered_percent
      }, null, 8, ["number", "number-percent"]),
      (_ctx.setSubStatisticsComponentFunction)
        ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
            key: 0,
            class: "cursor-pointer text-indigo-400",
            type: "shield",
            theme: "outline",
            size: _ctx.iconSize,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSubStatisticsComponentFunction('StatisticsByFilteredClicks', _ctx.setSubStatisticsComponentKey, _ctx.filters)))
          }, null, 8, ["size"])), [
            [
              _directive_tooltip,
              _ctx.$t('filtered_rtb_clicks'),
              void 0,
              { left: true }
            ]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}