import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-4" }
const _hoisted_3 = { class: "col-12 lg:col-4" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "col-12 lg:col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_DecodePayload = _resolveComponent("DecodePayload")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_SubidsIDsToSubidsUUIDs = _resolveComponent("SubidsIDsToSubidsUUIDs")!
  const _component_SubidsUUIDsToSubidsIDs = _resolveComponent("SubidsUUIDsToSubidsIDs")!
  const _component_UsersEmailsToUsersIDs = _resolveComponent("UsersEmailsToUsersIDs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: `${_ctx.$t('tools')} 🔧`
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            _createVNode(_component_DecodePayload)
          ]),
          fallback: _withCtx(() => [
            _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
          ]),
          _: 1
        }))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(_component_SubidsIDsToSubidsUUIDs)
            ]),
            fallback: _withCtx(() => [
              _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
            ]),
            _: 1
          }))
        ]),
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              _createVNode(_component_SubidsUUIDsToSubidsIDs)
            ]),
            fallback: _withCtx(() => [
              _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
            ]),
            _: 1
          }))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            _createVNode(_component_UsersEmailsToUsersIDs)
          ]),
          fallback: _withCtx(() => [
            _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
          ]),
          _: 1
        }))
      ])
    ])
  ], 64))
}