import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, Suspense as _Suspense, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsTitle = _resolveComponent("StatisticsTitle")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_Referrer = _resolveComponent("Referrer")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_ClicksColumn = _resolveComponent("ClicksColumn")!
  const _component_ImpressionsColumn = _resolveComponent("ImpressionsColumn")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createVNode(_component_StatisticsTitle, {
          title: _ctx.$t('referrers'),
          filters: _ctx.filters
        }, null, 8, ["title", "filters"])
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EntriesDataTableNext, {
        class: "p-datatable-sm statistics-table text-xs",
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        lazy: true,
        "data-key": "referrer_uuid",
        "default-sort-by": "publishers_payout",
        "show-gridlines": true
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('main'),
                colspan: 3
              }, null, 8, ["header"]),
              (_ctx.showFeedsSection)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 0,
                    header: _ctx.$t('feeds'),
                    colspan: 5
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              (_ctx.showCampaignsSection)
                ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                    key: 1,
                    header: _ctx.$t('campaigns'),
                    colspan: 8
                  }, null, 8, ["header"]))
                : _createCommentVNode("", true),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                rowspan: 2,
                sortable: "",
                field: "publishers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                rowspan: 2,
                sortable: "",
                field: "publishers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('feeds_plus_advertisers_plus_conversions_payout'),
                rowspan: 2,
                sortable: "",
                field: "feeds_plus_advertisers_plus_conversions_payout_estimated"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('profit'),
                rowspan: 2,
                sortable: "",
                field: "profit_estimated"
              }, null, 8, ["header"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('referrer'),
                sortable: "",
                field: "referrer_uuid"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('rtb_clicks'),
                sortable: "",
                field: "clicks"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('direct_clicks'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              (_ctx.showFeedsSection)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_payout'),
                      sortable: "",
                      field: "publishers_payout_by_feeds"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_cpc'),
                      sortable: "",
                      field: "publishers_cpc_by_feeds"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('feeds_payout'),
                      sortable: "",
                      field: "feeds_payout_estimated"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('feeds_cpc'),
                      sortable: "",
                      field: "feeds_cpc"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('profit'),
                      sortable: "",
                      field: "profit_by_feeds_estimated"
                    }, null, 8, ["header"])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.showCampaignsSection)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_payout'),
                      sortable: "",
                      field: "publishers_payout_by_campaigns"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('publishers_cpc'),
                      sortable: "",
                      field: "publishers_cpc_by_campaigns"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('advertisers_payout'),
                      sortable: "",
                      field: "advertisers_payout"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('advertisers_cpc'),
                      sortable: "",
                      field: "advertisers_cpc"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('conversions_short'),
                      sortable: "",
                      field: "conversions"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('conversions_payout'),
                      sortable: "",
                      field: "conversions_payout"
                    }, null, 8, ["header"]),
                    _createVNode(_component_PrimeVueColumn, {
                      header: "CTC",
                      sortable: "",
                      field: "advertisers_ctc"
                    }),
                    _createVNode(_component_PrimeVueColumn, {
                      header: _ctx.$t('profit'),
                      sortable: "",
                      field: "profit_by_campaigns"
                    }, null, 8, ["header"])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Referrer, {
                    "referrer-uuid": data.referrer_uuid,
                    referrer: data.referrer
                  }, null, 8, ["referrer-uuid", "referrer"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "50px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ClicksColumn, {
                    data: data,
                    "show-extra-data": _ctx.showExtraData
                  }, null, 8, ["data", "show-extra-data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ImpressionsColumn, {
                    data: data,
                    "show-extra-data": _ctx.showExtraData
                  }, null, 8, ["data", "show-extra-data"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          (_ctx.showFeedsSection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout_by_feeds, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc_by_feeds, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$n(data.feeds_payout_estimated, 'payout')) + " ℮", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.feeds_cpc, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$n(data.profit_by_feeds_estimated, 'payout')) + " ℮", 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.showCampaignsSection)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout_by_campaigns, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc_by_campaigns, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$n(data.advertisers_payout, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_cpc, 'bid')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.conversions)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.conversions_payout, 'payout')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.$n(data.advertisers_ctc, 'percent_with_fraction')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PrimeVueColumn, null, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$n(data.profit_by_campaigns, 'payout')), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.feeds_plus_advertisers_plus_conversions_payout_estimated, 'payout' )) + " ℮", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$n(data.profit_estimated, 'payout')) + " ℮", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler"])
    ])
  ], 64))
}