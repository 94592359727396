import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!

  return (_openBlock(), _createBlock(_component_FieldTextarea, {
    modelValue: _ctx.ids,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ids) = $event)),
    modelModifiers: { trim: true },
    label: _ctx.label,
    help: _ctx.help
  }, null, 8, ["modelValue", "label", "help"]))
}