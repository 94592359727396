import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_5 = {
  key: 1,
  class: "p-fluid"
}
const _hoisted_6 = {
  key: 2,
  class: "p-fluid"
}
const _hoisted_7 = {
  key: 3,
  class: "p-fluid"
}
const _hoisted_8 = {
  key: 4,
  class: "p-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t(_ctx.titleTranslateKey)), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FieldDropdown, {
        modelValue: _ctx.entry.payout_period,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entry.payout_period) = $event)),
        modelModifiers: { number: true },
        name: "payout_period",
        "errors-map": _ctx.errorsMap,
        disabled: _ctx.activeUser.isAffiliate(),
        label: _ctx.$t('period'),
        options: _ctx.PAYOUT_PERIODS,
        "option-label": "label",
        "option-label-translate": false
      }, null, 8, ["modelValue", "errors-map", "disabled", "label", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FieldDropdown, {
        modelValue: _ctx.entry.payout_method,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entry.payout_method) = $event)),
        modelModifiers: { number: true },
        name: "payout_method",
        "errors-map": _ctx.errorsMap,
        label: _ctx.$t('method'),
        disabled: _ctx.disabled,
        "show-clear": true,
        options: _ctx.PAYOUT_METHODS,
        "option-label": "label",
        "option-label-translate": false
      }, null, 8, ["modelValue", "errors-map", "label", "disabled", "options"])
    ]),
    (_ctx.isCrypto)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_FieldText, {
            modelValue: _ctx.entry.payout_method_crypto_wallet_address,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entry.payout_method_crypto_wallet_address) = $event)),
            modelModifiers: { trim: true },
            name: "payout_method_crypto_wallet_address",
            "errors-map": _ctx.errorsMap,
            label: _ctx.$t('wallet_address'),
            disabled: _ctx.disabled,
            "input-group": true
          }, {
            append: _withCtx(() => [
              _withDirectives(_createVNode(_component_PrimeVueButton, {
                class: "p-button-secondary",
                icon: "pi pi-copy",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyToClipboard(_ctx.entry.payout_method_crypto_wallet_address)))
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.$t('copy'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "errors-map", "label", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.entry.payout_method === _ctx.PAYOUT_METHOD_PAYONEER)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_FieldText, {
            modelValue: _ctx.entry.payout_method_payoneer_email,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entry.payout_method_payoneer_email) = $event)),
            modelModifiers: { trim: true },
            name: "payout_method_payoneer_email",
            "errors-map": _ctx.errorsMap,
            label: _ctx.$t('email'),
            disabled: _ctx.disabled,
            "input-group": true
          }, {
            append: _withCtx(() => [
              _withDirectives(_createVNode(_component_PrimeVueButton, {
                class: "p-button-secondary",
                icon: "pi pi-copy",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.copyToClipboard(_ctx.entry.payout_method_payoneer_email)))
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.$t('copy'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "errors-map", "label", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.entry.payout_method === _ctx.PAYOUT_METHOD_PAYPAL)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_FieldText, {
            modelValue: _ctx.entry.payout_method_paypal_email,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.entry.payout_method_paypal_email) = $event)),
            modelModifiers: { trim: true },
            name: "payout_method_paypal_email",
            "errors-map": _ctx.errorsMap,
            label: _ctx.$t('email'),
            disabled: _ctx.disabled,
            "input-group": true
          }, {
            append: _withCtx(() => [
              _withDirectives(_createVNode(_component_PrimeVueButton, {
                class: "p-button-secondary",
                icon: "pi pi-copy",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyToClipboard(_ctx.entry.payout_method_paypal_email)))
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.$t('copy'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "errors-map", "label", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.entry.payout_method === _ctx.PAYOUT_METHOD_WIRE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_FieldTextarea, {
            modelValue: _ctx.entry.payout_method_wire,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.entry.payout_method_wire) = $event)),
            modelModifiers: { trim: true },
            name: "payout_method_wire",
            "errors-map": _ctx.errorsMap,
            label: "Wire",
            disabled: _ctx.disabled
          }, null, 8, ["modelValue", "errors-map", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.entry.payout_method === _ctx.PAYOUT_METHOD_WEBMONEY)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_FieldText, {
            modelValue: _ctx.entry.payout_method_webmoney_wallet,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.entry.payout_method_webmoney_wallet) = $event)),
            modelModifiers: { trim: true },
            name: "payout_method_webmoney_wallet",
            "errors-map": _ctx.errorsMap,
            label: _ctx.$t('wallet_address'),
            disabled: _ctx.disabled,
            "input-group": true
          }, {
            append: _withCtx(() => [
              _withDirectives(_createVNode(_component_PrimeVueButton, {
                class: "p-button-secondary",
                icon: "pi pi-copy",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.copyToClipboard(_ctx.entry.payout_method_webmoney_wallet)))
              }, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.$t('copy'),
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "errors-map", "label", "disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}