
import isNumber from 'lodash/isNumber';

import isEmpty from 'lodash/isEmpty';

import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import type { GenericObject } from '@/types';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
  },
  setup() {
    return {
      isNumber,
      isEmpty,
    };
  },
});
