
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import PrimeVueListBox from 'primevue/listbox';

import {
  ErrorsMap,
  Option,
} from '@/types';

export default defineComponent({
  components: {
    PrimeVueListBox,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    label: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionValue: {
      type: String,
      required: true,
    },
    optionLabel: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line arrow-body-style
    const sortedOptions = computed((): Option[] => {
      return props.options
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((option: any) => ({
          value: option[props.optionValue],
          label: option[props.optionLabel],
        }))
        .sort((a, b) => {
          const aSelected = Number(props.modelValue.includes(a.value));

          const bSelected = Number(props.modelValue.includes(b.value));

          return bSelected - aSelected || a.label.localeCompare(b.label);
        });
    });

    return {
      sortedOptions,
    };
  },
});
