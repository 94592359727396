import axios from 'axios';

import type { UnwrapNestedRefs } from 'vue';

import {
  ref,
  reactive,
} from 'vue';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export default <T>(url: string, stub?: any) => {
  const entriesLoading = ref<boolean>(false);

  const entriesFetched = ref<boolean>(false);

  // eslint-disable-next-line no-nested-ternary
  const entries = reactive(stub ? (Array.isArray(stub) ? [...stub] : { ...stub }) : {}) as UnwrapNestedRefs<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchEntries = async (data?: any): Promise<void> => {
    entriesLoading.value = true;

    const response = await axios.post(url, data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.assign(entries as any, response.data);

    entriesLoading.value = false;

    entriesFetched.value = true;
  };

  return {
    entries,
    entriesLoading,
    entriesFetched,
    fetchEntries,
  };
};
