import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card operations" }
const _hoisted_2 = { class: "grid align-items-center" }
const _hoisted_3 = { class: "col-11" }
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "p-fluid" }
const _hoisted_9 = { class: "col-1 text-center" }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldNumber = _resolveComponent("FieldNumber")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('shave_by_countries')), 1),
    (_ctx.shaveRules.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.shaveRules, (shaveRule, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "insights",
            key: index
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_FieldDropdown, {
                        class: "mb-0",
                        modelValue: shaveRule.country_id,
                        "onUpdate:modelValue": ($event: any) => ((shaveRule.country_id) = $event),
                        modelModifiers: { number: true },
                        label: _ctx.$t('country'),
                        loading: _ctx.countriesLoading,
                        "show-clear": true,
                        filter: true,
                        options: _ctx.countries.data,
                        "option-value": "id",
                        "option-label": "compiled_name",
                        "option-label-translate": false
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "loading", "options"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_FieldNumber, {
                        class: "mb-0",
                        modelValue: shaveRule.shave,
                        "onUpdate:modelValue": ($event: any) => ((shaveRule.shave) = $event),
                        modelModifiers: { number: true },
                        label: _ctx.$t('shave'),
                        min: 0,
                        max: 100,
                        step: 5,
                        "show-buttons": "",
                        "button-layout": "horizontal",
                        "increment-button-icon": "pi pi-plus",
                        "decrement-button-icon": "pi pi-minus"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createVNode(_component_IconPark, {
                  class: "mt-3 cursor-pointer text-pink-600",
                  type: "delete",
                  theme: "outline",
                  size: "16",
                  onClick: ($event: any) => (_ctx.deleteShaveRule(index))
                }, null, 8, ["onClick"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('delete'),
                    void 0,
                    { right: true }
                  ]
                ])
              ])
            ])
          ]))
        }), 128))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('nothing')), 1)),
    _createVNode(_component_PrimeVueButton, {
      class: "p-button-outlined p-button-success mt-3 w-full",
      icon: "pi pi-plus",
      onClick: _ctx.addShaveRule
    }, null, 8, ["onClick"])
  ]))
}