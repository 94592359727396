import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a02b87ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  key: 0,
  class: "block mb-2"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueTextarea = _resolveComponent("PrimeVueTextarea")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_PrimeVueTextarea, {
      "model-value": _ctx.modelValue,
      rows: _ctx.rows,
      disabled: _ctx.disabled,
      class: _normalizeClass({ 'p-invalid': _ctx.invalid }),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, null, 8, ["model-value", "rows", "disabled", "class"]),
    (_ctx.maxLength)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: _normalizeClass(["block mt-1", { 'text-pink-600': _ctx.textLength > _ctx.maxLength }])
        }, _toDisplayString(_ctx.textLength) + " / " + _toDisplayString(_ctx.maxLength), 3))
      : _createCommentVNode("", true),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 2,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true),
    (_ctx.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 3,
          class: "block mt-1",
          innerHTML: _ctx.help
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}