
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import type { GenericObject } from '@/types';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<GenericObject[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const creatives = computed({
      get: () => props.modelValue,
      set: (value: GenericObject[]) => {
        emit('update:modelValue', value.filter((creative: GenericObject) => !!creative.creative_id));
      },
    });

    const addCreative = (): void => {
      creatives.value.push({
        creative_id: null,
        weight: 100,
      });
    };

    const deleteCreative = (index: number): void => {
      creatives.value.splice(index, 1);
    };

    return {
      creatives,
      addCreative,
      deleteCreative,
    };
  },
});
