import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsTitle = _resolveComponent("StatisticsTitle")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_NumberWithPercent = _resolveComponent("NumberWithPercent")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      "show-refresh-button": _ctx.statisticsFetched,
      "refresh-button-loading": _ctx.statisticsLoading,
      "refresh-button-handler": _ctx.fetchStatisticsWrapper
    }, {
      title: _withCtx(() => [
        _createVNode(_component_StatisticsTitle, {
          title: _ctx.$t('filtered_direct_clicks'),
          filters: _ctx.filters
        }, null, 8, ["title", "filters"])
      ]),
      _: 1
    }, 8, ["show-refresh-button", "refresh-button-loading", "refresh-button-handler"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EntriesDataTableNext, {
        class: "text-sm statistics-table",
        entries: _ctx.statistics,
        "entries-loading": _ctx.statisticsLoading,
        "entries-fetched": _ctx.statisticsFetched,
        "entries-handler": _ctx.fetchStatisticsWrapper,
        paginator: false
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filtered')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_not_checked')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_has_no_js')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_proxy')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_iframe')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_headless')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_double_click')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_ip_mismatch')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_user_agent_mismatch')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_referrer_mismatch')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_timezone_mismatch')
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('filter_click_without_impression')
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.impressions)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered",
                number: data.impressions_filtered,
                "number-percent": data.impressions_filtered_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_not_checked",
                number: data.impressions_filtered_by_not_checked,
                "number-percent": data.impressions_filtered_by_not_checked_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_has_no_js",
                number: data.impressions_filtered_by_has_no_js,
                "number-percent": data.impressions_filtered_by_has_no_js_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_proxy",
                number: data.impressions_filtered_by_proxy,
                "number-percent": data.impressions_filtered_by_proxy_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_iframe",
                number: data.impressions_filtered_by_iframe,
                "number-percent": data.impressions_filtered_by_iframe_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_headless",
                number: data.impressions_filtered_by_headless,
                "number-percent": data.impressions_filtered_by_headless_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_double_click",
                number: data.impressions_filtered_by_double_click,
                "number-percent": data.impressions_filtered_by_double_click_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_ip_mismatch",
                number: data.impressions_filtered_by_ip_mismatch,
                "number-percent": data.impressions_filtered_by_ip_mismatch_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_user_agent_mismatch",
                number: data.impressions_filtered_by_user_agent_mismatch,
                "number-percent": data.impressions_filtered_by_user_agent_mismatch_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_referrer_mismatch",
                number: data.impressions_filtered_by_referrer_mismatch,
                "number-percent": data.impressions_filtered_by_referrer_mismatch_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_timezone_mismatch",
                number: data.impressions_filtered_by_timezone_mismatch,
                "number-percent": data.impressions_filtered_by_timezone_mismatch_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_NumberWithPercent, {
                class: "mr-1",
                key: "impressions_filtered_by_click_without_impression",
                number: data.impressions_filtered_by_click_without_impression,
                "number-percent": data.impressions_filtered_by_click_without_impression_percent
              }, null, 8, ["number", "number-percent"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler"])
    ])
  ], 64))
}