
import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  GenericObject,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

import StatisticsBy from '@/components/Statistics/Staff/StatisticsBy.vue';

export default defineComponent({
  extends: StatisticsBy,
  components: {
    // Компоненты колонок
    EfficiencyColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/EfficiencyColumn.vue')),
    RequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/RequestsColumn.vue')),
    FeedsRequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/FeedsRequestsColumn.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ViewsColumn.vue')),
    PayableViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/PayableViewsColumn.vue')),
    ClicksColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ClicksColumn.vue')),
    ImpressionsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ImpressionsColumn.vue')),
    AdvertisersROIColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/AdvertisersROIColumn.vue')),
    CTRColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/CTRColumn.vue')),
    // Компоненты статистики
    Actions: defineAsyncComponent(() => import('@/components/Statistics/Staff/Actions.vue')),
    StatisticsByPublishers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByPublishers.vue')),
    StatisticsByEndpoints: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByEndpoints.vue')),
    StatisticsBySubids: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsBySubids.vue')),
    StatisticsByReferrers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByReferrers.vue')),
    StatisticsByCountries: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByCountries.vue')),
    StatisticsByPlatforms: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByPlatforms.vue')),
    StatisticsByBrowsers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByBrowsers.vue')),
    StatisticsByAdvertisers: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByAdvertisers.vue')),
    StatisticsByCampaigns: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByCampaigns.vue')),
    StatisticsByCreatives: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByCreatives.vue')),
    StatisticsByFeeds: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFeeds.vue')),
    StatisticsByFilteredClicks: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredClicks.vue')),
    StatisticsByFilteredImpressions: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredImpressions.vue')),
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsByHours');

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics({
        ...props.statisticsFilters,
        ...props.filters,
      });

      resetSubStatisticComponent();
    };

    const getStatisticByHourRowClass = (row: GenericObject): string => (row.hour === subStatisticsComponent.filters.hour ? 'statistics-table-row-active' : '');

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
      subStatisticsComponent,
      setSubStatisticsComponent,
      getStatisticByHourRowClass,
    };
  },
});
