import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex align-items-center justify-content-between" }
const _hoisted_2 = {
  key: 0,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.showRefreshButton)
        ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
            key: 0,
            class: "p-button-outlined p-button-primary ml-3",
            icon: "pi pi-sync",
            loading: _ctx.refreshButtonLoading,
            onClick: _ctx.refreshButtonHandler,
            small: ""
          }, null, 8, ["loading", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.showFiltersButton)
        ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
            key: 1,
            class: "p-button-outlined p-button-secondary ml-3",
            icon: "pi pi-filter",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:showFilters', !_ctx.showFilters)))
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "buttons:end")
    ])
  ]))
}