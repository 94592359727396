
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    number: {
      type: Number,
      required: true,
    },
    numberFormat: {
      type: String,
    },
    disableThreshold: {
      type: Boolean,
      default: false,
    },
    thresholdWarning: {
      type: Number,
      default: 0.3,
    },
    thresholdDanger: {
      type: Number,
      default: 0.75,
    },
    negativeContext: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getClassName = (): string => {
      if (props.disableThreshold) {
        return 'text-blue-400';
      }

      if (!props.negativeContext && props.number <= props.thresholdDanger) {
        return 'text-pink-400';
      }

      if (!props.negativeContext && props.number <= props.thresholdWarning) {
        return 'text-orange-400';
      }

      if (props.negativeContext && props.number >= props.thresholdDanger) {
        return 'text-pink-400';
      }

      if (props.negativeContext && props.number >= props.thresholdWarning) {
        return 'text-orange-400';
      }

      return 'text-blue-400';
    };

    return {
      getClassName,
    };
  },
});
