
import {
  defineComponent,
  onMounted,
} from 'vue';

import { GenericObject } from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  setup() {
    const {
      entriesLoading: statisticsLoading,
      entries: statistics,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<GenericObject[]>('/api/widget/getStatisticsByNewSubids', []);

    onMounted(() => fetchStatistics());

    return {
      statisticsLoading,
      statistics,
    };
  },
});
