
import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  GenericObject,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

export default defineComponent({
  components: {
    Actions: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/Actions.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/Columns/ViewsColumn.vue')),
    StatisticsByCampaigns: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsByCampaigns.vue')),
    StatisticsByCreatives: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsByCreatives.vue')),
    StatisticsByEndpoints: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsByZones.vue')),
    StatisticsBySubids: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsBySubids.vue')),
    StatisticsByCountries: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsByCountries.vue')),
    StatisticsByPlatforms: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsByPlatforms.vue')),
    StatisticsByBrowsers: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/StatisticsByBrowsers.vue')),
  },
  props: {
    statisticsFilters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/advertiser/getStatisticsByHours');

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const getStatisticByHourRowClass = (row: GenericObject): string => (row.hour === subStatisticsComponent.filters.hour ? 'statistics-table-row-active' : '');

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics({
        ...props.statisticsFilters,
        ...props.filters,
      });

      resetSubStatisticComponent();
    };

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
      subStatisticsComponent,
      setSubStatisticsComponent,
      getStatisticByHourRowClass,
    };
  },
});
