
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import type { ErrorsMap } from '@/types';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    entry: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
    entryErrorsMap: {
      type: Object as PropType<ErrorsMap>,
      required: true,
    },
    entryFetchHandler: {
      type: Function as PropType<() => void | Promise<void>>,
    },
    entryStoreHandler: {
      type: Function as PropType<() => void | Promise<void>>,
    },
    entryDeleteHandler: {
      type: Function as PropType<() => void | Promise<void>>,
    },
    hrefPrefix: {
      type: String,
    },
    onEntryStoreRedirectToEntries: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const i18n = useI18n();

    const toast = useToast();

    const entryWasNew = !props.entry.id;

    const entryFetched = ref<boolean>(!props.entryFetchHandler); // Если нет обработчика получения записи, то считаем, что запись получена

    const entryErrorsMap = ref<ErrorsMap>({ ...props.entryErrorsMap });

    const entryStoreLoading = ref<boolean>(false);

    const entryDeleteConfirmation = ref<boolean>(false);

    const entryDeleteLoading = ref<boolean>(false);

    const fetchEntry = async (): Promise<void> => {
      if (!props.entryFetchHandler) {
        return;
      }

      try {
        await props.entryFetchHandler();

        entryFetched.value = true;
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }
    };

    const storeEntry = async (): Promise<void> => {
      if (!props.entryStoreHandler) {
        return;
      }

      entryStoreLoading.value = true;

      entryErrorsMap.value = {};

      try {
        await props.entryStoreHandler();

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_store'),
          life: 5000,
        });

        if (props.onEntryStoreRedirectToEntries && entryWasNew && props.hrefPrefix) {
          await router.push(`/${props.hrefPrefix}`);
        }
      } catch (error: unknown | AxiosError) {
        // В случае ошибки валидации API возвращает ответ с кодом 422
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          entryErrorsMap.value = error.response.data;

          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('validation_error'),
            life: 5000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('unknown_error'),
            life: 5000,
          });
        }
      }

      entryStoreLoading.value = false;

      emit('update:entryErrorsMap', entryErrorsMap.value);
    };

    const deleteEntry = async (): Promise<void> => {
      if (!props.entryDeleteHandler) {
        return;
      }

      if (!entryDeleteConfirmation.value) {
        entryDeleteConfirmation.value = true;

        return;
      }

      entryDeleteLoading.value = true;

      try {
        await props.entryDeleteHandler();

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: i18n.t('success_delete'),
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      entryDeleteLoading.value = false;
    };

    onMounted(fetchEntry);

    return {
      entryFetched,
      entryStoreLoading,
      entryDeleteConfirmation,
      entryDeleteLoading,
      storeEntry,
      deleteEntry,
    };
  },
});
