
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  ErrorsMap,
  QualityPreset,
} from '@/types';

import { QUALITY_PRESET_COMPARATORS } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  setup() {
    const { activeUser } = useUser();

    const { getEntryByRoute } = useEntries();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const qualityPreset = reactive<QualityPreset>({
      ...Object.fromEntries(QUALITY_PRESET_COMPARATORS.map((attribute: string) => [attribute, null])),
    } as unknown as QualityPreset);

    const qualityPresetErrorsMap = ref<ErrorsMap>({});

    const fetchQualityPreset = async (): Promise<void> => {
      const entry = await getEntryByRoute('/api/quality-preset/getQualityPresetById');

      if (entry) {
        Object.assign(qualityPreset, entry);
      }
    };

    const storeQualityPreset = async (): Promise<void> => {
      const response = await axios.post('/api/quality-preset/storeQualityPreset', qualityPreset);

      Object.assign(qualityPreset, response.data);
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
      ]);
    });

    return {
      QUALITY_PRESET_COMPARATORS,
      activeUser,
      projectsLoading,
      projects,
      qualityPreset,
      qualityPresetErrorsMap,
      fetchQualityPreset,
      storeQualityPreset,
    };
  },
});
