
import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  EntriesPaginator,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import StatisticsBy from '@/components/Statistics/Staff/StatisticsBy.vue';

export default defineComponent({
  extends: StatisticsBy,
  components: {
    // Компоненты колонок
    ClicksColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ClicksColumn.vue')),
    ImpressionsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ImpressionsColumn.vue')),
    AdvertisersROIColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/AdvertisersROIColumn.vue')),
    Referrer: defineAsyncComponent(() => import('@/components/Referrer.vue')),
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsByReferrers');

    const fetchStatisticsWrapper = async (referrersPaginator: EntriesPaginator): Promise<void> => fetchStatistics({
      ...props.statisticsFilters,
      ...props.filters,
      ...referrersPaginator,
    });

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
    };
  },
});
