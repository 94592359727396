
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  EntriesPaginator,
  QualityPresets,
} from '@/types';

import { ENTRIES } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  setup() {
    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const qualityPresets = ref<QualityPresets>(ENTRIES);

    const qualityPresetsFilters = reactive({
      project_id: null,
      name: null,
    });

    const fetchQualityPresets = async (qualityPresetsPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/quality-preset/getQualityPresetsPaginator',
        {
          ...qualityPresetsPaginator,
          ...qualityPresetsFilters,
        },
      );

      qualityPresets.value = response.data as QualityPresets;
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
      ]);
    });

    return {
      activeUser,
      projectsLoading,
      projects,
      qualityPresets,
      qualityPresetsFilters,
      fetchQualityPresets,
    };
  },
});
