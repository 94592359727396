
import objectHash from 'object-hash';

import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import type { GenericObject } from '@/types';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<GenericObject>,
      requried: true,
    },
    setSubStatisticsComponentFunction: {
      type: Function,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      default: () => ({}),
    },
    showExtraData: {
      type: Boolean,
      required: true,
    },
    iconSize: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const setSubStatisticsComponentKey = computed((): string => objectHash.MD5(props.filters));

    return {
      setSubStatisticsComponentKey,
    };
  },
});
