import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldCheckbox = _resolveComponent("FieldCheckbox")!
  const _component_FieldTextarea = _resolveComponent("FieldTextarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('ui_settings')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FieldCheckbox, {
        modelValue: _ctx.user.ui_publisher_direct,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.ui_publisher_direct) = $event)),
        label: _ctx.$t('ui_publisher_direct')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_FieldCheckbox, {
        modelValue: _ctx.user.ui_publisher_rtb,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.ui_publisher_rtb) = $event)),
        label: _ctx.$t('ui_publisher_rtb')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_FieldCheckbox, {
        modelValue: _ctx.user.ui_advertiser,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.ui_advertiser) = $event)),
        label: _ctx.$t('ui_advertiser')
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_FieldCheckbox, {
        modelValue: _ctx.user.ui_referrer,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.ui_referrer) = $event)),
        label: _ctx.$t('ui_referrer')
      }, null, 8, ["modelValue", "label"]),
      (_ctx.user.ui_advertiser)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_FieldCheckbox, {
              modelValue: _ctx.user.ui_contact_notification,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.ui_contact_notification) = $event)),
              label: _ctx.$t('ui_contact_notification')
            }, null, 8, ["modelValue", "label"]),
            (_ctx.user.ui_contact_notification)
              ? (_openBlock(), _createBlock(_component_FieldTextarea, {
                  key: 0,
                  modelValue: _ctx.user.ui_contact_notification_via,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.ui_contact_notification_via) = $event)),
                  modelModifiers: { trim: true },
                  label: _ctx.$t('ui_contact_notification_via')
                }, null, 8, ["modelValue", "label"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}