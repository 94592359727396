import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hideFeedsRequestsColumn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "—"))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$n(_ctx.data.feeds_requests)), 1))
  ]))
}