
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import type {
  User,
  Payout,
} from '@/types';

import {
  PAYOUT_METHOD_CRYPTO_BITCON,
  PAYOUT_METHOD_CRYPTO_USDT_ERC20,
  PAYOUT_METHOD_CRYPTO_USDT_TRC20,
  PAYOUT_METHOD_CRYPTO_USDC,
  PAYOUT_METHOD_PAYONEER,
  PAYOUT_METHOD_PAYPAL,
  PAYOUT_METHOD_WIRE,
  PAYOUT_METHOD_WEBMONEY,
  PAYOUT_METHODS_MAP,
} from '@/libs/consts';

export default defineComponent({
  props: {
    entry: {
      type: Object as PropType<User | Payout>,
      required: true,
    },
  },
  setup(props) {
    const isCrypto = computed((): boolean => props.entry.payout_method === PAYOUT_METHOD_CRYPTO_BITCON
      || props.entry.payout_method === PAYOUT_METHOD_CRYPTO_USDT_ERC20
      || props.entry.payout_method === PAYOUT_METHOD_CRYPTO_USDT_TRC20
      || props.entry.payout_method === PAYOUT_METHOD_CRYPTO_USDC);

    return {
      PAYOUT_METHOD_PAYONEER,
      PAYOUT_METHOD_PAYPAL,
      PAYOUT_METHOD_WIRE,
      PAYOUT_METHOD_WEBMONEY,
      PAYOUT_METHODS_MAP,
      isCrypto,
    };
  },
});
