
import { defineComponent } from 'vue';

import { useRoute } from 'vue-router';

import Layout from '@/components/Layout.vue';

export default defineComponent({
  components: {
    Layout,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
