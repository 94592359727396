
import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import type {
  GenericObject,
  Statistics,
} from '@/types';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Endpoint: defineAsyncComponent(() => import('@/components/Endpoint.vue')),
  },
  props: {
    statisticsFilters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
  },
  setup(props) {
    const { activeUser: user } = useUser();

    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/publisher/direct/getStatisticsByEndpoints');

    const fetchStatisticsWrapper = async (): Promise<void> => fetchStatistics({
      ...props.statisticsFilters,
      ...props.filters,
    });

    return {
      user,
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
    };
  },
});
