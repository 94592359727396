import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_PrimeVueListBox = _resolveComponent("PrimeVueListBox")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_PrimeVueSkeleton, {
            class: "mb-2",
            height: "20px"
          }),
          _createVNode(_component_PrimeVueSkeleton, {
            class: "mb-2",
            height: "20px"
          })
        ], 64))
      : (_openBlock(), _createBlock(_component_PrimeVueListBox, {
          key: 2,
          "model-value": _ctx.modelValue,
          options: _ctx.sortedOptions,
          "option-value": "value",
          "option-label": "label",
          multiple: true,
          filter: _ctx.filter,
          "filter-placeholder": _ctx.$t('search'),
          "list-style": "max-height: 250px;",
          class: _normalizeClass({ 'p-invalid': _ctx.invalid }),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
        }, null, 8, ["model-value", "options", "filter", "filter-placeholder", "class"])),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 3,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}