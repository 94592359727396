import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueCalendar = _resolveComponent("PrimeVueCalendar")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PrimeVueCalendar, {
        "model-value": _ctx.value,
        "date-format": "yy-mm-dd",
        "show-time": _ctx.showTime,
        "show-button-bar": true,
        disabled: _ctx.disabled,
        "onUpdate:modelValue": _ctx.onUpdateValue
      }, null, 8, ["model-value", "show-time", "disabled", "onUpdate:modelValue"])
    ]),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 0,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}