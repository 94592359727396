
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';

import { useStore } from 'vuex';

import type {
  GenericObject,
  Payment,
  Payments,
} from '@/types';

import { ENTRIES } from '@/libs/consts';

import useEntriesNext from '@/composable/useEntries@next';

import useUser from '@/composable/useUser';

export default defineComponent({
  components: {
    User: defineAsyncComponent(() => import('@/components/User.vue')),
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    userBalance: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const { activeUser } = useUser();

    const {
      entries: payments,
      entriesLoading: paymentsLoading,
      fetchEntries: fetchPayments,
    } = useEntriesNext<Payments>('/api/payment/getPaymentsByUserId', ENTRIES);

    const fetchPaymentsWrapper = async (): Promise<void> => fetchPayments({
      user_id: props.userId,
    });

    const form = reactive<GenericObject>({
      user_id: props.userId,
      amount: 0,
      comment: null,
    });

    const addPaymentLoading = ref<boolean>(false);

    const addPayment = async (): Promise<void> => {
      addPaymentLoading.value = true;

      const response = await axios.post('/api/payment/addPayment', form);

      const payment = response.data as Payment;

      payments.data.unshift(payment);

      const newUserBalance = props.userBalance + payment.amount;

      emit('update:user-balance', newUserBalance);

      if (props.userId === activeUser.id) {
        store.commit('SET_USER_BALANCE', newUserBalance);
      }

      form.amount = 0;

      form.comment = null;

      addPaymentLoading.value = false;
    };

    onMounted((): void => {
      Promise.all([
        fetchPaymentsWrapper(),
      ]);
    });

    return {
      payments,
      paymentsLoading,
      form,
      addPaymentLoading,
      addPayment,
    };
  },
});
