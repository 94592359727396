
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import type { Referrer } from '@/types';

export default defineComponent({
  props: {
    referrerUuid: {
      type: String,
      required: true,
    },
    referrer: {
      type: Object as PropType<Referrer>,
    },
  },
});
