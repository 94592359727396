
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
  ref,
} from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: true,
    },
    hideSchedule: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const days = [
      'M',
      'T',
      'W',
      'T',
      'F',
      'S',
      'S',
    ];

    const schedule = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const getKey = (day: number, hour: number): string => `${day}:${hour}`;

    const select = (day: number, hour: number): void => {
      const key = getKey(day, hour);

      const index = schedule.value.indexOf(key);

      if (index === -1) {
        schedule.value.push(key);
      } else {
        schedule.value.splice(index, 1);
      }
    };

    const selectDaysUndo = ref<boolean>(false);

    const selectDays = (day: number): void => {
      const keys: string[] = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= 23; i++) {
        keys.push(getKey(day, i));
      }

      if (selectDaysUndo.value) {
        schedule.value = schedule.value.filter((value: string) => !keys.includes(value));
      } else {
        schedule.value.push(...keys);

        schedule.value = [...new Set(schedule.value)];
      }

      selectDaysUndo.value = !selectDaysUndo.value;
    };

    const selectHoursUndo = ref<boolean>(false);

    const selectHours = (hour: number): void => {
      const keys: string[] = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= 7; i++) {
        keys.push(getKey(i, hour));
      }

      if (selectHoursUndo.value) {
        schedule.value = schedule.value.filter((value: string) => !keys.includes(value));
      } else {
        schedule.value.push(...keys);

        schedule.value = [...new Set(schedule.value)];
      }

      selectHoursUndo.value = !selectHoursUndo.value;
    };

    const inSchedule = (day: number, hour: number): boolean => {
      const key = getKey(day, hour);

      return schedule.value.indexOf(key) !== -1;
    };

    return {
      days,
      schedule,
      select,
      selectDays,
      selectHours,
      inSchedule,
    };
  },
});
