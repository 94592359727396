
import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import type {
  Entry,
  Entries,
  EntriesPaginator,
  EntriesPaginatorEvent,
} from '@/types';

import emitter from '@/libs/emitter';

import {
  ENTRIES_PER_PAGE,
  ENTRIES_SORT_BY_ID,
  ENTRIES_SORT_BY_DIRECTION_ASC,
  ENTRIES_SORT_BY_DIRECTION_DESC,
} from '@/libs/consts';

export default defineComponent({
  props: {
    defaultSortBy: {
      type: String,
      default: ENTRIES_SORT_BY_ID,
    },
    defaultSortByDirection: {
      type: String,
      default: ENTRIES_SORT_BY_DIRECTION_DESC,
    },
    entries: {
      type: Object as PropType<Entries>,
      required: true,
    },
    entriesHandler: {
      type: Function as PropType<(entriesPaginator?: EntriesPaginator) => void | Promise<void>>,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    paginator: {
      type: Boolean,
      default: true,
    },
    showActionsColumn: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    expandedRows: {
      type: Array,
      default: null,
    },
    rowClass: {
      type: Function as PropType<(entry: Entry) => string>,
    },
    hrefPrefix: {
      type: String,
    },
    scrollHeight: {
      type: String,
    },
    sortField: {
      type: String,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const toast = useToast();

    const entriesLoading = ref<boolean>(false);

    const entriesPaginator = ref<EntriesPaginator>({
      entries_per_page: ENTRIES_PER_PAGE,
      page: 1,
      sort_by: props.defaultSortBy,
      sort_by_direction: props.defaultSortByDirection,
    } as EntriesPaginator);

    const fetchEntries = async (): Promise<void> => {
      entriesLoading.value = true;

      try {
        if (props.lazy) {
          await props.entriesHandler(entriesPaginator.value);
        } else {
          await props.entriesHandler();
        }
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      entriesLoading.value = false;
    };

    const onPaginatorChange = async (event: EntriesPaginatorEvent): Promise<void> => {
      entriesPaginator.value.page = event.first / ENTRIES_PER_PAGE + 1;

      if (event.sortField) {
        entriesPaginator.value.sort_by = event.sortField;

        entriesPaginator.value.sort_by_direction = event.sortOrder === -1 ? ENTRIES_SORT_BY_DIRECTION_DESC : ENTRIES_SORT_BY_DIRECTION_ASC;
      }

      await fetchEntries();
    };

    const entriesPaginatorEvents = {
      page: props.lazy ? onPaginatorChange : undefined,
      sort: props.lazy ? onPaginatorChange : undefined,
    };

    onMounted(() => {
      fetchEntries();

      emitter.on('fetchEntries', fetchEntries);
    });

    onUnmounted(() => {
      emitter.off('fetchEntries');
    });

    watch(entriesLoading, (value: boolean) => emitter.emit('entriesLoading', value));

    return {
      ENTRIES_PER_PAGE,
      ENTRIES_SORT_BY_DIRECTION_DESC,
      entriesLoading,
      entriesPaginatorEvents,
    };
  },
});
