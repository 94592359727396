import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center" }
const _hoisted_2 = { class: "mr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$n(_ctx.number, _ctx.numberFormat)), 1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.className)
    }, _toDisplayString(_ctx.$n(_ctx.numberPercent, 'percent')), 3)
  ]))
}