import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { role: "menu" }
const _hoisted_2 = { class: "layout-menuitem-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_PrimeVueTag = _resolveComponent("PrimeVueTag")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (!item.hide)
          ? (_openBlock(), _createElementBlock("li", {
              class: "layout-root-menuitem",
              key: index,
              role: "menuitem"
            }, [
              _createVNode(_component_router_link, {
                to: item.to,
                "active-class": "active-route",
                exact: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconPark, {
                    class: "mx-0",
                    type: item.icon,
                    theme: "outline",
                    size: "24"
                  }, null, 8, ["type"]),
                  _createElementVNode("span", _hoisted_2, [
                    _createTextVNode(_toDisplayString(_ctx.$t(item.translate_key)), 1),
                    (item.tagValue)
                      ? (_openBlock(), _createBlock(_component_PrimeVueTag, {
                          key: 0,
                          class: "ml-2",
                          value: item.tagValue,
                          severity: item.tagSeverity
                        }, null, 8, ["value", "severity"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}