import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })),
      _createVNode(_component_Toast)
    ]),
    _createElementVNode("link", {
      rel: "stylesheet",
      type: "text/css",
      href: `/assets/css/layout/layout-${_ctx.theme}.css`
    }, null, 8, _hoisted_1),
    _createElementVNode("link", {
      rel: "stylesheet",
      type: "text/css",
      href: `/assets/css/theme/${_ctx.themeColor}/theme-${_ctx.theme}.css`
    }, null, 8, _hoisted_2)
  ], 64))
}