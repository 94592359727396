
import axios from 'axios';

import {
  defineComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';

import PrimeVueToast from 'primevue/toast';

import { useToast } from 'primevue/usetoast';

import {
  FeedCreative,
  FeedsCreatives,
  EntriesPaginator,
  Projects,
  Countries,
  Feeds,
} from '@/types';

import { ENTRIES } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    PrimeVueToast,
  },
  setup() {
    const toast = useToast();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: feeds,
      entriesLoading: feedsLoading,
      fetchEntries: fetchFeeds,
    } = useEntriesNext<Feeds>('/api/feed/getFeeds');

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries');

    const feedsCreatives = ref<FeedsCreatives>({ ...ENTRIES });

    const feedsCreativesFilters = reactive({
      project_id: null,
      feed_id: null,
      country_id: null,
      search_text: null,
    });

    const fetchFeedsCreatives = async (feedsCreativesPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        'api/feed-creative/getFeedsCreativesPaginator',
        {
          ...feedsCreativesPaginator,
          ...feedsCreativesFilters,
        },
      );

      feedsCreatives.value = response.data;
    };

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds({
      project_id: user.isSuperAdmin() ? feedsCreativesFilters.project_id : undefined,
    });

    const onProjectChange = (): void => {
      feedsCreativesFilters.feed_id = null;

      fetchFeedsWrapper();
    };

    const previewFeedCreative = (feedCreative: FeedCreative): void => {
      toast.removeAllGroups();

      toast.add({
        severity: 'info',
        styleClass: 'p-toast-push',
        detail: feedCreative,
        group: 'bc',
      });
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        fetchCountries(),
        fetchFeeds(),
      ]);
    });

    return {
      user,
      feedsCreatives,
      feedsCreativesFilters,
      projects,
      projectsLoading,
      feeds,
      feedsLoading,
      countries,
      countriesLoading,
      fetchFeedsCreatives,
      onProjectChange,
      previewFeedCreative,
    };
  },
});
