
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import { useRouter } from 'vue-router';

import type {
  EntriesPaginator,
  Endpoint,
  Endpoints,
} from '@/types';

import {
  TRAFFIC_TYPES,
  TRAFFIC_TYPES_MAP,
  TRAFFIC_CATEGORIES,
  TRAFFIC_CATEGORIES_MAP,
  REGIONS,
  REGIONS_MAP,
  PROTOCOLS_MAP,
  ENTRIES,
  PROJECT_STATUSES,
  USER_TYPE_AFFILIATE,
  ENDPOINT_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    EndpointMeta: defineAsyncComponent(() => import('@/components/EndpointMeta.vue')),
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
    SiteColumn: defineAsyncComponent(() => import('@/components/SiteColumn.vue')),
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    showProjectColumn: {
      type: Boolean,
      default: true,
    },
    showUserColumn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: usersLoading,
      entries: users,
      fetchEntries: fetchUsers,
    } = useEntries();

    const { encodeStatisticsFilters } = useStatisticsLink();

    const endpoints = ref<Endpoints>(ENTRIES);

    const endpointsExpandedRows = ref([]);

    const endpointsFilters = reactive({
      project_id: null,
      user_id: null,
      status: null,
      uid: null,
      uuid: null,
      traffic_type: null,
      traffic_category: null,
      region: null,
    });

    const fetchEndpoints = async (endpointsPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/endpoint/getEndpointsPaginator',
        {
          ...endpointsPaginator,
          ...endpointsFilters,
          ...props.filters,
        },
      );

      endpoints.value = response.data as Endpoints;
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers(
      '/api/user/getUsers',
      {
        project_id: activeUser.isSuperAdmin() ? endpointsFilters.project_id : null,
        type: USER_TYPE_AFFILIATE,
      },
    );

    const onProjectChange = (): void => {
      endpointsFilters.user_id = null;

      fetchUsersWrapper();
    };

    const addButtonHandler = async (): Promise<void> => {
      await router.push('/endpoints/add');
    };

    const editButtonHandler = async (endpoint: Endpoint): Promise<void> => {
      await router.push(`/endpoints/${endpoint.id}`);
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
        activeUser.isAffiliate() ? Promise.resolve() : fetchUsersWrapper(),
      ]);
    });

    return {
      TRAFFIC_TYPES,
      TRAFFIC_TYPES_MAP,
      TRAFFIC_CATEGORIES,
      TRAFFIC_CATEGORIES_MAP,
      REGIONS,
      REGIONS_MAP,
      PROTOCOLS_MAP,
      PROJECT_STATUSES,
      ENDPOINT_STATUSES_MAP,
      activeUser,
      projectsLoading,
      projects,
      usersLoading,
      users,
      encodeStatisticsFilters,
      endpoints,
      endpointsExpandedRows,
      endpointsFilters,
      fetchEndpoints,
      onProjectChange,
      addButtonHandler: activeUser.isAffiliate() ? null : addButtonHandler,
      editButtonHandler,
    };
  },
});
