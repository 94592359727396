
import { defineComponent } from 'vue';

import PrimeVueSidebar from 'primevue/sidebar';

export default defineComponent({
  components: {
    PrimeVueSidebar,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    applyButtonHandler: {
      type: Function,
      required: true,
    },
    applyButtonLoading: {
      type: Boolean,
      default: false,
    },
  },
});
