
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import type { GenericObject } from '@/types';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<GenericObject>,
      requried: true,
    },
    hideFeedsRequestsColumn: {
      type: Boolean,
      required: true,
    },
  },
});
