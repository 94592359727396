
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  ref,
  onMounted,
} from 'vue';

import type {
  EntriesPaginator,
  User,
  Users,
} from '@/types';

import {
  ENTRIES,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPES,
  USER_TYPES_MAP,
  USER_ROLES,
  USER_STATUSES,
  USER_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useClipboard from '@/composable/useClipboard';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  components: {
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const { copyToClipboard } = useClipboard();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const {
      entriesLoading: managersLoading,
      entries: managers,
      fetchEntries: fetchManagers,
    } = useEntries();

    const users = ref<Users>(ENTRIES);

    const usersFilters = reactive({
      id: null,
      project_id: null,
      email: null,
      status: null,
      alias: null,
      type: null,
      role: null,
      manager_id: null,
    });

    const fetchUsers = async (usersPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/user/getUsersPaginator',
        {
          ...usersPaginator,
          ...usersFilters,
        },
      );

      users.value = response.data as Users;
    };

    const fetchManagersWrapper = async (): Promise<void> => fetchManagers(
      '/api/user/getUsers',
      {
        project_id: activeUser.isSuperAdmin() ? usersFilters.project_id : undefined,
        type: [
          USER_TYPE_ADMIN,
          USER_TYPE_MANAGER,
        ],
      },
    );

    const onProjectChange = async (): Promise<void> => {
      usersFilters.manager_id = null;

      fetchManagersWrapper();
    };

    const getLoginURL = async (user: User): Promise<void> => {
      const response = await axios.post(
        '/api/user/getTokenByUserId',
        {
          user_id: user.id,
        },
      );

      const { token } = response.data;

      if (!user.project) {
        console.warn('user.project is undefined');

        return;
      }

      const url = `https://${user.project.host}/login?token=${token}`;

      await copyToClipboard(url);
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
        fetchManagersWrapper(),
      ]);
    });

    return {
      USER_TYPES,
      USER_TYPES_MAP,
      USER_ROLES,
      USER_STATUSES,
      USER_STATUSES_MAP,
      activeUser,
      projectsLoading,
      projects,
      managersLoading,
      managers,
      users,
      usersFilters,
      fetchUsers,
      onProjectChange,
      getLoginURL,
    };
  },
});
