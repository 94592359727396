
import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import { useRoute } from 'vue-router';

import type {
  EntriesPaginator,
  Projects,
  Users,
  Sites,
} from '@/types';

import {
  TRAFFIC_CATEGORIES,
  TRAFFIC_CATEGORIES_MAP,
  ENTRIES,
  USER_TYPE_AFFILIATE,
  SITE_APPROVAL_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
  },
  setup() {
    const route = useRoute();

    const { activeUser: user } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers');

    const { decodeStatisticsFilters } = useStatisticsLink();

    const sites = ref<Sites>({ ...ENTRIES });

    const queryFilters = {};

    if (route.query.filters) {
      Object.assign(queryFilters, decodeStatisticsFilters(route.query.filters as string));
    }

    const sitesFilters = reactive({
      project_id: null,
      user_id: null,
      traffic_category: null,
      host: null,
      ...queryFilters,
    });

    const fetchSites = async (sitesPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/site/getSitesPaginator',
        {
          ...sitesPaginator,
          ...sitesFilters,
        },
      );

      sites.value = response.data;
    };

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: user.isSuperAdmin() ? sitesFilters.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const onProjectChange = (): void => {
      sitesFilters.user_id = null;

      fetchUsersWrapper();
    };

    onMounted((): void => {
      Promise.all([
        user.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        user.isStaff() ? fetchUsersWrapper() : Promise.resolve(),
      ]);
    });

    return {
      TRAFFIC_CATEGORIES,
      TRAFFIC_CATEGORIES_MAP,
      SITE_APPROVAL_STATUSES_MAP,
      user,
      projectsLoading,
      projects,
      usersLoading,
      users,
      sites,
      sitesFilters,
      fetchSites,
      onProjectChange,
    };
  },
});
