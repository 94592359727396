
import {
  defineComponent,
  onMounted,
} from 'vue';

import {
  useRoute,
  useRouter,
} from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();

    const router = useRouter();

    onMounted(async () => {
      if (route.params.slug) {
        window.localStorage.setItem('link_slug', route.params.slug.toString());
      }

      await router.push('/register');
    });
  },
});
