import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "grid align-items-center justify-content-between mb-4" }
const _hoisted_3 = { class: "p-2" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PrimeVueSidebar = _resolveComponent("PrimeVueSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.showAddButton)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: `/${_ctx.hrefPrefix}/add`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PrimeVueButton, {
                    class: "p-button-rounded p-button-outlined p-button-primary",
                    icon: "pi pi-plus"
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueButton, {
            class: "p-button-rounded p-button-outlined p-button-success ml-3 sm:ml-4",
            icon: "pi pi-sync",
            disabled: _ctx.entriesLoading,
            loading: _ctx.entriesLoading,
            onClick: _ctx.fetchEntries
          }, null, 8, ["disabled", "loading", "onClick"]),
          (_ctx.showFiltersButton)
            ? (_openBlock(), _createBlock(_component_PrimeVueButton, {
                key: 1,
                class: "p-button-rounded p-button-outlined p-button-info ml-3 sm:ml-4",
                icon: "pi pi-filter",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFilters = true))
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "buttons:append", { entriesLoading: _ctx.entriesLoading })
        ])
      ]),
      _renderSlot(_ctx.$slots, "tables")
    ]),
    _createVNode(_component_PrimeVueSidebar, {
      visible: _ctx.showFilters,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showFilters) = $event)),
      position: "right",
      dismissable: true
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "filters"),
        _createVNode(_component_PrimeVueButton, {
          class: "mt-3 w-full",
          label: _ctx.$t('apply'),
          onClick: _ctx.fetchEntries
        }, null, 8, ["label", "onClick"])
      ]),
      _: 3
    }, 8, ["visible"])
  ], 64))
}