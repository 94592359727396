
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import {
  defineComponent,
  reactive,
  computed,
  onMounted,
} from 'vue';

import { useStore } from 'vuex';

import {
  useRouter,
  useRoute,
} from 'vue-router';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import PrimeVueInputText from 'primevue/inputtext';

import PrimeVuePassword from 'primevue/password';

import type {
  Project,
} from '@/types';

import {
  THEME_LIGHT,
  PROJECT_ID_ADBISON,
} from '@/libs/consts';

export default defineComponent({
  components: {
    PrimeVueInputText,
    PrimeVuePassword,
  },
  setup() {
    const store = useStore();

    const router = useRouter();

    const route = useRoute();

    const i18n = useI18n();

    const toast = useToast();

    store.commit('SET_THEME', THEME_LIGHT);

    const form = reactive({
      email: '',
      password: '',
    });

    const formIsValid = computed((): boolean => form.email.length !== 0 && form.password.length !== 0);

    const login = async (): Promise<void> => {
      try {
        const response = await axios.post('api/user/login', form);

        const { token } = response.data;

        store.commit('SET_TOKEN', token);

        await router.push('/');
      } catch (error: unknown | Error | AxiosError) {
        // В случае ошибки валидации API возвращает ответ с кодом 422
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          toast.add({
            severity: 'error',
            summary: i18n.t('login_error'),
            detail: i18n.t('invalid_email_or_password'),
            life: 5000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('unknown_error'),
            life: 5000,
          });
        }
      }
    };

    onMounted(async () => {
      // Авторизация под пользователем используя token переданный в адресной строке

      if (route.query.token) {
        store.commit('SET_TOKEN', route.query.token);

        await router.push('/');
      }
    });

    return {
      PROJECT_ID_ADBISON,
      project: computed((): Project => store.getters.project),
      form,
      formIsValid,
      login,
    };
  },
});
