
import {
  defineComponent,
  computed,
} from 'vue';

import type { Postbacks } from '@/types';

import {
  ENTRIES,
  POSTBACK_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

import useClipboard from '@/composable/useClipboard';

export default defineComponent({
  setup() {
    const { activeUser: user } = useUser();

    const {
      entries: postbacks,
      entriesLoading: postbacksLoading,
      fetchEntries: fetchPostbacks,
    } = useEntriesNext<Postbacks>('/api/postback/getPostbacksPaginator', ENTRIES);

    const { copyToClipboard } = useClipboard();

    const postbackUrl = computed((): string => `https://api.${user.project.host}/api/postback?click_uuid=EXTERNAL_ID&payout=PAYOUT`);

    return {
      POSTBACK_STATUSES_MAP,
      user,
      postbacks,
      postbacksLoading,
      fetchPostbacks,
      copyToClipboard,
      postbackUrl,
    };
  },
});
