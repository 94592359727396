
import {
  defineComponent,
  ref,
} from 'vue';

export default defineComponent({
  props: {
    number: {
      type: Number,
      required: true,
    },
    numberFormat: {
      type: String,
    },
    numberPercent: {
      type: Number,
      required: true,
    },
    positiveContext: {
      type: Boolean,
      default: false,
    },
    disableThreshold: {
      type: Boolean,
      default: false,
    },
    numberPercentWarningThreshold: {
      type: Number,
      default: 0.3,
    },
    numberPercentDangerThreshold: {
      type: Number,
      default: 0.75,
    },
  },
  setup(props) {
    const numberPercent = ref<number>(props.numberPercent);

    /**
     * @todo Функция некорректно работает с отрицательными числами
     */
    const getClassName = (): string => {
      if (props.disableThreshold) {
        return 'text-blue-400';
      }

      if (props.positiveContext) {
        numberPercent.value = 1 - numberPercent.value;
      }

      if (numberPercent.value >= props.numberPercentDangerThreshold) {
        return 'text-pink-400';
      }

      if (numberPercent.value >= props.numberPercentWarningThreshold) {
        return 'text-orange-400';
      }

      return 'text-blue-400';
    };

    return {
      className: getClassName(),
    };
  },
});
