
import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
} from 'vue';

import type {
  GenericObject,
  EntriesPaginator,
  QualityPresets,
  Statistics,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Subid: defineAsyncComponent(() => import('@/components/Subid.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Advertiser/Columns/ViewsColumn.vue')),
  },
  props: {
    statisticsFilters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
    filters: {
      type: Object as PropType<GenericObject>,
      required: true,
    },
  },
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/publisher/rtb/getStatisticsBySubids');

    const {
      entries: qualityPresets,
      entriesFetched: qualityPresetsFetched,
      fetchEntries: fetchQualityPresets,
    } = useEntriesNext<QualityPresets>('/api/quality-preset/getQualityPresets');

    const fetchStatisticsWrapper = async (subidsPaginator: EntriesPaginator): Promise<void> => fetchStatistics({
      ...props.statisticsFilters,
      ...props.filters,
      ...subidsPaginator,
    });

    onMounted((): Promise<void> => fetchQualityPresets());

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      qualityPresets,
      qualityPresetsFetched,
      fetchStatisticsWrapper,
    };
  },
});
