import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "login-body" }
const _hoisted_2 = { class: "login-wrapper" }
const _hoisted_3 = { class: "login-panel text-left" }
const _hoisted_4 = {
  key: 0,
  class: "mb-8 text-center"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "p-fluid mb-3"
}
const _hoisted_8 = { class: "login-footer flex-column align-items-center sm:flex-row" }
const _hoisted_9 = { class: "mr-0 mb-2 sm:mb-0 sm:mr-5" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_PrimeVueInputText = _resolveComponent("PrimeVueInputText")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!
  const _component_PrimeVuePassword = _resolveComponent("PrimeVuePassword")!
  const _component_FieldCheckbox = _resolveComponent("FieldCheckbox")!
  const _component_VueRecaptcha = _resolveComponent("VueRecaptcha")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.project.logo_url_black)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                href: `https://${_ctx.project.site_host}`
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(["w-full", _ctx.project.logo_class]),
                  src: _ctx.project.logo_url_black
                }, null, 10, _hoisted_6)
              ], 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.project.preferred_user_role)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_FieldDropdownNext, {
                modelValue: _ctx.form.role,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.role) = $event)),
                options: _ctx.USER_ROLES,
                "option-label-translate": ""
              }, null, 8, ["modelValue", "options"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_PrimeVueInputText, {
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('email')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "email"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVuePassword, {
          modelValue: _ctx.form.password,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.password) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('password')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "password"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVuePassword, {
          modelValue: _ctx.form.repeat_password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.repeat_password) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('repeat_password'),
          feedback: false
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "repeat_password"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVueInputText, {
          modelValue: _ctx.form.firstname,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.firstname) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('firstname')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "firstname"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVueInputText, {
          modelValue: _ctx.form.lastname,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.lastname) = $event)),
          modelModifiers: { trim: true },
          placeholder: _ctx.$t('lastname')
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "lastname"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVueInputText, {
          modelValue: _ctx.form.telegram,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.telegram) = $event)),
          modelModifiers: { trim: true },
          placeholder: "Telegram"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "telegram"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVueInputText, {
          modelValue: _ctx.form.skype,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.skype) = $event)),
          modelModifiers: { trim: true },
          placeholder: "Skype"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "skype"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_FieldCheckbox, {
          modelValue: _ctx.accept,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.accept) = $event)),
          label: _ctx.$t('accept_terms', { 'terms_and_conditions_url': _ctx.project.terms_and_conditions_url, 'privacy_policy_url': _ctx.project.privacy_policy_url })
        }, null, 8, ["modelValue", "label"]),
        (_ctx.form.role === _ctx.USER_ROLE_ADVERTISER)
          ? (_openBlock(), _createBlock(_component_FieldCheckbox, {
              key: 2,
              modelValue: _ctx.acceptAdvertiser,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.acceptAdvertiser) = $event)),
              label: _ctx.$t('accept_terms_advertiser')
            }, null, 8, ["modelValue", "label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_VueRecaptcha, {
          class: "mb-2",
          sitekey: "6Lep_WofAAAAAEtzmGv56OSBxGwmbplA70XmKvZc",
          "load-recaptcha-script": true,
          onVerify: _ctx.recaptchaVerify
        }, null, 8, ["onVerify"]),
        _createVNode(_component_FieldErrors, {
          class: "mb-3",
          "errors-map": _ctx.errorsMap,
          name: "recaptcha_token"
        }, null, 8, ["errors-map"]),
        _createVNode(_component_PrimeVueButton, {
          label: _ctx.$t('sign_up'),
          disabled: !_ctx.accept || (_ctx.form.role === _ctx.USER_ROLE_ADVERTISER && !_ctx.acceptAdvertiser) || !_ctx.form.recaptcha_token.length,
          onClick: _ctx.register
        }, null, 8, ["label", "disabled", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.project.name), 1),
        (!_ctx.project.white_label)
          ? (_openBlock(), _createElementBlock("h6", _hoisted_10, "⚙️ " + _toDisplayString(_ctx.$t('powered_by')) + " AdBison Platform", 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}