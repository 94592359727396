
import {
  defineComponent,
  defineAsyncComponent,
  computed,
} from 'vue';

import { useRoute } from 'vue-router';

import { useStore } from 'vuex';

import Toast from 'primevue/toast';

export default defineComponent({
  components: {
    Toast,
    Dashboard: defineAsyncComponent(() => import('@/layouts/Dashboard.vue')),
    Empty: defineAsyncComponent(() => import('@/layouts/Empty.vue')),
  },

  setup() {
    const route = useRoute();

    const store = useStore();

    return {
      layout: computed((): string => route.meta.layout as string),
      theme: computed((): string => store.getters.theme),
      themeColor: computed((): string => store.getters.themeColor),
    };
  },
});
