import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
        key: 0,
        height: "12px"
      }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(_ctx.SUBID_STATUSES_MAP[_ctx.subid.status].cssClass)
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.SUBID_STATUSES_MAP[_ctx.subid.status].translate_key)), 1),
        (_ctx.subid.status !== _ctx.SUBID_STATUS_NEW && _ctx.user.isStaff())
          ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
              key: 0,
              class: _normalizeClass(["ml-2 cursor-pointer", _ctx.SUBID_STATUSES_MAP[_ctx.SUBID_STATUS_NEW].cssClass]),
              type: "baby",
              theme: "outline",
              size: "10",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeSubidStatus(_ctx.SUBID_STATUS_NEW)))
            }, null, 8, ["class"])), [
              [
                _directive_tooltip,
                _ctx.$t('change_subid_status_to_new'),
                void 0,
                { left: true }
              ]
            ])
          : _createCommentVNode("", true),
        ((_ctx.subid.status !== _ctx.SUBID_STATUS_AUTO_APPROVED) && _ctx.user.isStaff())
          ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
              key: 1,
              class: _normalizeClass(["ml-2 cursor-pointer", _ctx.SUBID_STATUSES_MAP[_ctx.SUBID_STATUS_AUTO_APPROVED].cssClass]),
              type: "thumbs-up",
              theme: "outline",
              size: "10",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeSubidStatus(_ctx.SUBID_STATUS_AUTO_APPROVED)))
            }, null, 8, ["class"])), [
              [
                _directive_tooltip,
                _ctx.$t('change_subid_status_to_approved'),
                void 0,
                { left: true }
              ]
            ])
          : _createCommentVNode("", true),
        (_ctx.subid.status !== _ctx.SUBID_STATUS_AUTO_DECLINED && _ctx.subid.status !== _ctx.SUBID_STATUS_DECLINED && _ctx.user.isStaff())
          ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
              key: 2,
              class: _normalizeClass(["ml-2 cursor-pointer", { 'text-yellow-400': !_ctx.declineConfirmation, 'text-pink-400': _ctx.declineConfirmation }]),
              type: "thumbs-down",
              theme: "outline",
              size: "10",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeSubidStatus(_ctx.SUBID_STATUS_DECLINED)))
            }, null, 8, ["class"])), [
              [
                _directive_tooltip,
                _ctx.$t('change_subid_status_to_declined'),
                void 0,
                { left: true }
              ]
            ])
          : _createCommentVNode("", true)
      ], 2))
}