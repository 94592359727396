
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  EntriesPaginator,
  Schemes,
} from '@/types';

import { ENTRIES } from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

export default defineComponent({
  setup() {
    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const schemes = ref<Schemes>(ENTRIES);

    const schemesFilters = reactive({
      name: null,
    });

    const fetchSchemes = async (schemesPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/scheme/getSchemesPaginator',
        {
          ...schemesPaginator,
          ...schemesFilters,
        },
      );

      schemes.value = response.data as Schemes;
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
      ]);
    });

    return {
      activeUser,
      projectsLoading,
      projects,
      schemes,
      schemesFilters,
      fetchSchemes,
    };
  },
});
