import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueInputNumber = _resolveComponent("PrimeVueInputNumber")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.wrapperClass)
    }, [
      _renderSlot(_ctx.$slots, "prepend"),
      _createVNode(_component_PrimeVueInputNumber, {
        "model-value": _ctx.modelValue,
        disabled: _ctx.disabled,
        mode: _ctx.mode,
        "use-grouping": _ctx.useGrouping,
        currency: _ctx.currency,
        min: _ctx.min,
        max: _ctx.max,
        "min-fraction-digits": _ctx.minFractionDigits,
        "max-fraction-digits": _ctx.maxFractionDigits,
        step: _ctx.step,
        "show-buttons": _ctx.showButtons,
        "button-layout": _ctx.buttonLayout,
        "increment-button-icon": _ctx.incrementButtonIcon,
        "decrement-button-icon": _ctx.decrementButtonIcon,
        prefix: _ctx.prefix,
        suffix: _ctx.suffix,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["model-value", "disabled", "mode", "use-grouping", "currency", "min", "max", "min-fraction-digits", "max-fraction-digits", "step", "show-buttons", "button-layout", "increment-button-icon", "decrement-button-icon", "prefix", "suffix"]),
      _renderSlot(_ctx.$slots, "append")
    ], 2),
    (_ctx.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: "block",
          innerHTML: _ctx.help
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 2,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}