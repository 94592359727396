
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import type {
  ErrorsMap,
  User,
  Payout,
} from '@/types';

import {
  PAYOUT_PERIODS,
  PAYOUT_METHOD_CRYPTO_BITCON,
  PAYOUT_METHOD_CRYPTO_USDT_ERC20,
  PAYOUT_METHOD_CRYPTO_USDT_TRC20,
  PAYOUT_METHOD_CRYPTO_USDC,
  PAYOUT_METHOD_PAYONEER,
  PAYOUT_METHOD_PAYPAL,
  PAYOUT_METHOD_WIRE,
  PAYOUT_METHOD_WEBMONEY,
  PAYOUT_METHODS,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useClipboard from '@/composable/useClipboard';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<User | Payout>,
      required: true,
    },
    titleTranslateKey: {
      type: String,
      default: 'publisher_payout_information',
    },
    errorsMap: {
      type: Object as PropType<ErrorsMap>,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { activeUser } = useUser();

    const { copyToClipboard } = useClipboard();

    const entry = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const isCrypto = computed((): boolean => entry.value.payout_method === PAYOUT_METHOD_CRYPTO_BITCON
      || entry.value.payout_method === PAYOUT_METHOD_CRYPTO_USDT_ERC20
      || entry.value.payout_method === PAYOUT_METHOD_CRYPTO_USDT_TRC20
      || entry.value.payout_method === PAYOUT_METHOD_CRYPTO_USDC);

    return {
      activeUser,
      PAYOUT_PERIODS,
      PAYOUT_METHOD_PAYONEER,
      PAYOUT_METHOD_PAYPAL,
      PAYOUT_METHOD_WIRE,
      PAYOUT_METHOD_WEBMONEY,
      PAYOUT_METHODS,
      copyToClipboard,
      entry,
      isCrypto,
    };
  },
});
