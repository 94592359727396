
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import numeral from 'numeral';

import type {
  GenericObject,
  Option,
  Projects,
  Feeds,
  Statistics,
} from '@/types';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    Feed: defineAsyncComponent(() => import('@/components/Feed.vue')),
    Chartist: defineAsyncComponent(() => import('@/components/Chartist.vue')),
  },
  setup() {
    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entriesLoading: feedsLoading,
      entries: feeds,
      fetchEntries: fetchFeeds,
    } = useEntriesNext<Feeds>('/api/feed/getFeeds');

    const {
      entriesLoading: statisticsLoading,
      entries: statistics,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/getStatisticsByFeedsActivity');

    const statisticsFilters = reactive<GenericObject>({
      interval_hours: 24,
      project_id: null,
      feed_id: null,
    });

    const showStatisticsFilters = ref<boolean>(false);

    const fetchFeedsWrapper = async (): Promise<void> => fetchFeeds({
      project_id: activeUser.isSuperAdmin() ? statisticsFilters.project_id : null,
    });

    const fetchStatisticsWrapper = async (): Promise<void> => fetchStatistics(statisticsFilters);

    const onProjectChange = (): void => {
      statisticsFilters.feed_id = null;

      fetchFeedsWrapper();
    };

    const intervalHoursOptions: Option[] = [3, 6, 12, 24, 48, 72, 168, 336].map((value: number) => {
      const labelMap: { [key: number]: string; } = {
        48: '2 days',
        72: '3 days',
        168: '1 week',
        336: '2 weeks',
      };

      return {
        value,
        label: `${labelMap[value] || value}`,
      };
    });

    const axisYLabelInterpolationFunction = (value: number): string => numeral(value).format('0a');

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        fetchFeedsWrapper(),
        fetchStatisticsWrapper(),
      ]);
    });

    return {
      activeUser,
      projectsLoading,
      projects,
      feedsLoading,
      feeds,
      statisticsLoading,
      statistics,
      fetchStatisticsWrapper,
      statisticsFilters,
      showStatisticsFilters,
      onProjectChange,
      intervalHoursOptions,
      axisYLabelInterpolationFunction,
    };
  },
});
