
import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import { useI18n } from 'vue-i18n';

import numeral from 'numeral';

import type {
  GenericObject,
  Option,
  Projects,
  Users,
  Endpoints,
  Statistics,
} from '@/types';

import {
  USER_TYPE_AFFILIATE,
  TRAFFIC_SOURCES,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    User: defineAsyncComponent(() => import('@/components/User.vue')),
    Endpoint: defineAsyncComponent(() => import('@/components/Endpoint.vue')),
    Chartist: defineAsyncComponent(() => import('@/components/Chartist.vue')),
  },
  setup() {
    const i18n = useI18n();

    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects');

    const {
      entriesLoading: usersLoading,
      entries: users,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers');

    const {
      entriesLoading: endpointsLoading,
      entries: endpoints,
      fetchEntries: fetchEndpoints,
    } = useEntriesNext<Endpoints>('/api/endpoint/getEndpoints');

    const {
      entriesLoading: statisticsLoading,
      entries: statistics,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/getStatisticsByEndpointsActivity');

    const statisticsFilters = reactive<GenericObject>({
      interval_hours: 24,
      project_id: null,
      traffic_source: null,
      user_id: null,
      endpoint_id: null,
    });

    const showStatisticsFilters = ref<boolean>(false);

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: activeUser.isSuperAdmin() ? statisticsFilters.project_id : null,
      type: USER_TYPE_AFFILIATE,
    });

    const fetchEndpointsWrapper = async (): Promise<void> => fetchEndpoints({
      project_id: activeUser.isSuperAdmin() ? statisticsFilters.project_id : null,
      user_id: statisticsFilters.user_id,
    });

    const fetchStatisticsWrapper = async (): Promise<void> => fetchStatistics(statisticsFilters);

    const onUserChange = (): void => {
      statisticsFilters.endpoint_id = null;

      fetchEndpointsWrapper();
    };

    const onProjectChange = (): void => {
      statisticsFilters.user_id = null;

      fetchUsersWrapper();

      onUserChange();
    };

    const intervalHoursOptions: Option[] = [3, 6, 12, 24, 48, 72, 168, 336].map((value: number) => {
      const labelMap: { [key: number]: string; } = {
        48: '2 days',
        72: '3 days',
        168: '1 week',
        336: '2 weeks',
      };

      return {
        value,
        label: `${labelMap[value] || value}`,
      };
    });

    const axisYLabelInterpolationFunction = (value: number): string => numeral(value).format('0a');

    const axisYLabelInterpolationFunctionEstimatedProfit = (value: number): string => i18n.n(value, 'payout');

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        fetchUsersWrapper(),
        fetchEndpointsWrapper(),
        fetchStatisticsWrapper(),
      ]);
    });

    return {
      TRAFFIC_SOURCES,
      activeUser,
      projectsLoading,
      projects,
      usersLoading,
      users,
      endpointsLoading,
      endpoints,
      statisticsLoading,
      statistics,
      fetchStatisticsWrapper,
      statisticsFilters,
      showStatisticsFilters,
      onUserChange,
      onProjectChange,
      intervalHoursOptions,
      axisYLabelInterpolationFunction,
      axisYLabelInterpolationFunctionEstimatedProfit,
    };
  },
});
