
import type { PropType } from 'vue';

import {
  defineComponent,
  computed,
} from 'vue';

import PrimeVueCheckbox from 'primevue/checkbox';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      required: true,
    },
    hideSchedule: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PrimeVueCheckbox,
  },
  setup(props, { emit }) {
    const schedule = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const selectAll = (): void => {
      schedule.value = Array.from(Array(24).keys());
    };

    const unselectAll = (): void => {
      schedule.value = [];
    };

    return {
      schedule,
      selectAll,
      unselectAll,
    };
  },
});
