
import type { PropType } from 'vue';

import {
  defineComponent,
  reactive,
  watch,
  onMounted,
} from 'vue';

import type {
  Countries,
  ShaveRule,
} from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<ShaveRule[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const shaveRules = reactive<ShaveRule[]>(props.modelValue);

    const {
      entries: countries,
      entriesLoading: countriesLoading,
      fetchEntries: fetchCountries,
    } = useEntriesNext<Countries>('/api/country/getCountries');

    const addShaveRule = (): void => {
      shaveRules.push({
        country_id: null,
        shave: 0,
      });
    };

    const deleteShaveRule = (index: number): void => {
      shaveRules.splice(index, 1);
    };

    watch(
      shaveRules,
      () => emit('update:modelValue', shaveRules.filter((shaveRule: ShaveRule) => shaveRule.country_id !== null)),
    );

    onMounted((): void => {
      Promise.all([
        fetchCountries(),
      ]);
    });

    return {
      shaveRules,
      countriesLoading,
      countries,
      addShaveRule,
      deleteShaveRule,
    };
  },
});
