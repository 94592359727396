import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldErrors = _resolveComponent("FieldErrors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), {
      "model-value": _ctx.modelValue,
      loading: _ctx.loading,
      disabled: _ctx.disabled,
      "show-clear": _ctx.showClear,
      filter: _ctx.filter,
      "filter-placeholder": _ctx.filterPlaceholder,
      options: _ctx.sortedOptions,
      "option-value": _ctx.optionValue,
      "option-label": _ctx.optionLabel,
      "option-disabled": _ctx.optionDisabled,
      "data-key": _ctx.optionValue,
      display: "chip",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, _createSlots({
      chip: _withCtx((slotProps) => [
        (slotProps.value !== undefined && slotProps.value !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.optionLabelTranslate)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.optionsMap[slotProps.value] ? _ctx.$t(_ctx.optionsMap[slotProps.value][_ctx.optionLabel]) : slotProps.value), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.optionsMap[slotProps.value] ? _ctx.optionsMap[slotProps.value][_ctx.optionLabel] : slotProps.value), 1)
                  ], 64))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, "Unknown"))
      ]),
      _: 2
    }, [
      (!_ctx.multiple)
        ? {
            name: "option",
            fn: _withCtx((slotProps) => [
              _createElementVNode("div", null, [
                (_ctx.optionLabelTranslate)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t(slotProps.option[_ctx.optionLabel])), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(slotProps.option[_ctx.optionLabel]), 1)
                    ], 64))
              ])
            ])
          }
        : undefined,
      (!_ctx.multiple)
        ? {
            name: "value",
            fn: _withCtx((slotProps) => [
              (slotProps.value !== undefined && slotProps.value !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.optionLabelTranslate)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.optionsMap[slotProps.value][_ctx.optionLabel])), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.optionsMap[slotProps.value][_ctx.optionLabel]), 1)
                        ], 64))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.placeholder || ' '), 1))
            ])
          }
        : undefined
    ]), 1032, ["model-value", "loading", "disabled", "show-clear", "filter", "filter-placeholder", "options", "option-value", "option-label", "option-disabled", "data-key"])),
    (_ctx.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 1,
          class: "block",
          innerHTML: _ctx.help
        }, null, 8, _hoisted_7))
      : _createCommentVNode("", true),
    (_ctx.name)
      ? (_openBlock(), _createBlock(_component_FieldErrors, {
          key: 2,
          "errors-map": _ctx.errorsMap,
          name: _ctx.name
        }, null, 8, ["errors-map", "name"]))
      : _createCommentVNode("", true)
  ]))
}