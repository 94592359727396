import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 lg:col-4" }
const _hoisted_3 = {
  key: 0,
  class: "card"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "col-12 lg:col-4" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "p-fluid" }
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "p-fluid" }
const _hoisted_12 = {
  key: 0,
  class: "col-12 lg:col-4"
}
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_FieldCheckbox = _resolveComponent("FieldCheckbox")!
  const _component_Entry = _resolveComponent("Entry")!

  return (_openBlock(), _createBlock(_component_Entry, {
    "entry-errors-map": _ctx.schemeErrorsMap,
    "onUpdate:entry-errors-map": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.schemeErrorsMap) = $event)),
    title: _ctx.$t('scheme'),
    entry: _ctx.scheme,
    "entry-fetch-handler": _ctx.fetchSchemes,
    "entry-store-handler": _ctx.storeScheme
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.scheme.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.scheme.id,
                    label: "ID",
                    disabled: ""
                  }, null, 8, ["model-value"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scheme.name) = $event)),
                modelModifiers: { trim: true },
                name: "name",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('name')
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_FieldDropdown, {
                modelValue: _ctx.scheme.content_type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.scheme.content_type) = $event)),
                modelModifiers: { number: true },
                name: "content_type",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('type'),
                options: _ctx.SCHEME_CONTENT_TYPES,
                "option-label": "label",
                "option-label-translate": false
              }, null, 8, ["modelValue", "errors-map", "label", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_ctx.scheme.content_type === _ctx.SCHEME_CONTENT_TYPE_JSON)
                ? (_openBlock(), _createBlock(_component_FieldCheckbox, {
                    key: 0,
                    modelValue: _ctx.scheme.items_in_array,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.scheme.items_in_array) = $event)),
                    label: _ctx.$t('items_in_array'),
                    help: _ctx.$t('items_in_array_help')
                  }, null, 8, ["modelValue", "label", "help"]))
                : _createCommentVNode("", true),
              (_ctx.scheme.content_type === _ctx.SCHEME_CONTENT_TYPE_XML || _ctx.scheme.items_in_array)
                ? (_openBlock(), _createBlock(_component_FieldText, {
                    key: 1,
                    modelValue: _ctx.scheme.items_path,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.scheme.items_path) = $event)),
                    modelModifiers: { trim: true },
                    name: "items_path",
                    "errors-map": _ctx.schemeErrorsMap,
                    label: _ctx.$t('items_path')
                  }, null, 8, ["modelValue", "errors-map", "label"]))
                : _createCommentVNode("", true),
              (_ctx.scheme.content_type === _ctx.SCHEME_CONTENT_TYPE_XML)
                ? (_openBlock(), _createBlock(_component_FieldCheckbox, {
                    key: 2,
                    modelValue: _ctx.scheme.data_in_attributes,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.scheme.data_in_attributes) = $event)),
                    label: _ctx.$t('data_in_attributes')
                  }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.title_path,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.scheme.title_path) = $event)),
                modelModifiers: { trim: true },
                name: "title_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('title_path')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.description_path,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.scheme.description_path) = $event)),
                modelModifiers: { trim: true },
                name: "description_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('description_path')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.bid_path,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.scheme.bid_path) = $event)),
                modelModifiers: { trim: true },
                name: "bid_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('bid_path')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              (_ctx.scheme.content_type === _ctx.SCHEME_CONTENT_TYPE_JSON)
                ? (_openBlock(), _createBlock(_component_FieldCheckbox, {
                    key: 3,
                    modelValue: _ctx.scheme.bid_is_string,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.scheme.bid_is_string) = $event)),
                    label: _ctx.$t('bid_is_string'),
                    help: _ctx.$t('bid_is_string_help')
                  }, null, 8, ["modelValue", "label", "help"]))
                : _createCommentVNode("", true),
              _createVNode(_component_FieldCheckbox, {
                modelValue: _ctx.scheme.bid_may_be_zero,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.scheme.bid_may_be_zero) = $event)),
                label: _ctx.$t('bid_may_be_zero'),
                help: _ctx.$t('bid_may_be_zero_help')
              }, null, 8, ["modelValue", "label", "help"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.icon_url_path,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.scheme.icon_url_path) = $event)),
                modelModifiers: { trim: true },
                name: "icon_url_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('icon_url_path')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.image_url_path,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.scheme.image_url_path) = $event)),
                modelModifiers: { trim: true },
                name: "image_url_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('image_url_path')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.click_url_path,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.scheme.click_url_path) = $event)),
                modelModifiers: { trim: true },
                name: "click_url_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('click_url_path')
              }, null, 8, ["modelValue", "errors-map", "label"]),
              _createVNode(_component_FieldText, {
                modelValue: _ctx.scheme.nurl_path,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.scheme.nurl_path) = $event)),
                modelModifiers: { trim: true },
                name: "nurl_path",
                "errors-map": _ctx.schemeErrorsMap,
                label: _ctx.$t('nurl_path')
              }, null, 8, ["modelValue", "errors-map", "label"])
            ])
          ])
        ]),
        (_ctx.scheme.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.scheme.created_at,
                    label: _ctx.$t('created_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"]),
                  _createVNode(_component_FieldText, {
                    "model-value": _ctx.scheme.updated_at,
                    label: _ctx.$t('updated_at'),
                    disabled: ""
                  }, null, 8, ["model-value", "label"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["entry-errors-map", "title", "entry", "entry-fetch-handler", "entry-store-handler"]))
}