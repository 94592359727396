
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    macros: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
});
