
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import { Country } from '@/types';

export default defineComponent({
  props: {
    country: {
      type: Object as PropType<Country>,
    },
  },
});
