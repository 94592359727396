import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold" }
const _hoisted_2 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_3 = {
  key: 1,
  class: "p-fluid"
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_UserColumn = _resolveComponent("UserColumn")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_PrimeVueTag = _resolveComponent("PrimeVueTag")!
  const _component_EntriesDataTable = _resolveComponent("EntriesDataTable")!
  const _component_FieldDropdownNext = _resolveComponent("FieldDropdownNext")!
  const _component_FieldText = _resolveComponent("FieldText")!
  const _component_Entries = _resolveComponent("Entries")!

  return (_openBlock(), _createBlock(_component_Entries, {
    title: _ctx.$t('sites'),
    "href-prefix": "sites",
    "show-add-button": _ctx.user.isStaff() || _ctx.sites.meta.total < _ctx.user.max_sites
  }, {
    tables: _withCtx(() => [
      _createVNode(_component_EntriesDataTable, {
        entries: _ctx.sites,
        "entries-handler": _ctx.fetchSites,
        "href-prefix": "sites"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, {
            field: "id",
            header: "ID",
            sortable: ""
          }),
          (_ctx.user.isSuperAdmin())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 0,
                field: "project_id",
                header: _ctx.$t('project'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.project.name), 1)
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          (_ctx.user.isStaff())
            ? (_openBlock(), _createBlock(_component_PrimeVueColumn, {
                key: 1,
                field: "user_id",
                header: _ctx.$t('user'),
                sortable: ""
              }, {
                body: _withCtx(({ data }) => [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_UserColumn, {
                        user: data.user,
                        "show-email-as-link": true
                      }, null, 8, ["user"])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024))
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          _createVNode(_component_PrimeVueColumn, {
            field: "host",
            header: _ctx.$t('host')
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(data.host), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "traffic_category",
            header: _ctx.$t('category'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.TRAFFIC_CATEGORIES_MAP[data.traffic_category].translate_key)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "approval_status",
            header: _ctx.$t('approval_status'),
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_PrimeVueTag, {
                value: _ctx.$t(_ctx.SITE_APPROVAL_STATUSES_MAP[data.approval_status].translate_key),
                severity: _ctx.SITE_APPROVAL_STATUSES_MAP[data.approval_status].severity
              }, null, 8, ["value", "severity"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_PrimeVueColumn, {
            field: "updated_at",
            header: _ctx.$t('updated_at'),
            sortable: ""
          }, null, 8, ["header"])
        ]),
        _: 1
      }, 8, ["entries", "entries-handler"])
    ]),
    filters: _withCtx(() => [
      (_ctx.user.isSuperAdmin())
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_FieldDropdownNext, {
              modelValue: _ctx.sitesFilters.project_id,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sitesFilters.project_id) = $event)),
                _ctx.onProjectChange
              ],
              modelModifiers: { number: true },
              label: _ctx.$t('project'),
              loading: _ctx.projectsLoading,
              "show-clear": true,
              options: _ctx.projects.data,
              "option-value": "id",
              "option-label": "name"
            }, null, 8, ["modelValue", "label", "loading", "options", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.user.isStaff())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_FieldDropdownNext, {
              modelValue: _ctx.sitesFilters.user_id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sitesFilters.user_id) = $event)),
              modelModifiers: { number: true },
              label: _ctx.$t('user'),
              loading: _ctx.usersLoading,
              filter: true,
              "show-clear": true,
              options: _ctx.users.data,
              "option-value": "id",
              "option-label": "compiled_name"
            }, null, 8, ["modelValue", "label", "loading", "options"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FieldDropdownNext, {
          modelValue: _ctx.sitesFilters.traffic_category,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sitesFilters.traffic_category) = $event)),
          modelModifiers: { number: true },
          label: _ctx.$t('category'),
          "show-clear": true,
          options: _ctx.TRAFFIC_CATEGORIES
        }, null, 8, ["modelValue", "label", "options"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FieldText, {
          modelValue: _ctx.sitesFilters.host,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sitesFilters.host) = $event)),
          modelModifiers: { trim: true },
          label: _ctx.$t('host')
        }, null, 8, ["modelValue", "label"])
      ])
    ]),
    _: 1
  }, 8, ["title", "show-add-button"]))
}