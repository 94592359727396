
import {
  defineComponent,
  computed,
} from 'vue';

import { useStore } from 'vuex';

import type { Project } from '@/types';

export default defineComponent({
  setup() {
    const store = useStore();

    return {
      project: computed((): Project => store.getters.project),
    };
  },
});
