
import { defineComponent } from 'vue';

import type { Statistics } from '@/types';

import useEntriesNext from '@/composable/useEntries@next';

import StatisticsBy from '@/components/Statistics/Staff/StatisticsBy.vue';

export default defineComponent({
  extends: StatisticsBy,
  setup(props) {
    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsByFilteredImpressions');

    const fetchStatisticsWrapper = async (): Promise<void> => fetchStatistics({
      ...props.statisticsFilters,
      ...props.filters,
    });

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
    };
  },
});
