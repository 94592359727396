import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = {
  key: 0,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_3 = {
  key: 1,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_4 = {
  key: 2,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_5 = {
  key: 3,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_6 = {
  key: 4,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_7 = {
  key: 5,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_8 = {
  key: 6,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_9 = {
  key: 7,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_10 = {
  key: 8,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_11 = {
  key: 9,
  class: "text-bluegray-400 ml-2"
}
const _hoisted_12 = {
  key: 10,
  class: "text-bluegray-400 ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h4", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.title), 1),
    (_ctx.filters.date)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.filters.date), 1))
      : _createCommentVNode("", true),
    (_ctx.isNumber(_ctx.filters.hour))
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.filters.hour.toString().padStart(2, '0')) + ":00", 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.publisher_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "+ " + _toDisplayString(_ctx.$t('publisher')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.endpoint_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, "+ " + _toDisplayString(_ctx.$t('endpoint')), 1))
      : _createCommentVNode("", true),
    (_ctx.filters.subid_uuid)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "+ " + _toDisplayString(_ctx.$t('subid')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.feed_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "+ " + _toDisplayString(_ctx.$t('feed')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.advertiser_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_8, "+ " + _toDisplayString(_ctx.$t('advertiser')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.campaign_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_9, "+ " + _toDisplayString(_ctx.$t('campaign')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.country_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_10, "+ " + _toDisplayString(_ctx.$t('country')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.platform_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_11, "+ " + _toDisplayString(_ctx.$t('platform')), 1))
      : _createCommentVNode("", true),
    (!_ctx.isEmpty(_ctx.filters.browser_id))
      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "+ " + _toDisplayString(_ctx.$t('browser')), 1))
      : _createCommentVNode("", true)
  ]))
}