
import type { PropType } from 'vue';

import {
  defineComponent,
  defineAsyncComponent,
} from 'vue';

import { Campaign } from '@/types';

import {
  CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES_MAP,
  CAMPAIGN_STATUS_DISABLED,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  components: {
    CampaignStatus: defineAsyncComponent(() => import('@/components/CampaignStatus.vue')),
  },
  props: {
    campaign: {
      type: Object as PropType<Campaign>,
      required: true,
    },
  },
  setup() {
    const { activeUser: user } = useUser();

    const { encodeStatisticsFilters } = useStatisticsLink();

    // TODO: Вынести в Vue 3 фильтры, чтобы использовать везде
    const truncateText = (text: string, length: number): string => {
      if (text.length < length) {
        return text;
      }

      return `${text.substring(1, length)}...`;
    };

    return {
      CAMPAIGN_MAX_SPENDING_PER_DAY_STRATEGIES_MAP,
      CAMPAIGN_STATUS_DISABLED,
      user,
      encodeStatisticsFilters,
      truncateText,
    };
  },
});
