
import axios from 'axios';

import {
  defineComponent,
  ref,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
    archivedAt: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const toast = useToast();

    const loading = ref<boolean>(false);

    const changeArchivedAt = async (archived: boolean): Promise<void> => {
      loading.value = true;

      try {
        await axios.post(
          '/api/campaign/changeArchivedAtById',
          {
            id: props.id,
            archived,
          },
        );

        // TODO: Использовать дату архивации из ответа API
        emit('update:archivedAt', archived ? '0000-00-00 00:00:00' : null);

        toast.add({
          severity: 'success',
          summary: i18n.t('success'),
          detail: '👌',
          life: 5000,
        });
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: i18n.t('error'),
          detail: i18n.t('unknown_error'),
          life: 5000,
        });
      }

      loading.value = false;
    };

    return {
      loading,
      changeArchivedAt,
    };
  },
});
