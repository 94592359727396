
import isNumber from 'lodash/isNumber';

import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  computed,
} from 'vue';

import { useToast } from 'primevue/usetoast';

import { useI18n } from 'vue-i18n';

import type {
  GenericObject,
  Statistics,
  FeedPayout,
} from '@/types';

import { BID_MULTIPLIER } from '@/libs/consts';

import useEntriesNext from '@/composable/useEntries@next';

import useSubStatisticsComponent from '@/composable/useSubStatisticsComponent';

import StatisticsBy from '@/components/Statistics/Staff/StatisticsBy.vue';

export default defineComponent({
  extends: StatisticsBy,
  components: {
    // Компоненты колонок
    EfficiencyColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/EfficiencyColumn.vue')),
    FeedsRequestsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/FeedsRequestsColumn.vue')),
    ViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ViewsColumn.vue')),
    PayableViewsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/PayableViewsColumn.vue')),
    ClicksColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ClicksColumn.vue')),
    ImpressionsColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/ImpressionsColumn.vue')),
    CTRColumn: defineAsyncComponent(() => import('@/components/Statistics/Staff/Columns/CTRColumn.vue')),
    Feed: defineAsyncComponent(() => import('@/components/Feed.vue')),
    // Компоненты статистики
    StatisticsByFilteredClicks: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredClicks.vue')),
    StatisticsByFilteredImpressions: defineAsyncComponent(() => import('@/components/Statistics/Staff/StatisticsByFilteredImpressions.vue')),
  },
  setup(props) {
    const toast = useToast();

    const i18n = useI18n();

    const {
      entries: statistics,
      entriesLoading: statisticsLoading,
      entriesFetched: statisticsFetched,
      fetchEntries: fetchStatistics,
    } = useEntriesNext<Statistics>('/api/statistics/staff/getStatisticsByFeeds');

    const {
      subStatisticsComponent,
      setSubStatisticsComponent,
      resetSubStatisticComponent,
    } = useSubStatisticsComponent();

    const getStatisticByFeedRowClass = (row: GenericObject): string => (Array.isArray(subStatisticsComponent.filters.feed_id) && subStatisticsComponent.filters.feed_id.includes(row.feed_id) ? 'statistics-table-row-active' : '');

    const fetchStatisticsWrapper = async (): Promise<void> => {
      fetchStatistics({
        ...props.statisticsFilters,
        ...props.filters,
      });

      resetSubStatisticComponent();
    };

    const feedPayoutDialog = reactive({
      visible: false,
      loading: false,
      data: null as unknown as GenericObject,
      feedPayout: null as unknown as FeedPayout,
    });

    const showFeedPayoutDialog = (data: GenericObject): void => {
      feedPayoutDialog.data = data;

      feedPayoutDialog.feedPayout = {
        feed_id: data.feed_id,
        date: props.filters.date,
        payout: data.feeds_payout,
      } as FeedPayout;

      feedPayoutDialog.visible = true;
    };

    const storeFeedPayout = async (): Promise<void> => {
      feedPayoutDialog.loading = true;

      const response = await axios.post('/api/feed-payout/storeFeedPayout', feedPayoutDialog.feedPayout);

      const feedPayout = response.data as FeedPayout;

      feedPayoutDialog.data.feeds_payout = feedPayout.payout / BID_MULTIPLIER;

      feedPayoutDialog.data.feeds_payout_percent = feedPayoutDialog.data.feeds_payout / (feedPayoutDialog.data.feeds_payout_estimated as number);

      toast.add({
        severity: 'success',
        summary: i18n.t('success'),
        detail: i18n.t('success_store'),
        life: 5000,
      });

      feedPayoutDialog.loading = false;

      feedPayoutDialog.visible = false;
    };

    const overrideHideFeedsPayoutColumn = computed((): boolean => props.hideFeedsPayoutColumn || isNumber(props.filters.hour));

    return {
      statistics,
      statisticsLoading,
      statisticsFetched,
      fetchStatisticsWrapper,
      subStatisticsComponent,
      setSubStatisticsComponent,
      getStatisticByFeedRowClass,
      feedPayoutDialog,
      showFeedPayoutDialog,
      storeFeedPayout,
      overrideHideFeedsPayoutColumn,
    };
  },
});
