import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 lg:col-6"
}
const _hoisted_3 = { class: "col-12 lg:col-6" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Manager = _resolveComponent("Manager")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_IconPark = _resolveComponent("IconPark")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: _ctx.$t('support')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.user.manager_id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(), _createBlock(_Suspense, null, {
              default: _withCtx(() => [
                _createVNode(_component_Manager, {
                  user: _ctx.user.manager
                }, null, 8, ["user"])
              ]),
              fallback: _withCtx(() => [
                _createVNode(_component_PrimeVueSkeleton, { height: "120px" })
              ]),
              _: 1
            }))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h6", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t('contact_via_form')), 1),
            _createElementVNode("a", {
              class: "text-indigo-400 ml-2",
              href: _ctx.contactUrl,
              target: "_blank"
            }, [
              _createVNode(_component_IconPark, {
                class: "cursor-pointer",
                type: "circle-right-up",
                theme: "outline",
                size: "14"
              })
            ], 8, _hoisted_6)
          ])
        ])
      ])
    ])
  ], 64))
}