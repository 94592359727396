import { createStore } from 'vuex';

import type {
  StoreState,
  Project,
  User,
} from '@/types';

import {
  THEME_LIGHT,
  THEME_DARK,
  THEME_COLOR_PURPLE,
} from '@/libs/consts';

export default createStore({
  state: {
    project: null,
    user: null,
    theme: null,
    themeColor: null,
  } as StoreState,
  getters: {
    project: (state) => state.project as Project,
    user: (state) => state.user as User,
    theme: (state) => state.theme || localStorage.getItem('theme') || THEME_LIGHT,
    themeIsLight: (state, getters) => getters.theme === THEME_LIGHT,
    themeIsDark: (state, getters) => getters.theme === THEME_DARK,
    themeColor: (state, getters) => state.themeColor || (getters.project ? getters.project.theme_color : THEME_COLOR_PURPLE),
  },
  mutations: {
    SET_PROJECT: (state, project: Project) => {
      state.project = project;
    },
    SET_TOKEN: (state, token: string) => {
      localStorage.setItem('token', token);
    },
    UNSET_TOKEN: () => {
      localStorage.removeItem('token');
    },
    SET_USER: (state, user: User) => {
      state.user = user;
    },
    SET_USER_BALANCE: (state, balance: number) => {
      if (!state.user) {
        return;
      }

      state.user.balance = balance;
    },
    SET_THEME: (state, value: string) => {
      state.theme = value;

      localStorage.setItem('theme', value);
    },
    SET_THEME_COLOR: (state, value: string) => {
      state.themeColor = value;
    },
  },
  actions: {
  },
  modules: {
  },
});
