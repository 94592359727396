
import { v4 as uuid } from 'uuid';

import Chartist from 'chartist';

import 'chartist/dist/chartist.min.css';

import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css';

import type { PropType } from 'vue';

import {
  defineComponent,
  onMounted,
} from 'vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ChartistTooltip = require('chartist-plugin-tooltips-updated');

export default defineComponent({
  props: {
    labels: {
      type: Array as PropType<string[]>,
    },
    showArea: {
      type: Boolean,
      default: false,
    },
    showPoint: {
      type: Boolean,
      default: false,
    },
    axisYLabelInterpolationFunction: {
      type: Function,
    },
    tooltipOptions: {
      type: Object,
      default: () => (null),
    },
    entries: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      required: true,
    },
    entryKey: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: String as PropType<keyof any>,
      required: true,
    },
    entryDescriptionKey: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: String as PropType<keyof any>,
    },
  },
  setup(props) {
    const containerId = `chartist-${uuid()}`;

    const data = {
      labels: props.labels || [],
      series: [
        props.entries.map((entry) => ({
          meta: props.entryDescriptionKey ? entry[props.entryDescriptionKey] as string : null,
          value: entry[props.entryKey] as number,
        })),
      ],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const plugins: any[] = [];

    if (props.tooltipOptions) {
      plugins.push(ChartistTooltip(props.tooltipOptions));
    }

    onMounted((): void => {
      // eslint-disable-next-line no-new
      new Chartist.Bar(
        `#${containerId}`,
        data,
        {
          low: 0,
          axisY: {
            labelInterpolationFnc: props.axisYLabelInterpolationFunction,
          },
          plugins,
        },
      );
    });

    return {
      containerId,
    };
  },
});
