import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Suspense as _Suspense, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "grid mb-2" }
const _hoisted_3 = { class: "col-12 lg:col-3" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "p-fluid" }
const _hoisted_6 = { class: "p-fluid" }
const _hoisted_7 = { class: "col-12 lg:col-3" }
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "p-fluid" }
const _hoisted_10 = { class: "grid mb-4" }
const _hoisted_11 = { class: "col-12 lg:col-3" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "col-12 lg:col-4" }
const _hoisted_15 = { class: "card insights" }
const _hoisted_16 = { class: "col-12 lg:col-4" }
const _hoisted_17 = { class: "card insights" }
const _hoisted_18 = { class: "card insights" }
const _hoisted_19 = { class: "col-12 lg:col-4" }
const _hoisted_20 = { class: "card insights" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "card" }
const _hoisted_24 = { class: "mr-2" }
const _hoisted_25 = { class: "flex align-items-center" }
const _hoisted_26 = { class: "mb-3" }
const _hoisted_27 = { class: "card" }
const _hoisted_28 = { class: "mr-2" }
const _hoisted_29 = { class: "flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_FieldDate = _resolveComponent("FieldDate")!
  const _component_FieldDropdown = _resolveComponent("FieldDropdown")!
  const _component_PrimeVueButton = _resolveComponent("PrimeVueButton")!
  const _component_PrimeVueBadge = _resolveComponent("PrimeVueBadge")!
  const _component_IconPark = _resolveComponent("IconPark")!
  const _component_User = _resolveComponent("User")!
  const _component_PrimeVueSkeleton = _resolveComponent("PrimeVueSkeleton")!
  const _component_PrimeVuePanel = _resolveComponent("PrimeVuePanel")!
  const _component_PrimeVueColumn = _resolveComponent("PrimeVueColumn")!
  const _component_PrimeVueRow = _resolveComponent("PrimeVueRow")!
  const _component_NumberWithPercent = _resolveComponent("NumberWithPercent")!
  const _component_EntriesDataTableNext = _resolveComponent("EntriesDataTableNext")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      class: "mb-3",
      title: _ctx.$t('users_report')
    }, null, 8, ["title"]),
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('filters')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FieldDate, {
              modelValue: _ctx.usersReportFilters.date_from,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.usersReportFilters.date_from) = $event)),
              label: _ctx.$t('date_from')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FieldDate, {
              modelValue: _ctx.usersReportFilters.date_to,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.usersReportFilters.date_to) = $event)),
              label: _ctx.$t('date_to')
            }, null, 8, ["modelValue", "label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_FieldDropdown, {
              modelValue: _ctx.usersReportFilters.link_id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.usersReportFilters.link_id) = $event)),
              label: _ctx.$t('ref_link'),
              filter: true,
              "show-clear": true,
              loading: _ctx.linksLoading,
              options: _ctx.links.data,
              "option-value": "id",
              "option-label": "compiled_name",
              "option-label-translate": false,
              multiple: true,
              "multiple-sort-by-value": true
            }, null, 8, ["modelValue", "label", "loading", "options"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_PrimeVueButton, {
          class: "p-button-primary w-full",
          label: _ctx.$t('apply'),
          loading: _ctx.usersReportLoading,
          onClick: _ctx.fetchUsersReport
        }, null, 8, ["label", "loading", "onClick"])
      ])
    ]),
    _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.$t('users')), 1),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_PrimeVuePanel, {
          class: "operations",
          toggleable: true,
          collapsed: true
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('users_report_registered')), 1),
              _createVNode(_component_PrimeVueBadge, {
                class: "ml-1",
                value: _ctx.usersReport.users.data.length,
                severity: "info"
              }, null, 8, ["value"]),
              (_ctx.usersReport.users.data.length)
                ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
                    key: 0,
                    class: "ml-1 text-blue-600 cursor-pointer",
                    type: "download",
                    theme: "outline",
                    size: "16",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadCSV(_ctx.usersReport.users.data, 'Users - Registered')))
                  }, null, 512)), [
                    [_directive_tooltip, _ctx.$t('download_csv')]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.usersReportFetched && !_ctx.usersReportLoading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.usersReport.users.data, (user) => {
                  return (_openBlock(), _createBlock(_Suspense, {
                    key: user.id
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_User, {
                          user: user,
                          "show-statistics-button": false,
                          "show-checker-button": false
                        }, null, 8, ["user"])
                      ])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
                  key: 1,
                  height: "40px"
                }))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_PrimeVuePanel, {
          class: "operations mb-3",
          toggleable: true,
          collapsed: true
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('users_report_has_sites')), 1),
              _createVNode(_component_PrimeVueBadge, {
                class: "ml-1",
                value: _ctx.usersHasSites.length,
                severity: "success"
              }, null, 8, ["value"]),
              (_ctx.usersHasSites.length)
                ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
                    key: 0,
                    class: "ml-1 text-blue-600 cursor-pointer",
                    type: "download",
                    theme: "outline",
                    size: "16",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.downloadCSV(_ctx.usersHasSites, 'Users - Has sites')))
                  }, null, 512)), [
                    [_directive_tooltip, _ctx.$t('download_csv')]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.usersReportFetched && !_ctx.usersReportLoading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.usersHasSites, (user) => {
                  return (_openBlock(), _createBlock(_Suspense, {
                    key: user.id
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_User, {
                          user: user,
                          "show-sites-button": true,
                          "statistics-filters": _ctx.statisticsFilters
                        }, null, 8, ["user", "statistics-filters"])
                      ])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
                  key: 1,
                  height: "40px"
                }))
          ]),
          _: 1
        }),
        _createVNode(_component_PrimeVuePanel, {
          class: "operations",
          toggleable: true,
          collapsed: true
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('users_report_has_sites_and_has_no_traffic')), 1),
              _createVNode(_component_PrimeVueBadge, {
                class: "ml-1",
                value: _ctx.usersHasSitesAndHasNoTraffic.length,
                severity: "warning"
              }, null, 8, ["value"]),
              (_ctx.usersHasSitesAndHasNoTraffic.length)
                ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
                    key: 0,
                    class: "ml-1 text-blue-600 cursor-pointer",
                    type: "download",
                    theme: "outline",
                    size: "16",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.downloadCSV(_ctx.usersHasSitesAndHasNoTraffic, 'Users - Has sites and has not traffic')))
                  }, null, 512)), [
                    [_directive_tooltip, _ctx.$t('download_csv')]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.usersReportFetched && !_ctx.usersReportLoading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.usersHasSitesAndHasNoTraffic, (user) => {
                  return (_openBlock(), _createBlock(_Suspense, {
                    key: user.id
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_User, {
                          user: user,
                          "show-sites-button": true,
                          "statistics-filters": _ctx.statisticsFilters
                        }, null, 8, ["user", "statistics-filters"])
                      ])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
                  key: 1,
                  height: "40px"
                }))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_PrimeVuePanel, {
          class: "operations",
          toggleable: true,
          collapsed: true
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('users_report_has_no_sites')), 1),
              _createVNode(_component_PrimeVueBadge, {
                class: "ml-1",
                value: _ctx.usersHasNoSites.length,
                severity: "danger"
              }, null, 8, ["value"]),
              (_ctx.usersHasNoSites.length)
                ? _withDirectives((_openBlock(), _createBlock(_component_IconPark, {
                    key: 0,
                    class: "ml-1 text-blue-600 cursor-pointer",
                    type: "download",
                    theme: "outline",
                    size: "16",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.downloadCSV(_ctx.usersHasNoSites, 'Users - Has no sites')))
                  }, null, 512)), [
                    [_directive_tooltip, _ctx.$t('download_csv')]
                  ])
                : _createCommentVNode("", true)
            ])
          ]),
          default: _withCtx(() => [
            (_ctx.usersReportFetched && !_ctx.usersReportLoading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.usersHasNoSites, (user) => {
                  return (_openBlock(), _createBlock(_Suspense, {
                    key: user.id
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_User, {
                          user: user,
                          "show-statistics-button": false,
                          "show-checker-button": false
                        }, null, 8, ["user"])
                      ])
                    ]),
                    fallback: _withCtx(() => [
                      _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : (_openBlock(), _createBlock(_component_PrimeVueSkeleton, {
                  key: 1,
                  height: "40px"
                }))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("h4", _hoisted_21, _toDisplayString(_ctx.$t('statistics')), 1),
    _createElementVNode("h5", _hoisted_22, [
      _createTextVNode(_toDisplayString(_ctx.$t('users_report_runned_and_stopped')), 1),
      _createVNode(_component_PrimeVueBadge, {
        class: "ml-1",
        value: _ctx.usersReport.users_has_traffic.meta.total,
        severity: "warning"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createVNode(_component_EntriesDataTableNext, {
        entries: _ctx.usersReport.users_has_traffic,
        "entries-loading": _ctx.usersReportLoading,
        "entries-fetched": _ctx.usersReportFetched,
        "entries-handler": _ctx.entriesHandlerStub,
        "default-sort-by": "impressions"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publisher'),
                sortable: "",
                field: "publisher_id"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                sortable: "",
                field: "publishers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                sortable: "",
                field: "publishers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('has_social_auth'),
                sortable: "",
                field: "impressions_has_social_auth"
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_User, {
                    user: _ctx.usersMap[data.publisher_id],
                    "statistics-filters": _ctx.statisticsFilters
                  }, {
                    name: _withCtx(() => [
                      _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.getPlashka(data)), 1)
                    ]),
                    _: 2
                  }, 1032, ["user", "statistics-filters"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$n(data.impressions)), 1),
              _createElementVNode("div", _hoisted_25, [
                (_openBlock(), _createBlock(_component_NumberWithPercent, {
                  class: "mr-1",
                  key: `${data.publisher_id}_impressions_filtered`,
                  number: data.impressions_filtered,
                  "number-percent": data.impressions_filtered_percent
                }, null, 8, ["number", "number-percent"]))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_component_NumberWithPercent, {
                key: `${data.publisher_id}_impressions_has_social_auth`,
                number: data.impressions_has_social_auth,
                "number-percent": data.impressions_has_social_auth_percent,
                "positive-context": true
              }, null, 8, ["number", "number-percent"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler"])
    ]),
    _createElementVNode("h5", _hoisted_26, [
      _createTextVNode(_toDisplayString(_ctx.$t('users_report_still_running')), 1),
      _createVNode(_component_PrimeVueBadge, {
        class: "ml-1",
        value: _ctx.usersReport.users_has_traffic_now.meta.total,
        severity: "success"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createVNode(_component_EntriesDataTableNext, {
        entries: _ctx.usersReport.users_has_traffic_now,
        "entries-loading": _ctx.usersReportLoading,
        "entries-fetched": _ctx.usersReportFetched,
        "entries-handler": _ctx.entriesHandlerStub,
        "default-sort-by": "impressions"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_PrimeVueRow, null, {
            default: _withCtx(() => [
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publisher'),
                sortable: "",
                field: "publisher_id"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('impressions'),
                sortable: "",
                field: "impressions"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_cpc'),
                sortable: "",
                field: "publishers_cpc"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('publishers_payout'),
                sortable: "",
                field: "publishers_payout"
              }, null, 8, ["header"]),
              _createVNode(_component_PrimeVueColumn, {
                header: _ctx.$t('has_social_auth'),
                sortable: "",
                field: "impressions_has_social_auth"
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_Suspense, null, {
                default: _withCtx(() => [
                  _createVNode(_component_User, {
                    user: _ctx.usersMap[data.publisher_id],
                    "statistics-filters": _ctx.statisticsFilters
                  }, {
                    name: _withCtx(() => [
                      _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.getPlashka(data)), 1)
                    ]),
                    _: 2
                  }, 1032, ["user", "statistics-filters"])
                ]),
                fallback: _withCtx(() => [
                  _createVNode(_component_PrimeVueSkeleton, { height: "10px" })
                ]),
                _: 2
              }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$n(data.impressions)), 1),
              _createElementVNode("div", _hoisted_29, [
                (_openBlock(), _createBlock(_component_NumberWithPercent, {
                  class: "mr-1",
                  key: `${data.publisher_id}_impressions_filtered`,
                  number: data.impressions_filtered,
                  "number-percent": data.impressions_filtered_percent
                }, null, 8, ["number", "number-percent"]))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_cpc, 'bid')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.$n(data.publishers_payout, 'payout')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeVueColumn, null, {
            body: _withCtx(({ data }) => [
              (_openBlock(), _createBlock(_component_NumberWithPercent, {
                key: `${data.publisher_id}_impressions_has_social_auth`,
                number: data.impressions_has_social_auth,
                "number-percent": data.impressions_has_social_auth_percent,
                "positive-context": true
              }, null, 8, ["number", "number-percent"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["entries", "entries-loading", "entries-fetched", "entries-handler"])
    ])
  ], 64))
}