import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldNumber = _resolveComponent("FieldNumber")!

  return (_openBlock(), _createBlock(_component_FieldNumber, {
    modelValue: _ctx.cpm,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cpm) = $event)),
    modelModifiers: { number: true },
    name: _ctx.name,
    "errors-map": _ctx.errorsMap,
    label: _ctx.label,
    help: _ctx.help,
    min: _ctx.min,
    "min-fraction-digits": 1,
    "max-fraction-digits": 2,
    step: _ctx.step,
    "show-buttons": "",
    "button-layout": "horizontal",
    "increment-button-icon": "pi pi-plus",
    "decrement-button-icon": "pi pi-minus",
    prefix: "$"
  }, null, 8, ["modelValue", "name", "errors-map", "label", "help", "min", "step"]))
}